<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-4 col-md-6 col-sm-12 form-heading">
        <i class="fa fa-search heading-icon" aria-hidden="true"></i>
        <span class="list-heading" *ngIf="inspections && !inspect.InspectionId">INSPECTION LOGS</span>
        <span class="list-heading" *ngIf="inspect.InspectionId">EDIT INSPECTION LOG</span>
      </div>

      <div class="col-lg-8 col-md-12">
        <div
          id="page-size"
          class="col-lg-1 col-sm-2 col-md-2 col-xs-12 pull-right list-heading pageSize"
          *ngIf="inspections"
        >
          <ngx-atlas-select
            (model)="InspectLogPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-7 col-md-10 col-sm-10 col-xs-12 input-group pull-right p-search" *ngIf="inspections">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="inspectionLogSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="inspections">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('InspectionId', columnSortOrder)">
                  Inspection #
                  <span *ngIf="selectedColumnName == 'InspectionId' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'InspectionId' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('InspectionTitle', columnSortOrder)">
                  Inspection Title
                  <span *ngIf="selectedColumnName == 'InspectionTitle' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'InspectionTitle' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('TotalWeightageScore', columnSortOrder)">
                  Score
                  <span *ngIf="selectedColumnName == 'TotalWeightageScore' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'TotalWeightageScore' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('LocationName', columnSortOrder)">
                  Location Name
                  <span *ngIf="selectedColumnName == 'LocationName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'LocationName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('FirstName', columnSortOrder)">
                  User
                  <span *ngIf="selectedColumnName == 'FirstName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'FirstName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('InspectionDate', columnSortOrder)">
                  Planned Start Date
                  <span *ngIf="selectedColumnName == 'InspectionDate' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'InspectionDate' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('InsStatus', columnSortOrder)">
                  Status
                  <span *ngIf="selectedColumnName == 'InsStatus' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'InsStatus' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
              </tr>
            </thead>
            <tbody
              *ngFor="
                let inspection of inspections
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getInspectionById(inspection.InspectionId)">
                <td>{{ inspection.InspectionId }}</td>
                <td>{{ inspection.InspectionTitle }}</td>
                <td>{{ inspection.TotalWeightageScore }}</td>
                <td>{{ inspection.Location.LocationName }}</td>
                <td>{{ inspection.User.FirstName }} {{ inspection.User.LastName }}</td>
                <td>{{ inspection.InspectionDate | date: 'MM/dd/yyyy' }}</td>
                <td style="min-width: 88px">{{ inspection.InspectionStatus.InsStatus }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!inspections">
    <form #inspectionLogForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div>
          <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField">
            <label for="InspectionId"> Inspection #</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ inspect.InspectionId }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField">
            <label for="InspectionTitle"> Title</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ inspect.InspectionTitle }}</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField">
            <label for="venueName"> Venue Name</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ inspect.Venue.VenueName }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField">
            <label for="LocationName"> Location Name</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ inspect.Location.LocationName }}</p>
              </div>
            </div>
          </div>
        </div>

        <div>

          <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField">
            <label for="TotalWeightageScore"> Score</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ inspect.TotalWeightageScore }}</p>
              </div>
            </div>
          </div>
     
          <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField">
            <label><span id="red-circle">&#x25CF;</span> Status</label>
            <div>
              <ngx-atlas-select
                (model)="changeStatus($event)"
                [list]="status"
                [idField]="'InspectionStatusId'"
                [textField]="'InsStatus'"
                [placeholder]="'Select Status'"
                [allowClear]="true"
                [multiple]="false"
                [required]="false"
                [disabled]="disableStatusField"
                [selectedValue]="inspect.InspectionStatus.InspectionStatusId"
              >
              </ngx-atlas-select>
            </div>
          </div>

        </div>

        <div>
          <div class="col-md-6 col-xs-12 form-group">
            <label><span id="red-circle" *ngIf="isAssignToReq">&#x25CF;</span>User</label>
            <div>
              <ngx-atlas-select
                (model)="changeUser($event)"
                [list]="inspectors"
                [idField]="'UserId'"
                [textField]="'FirstName'"
                [placeholder]="'Select Assigned To'"
                [allowClear]="true"
                [multiple]="false"
                [required]="true"
                [disabled]="isReAssignShow"
                [selectedValue]="inspect.User.UserId"
              >
              </ngx-atlas-select>
            </div>
          </div>
          
        <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField">
          <label for="LocationName"> Overall Comment</label>
          <div *ngIf="inspect.OverallComment != null">
            <textarea type="text" class="form-control" rows="3" name="Comments" readonly>{{
              inspect.OverallComment
            }}</textarea>
          </div>
          <div *ngIf="inspect.OverallComment == null">
            <textarea type="text" class="form-control" rows="3" name="Comments" readonly>{{
              OverallComment
            }}</textarea>
          </div>
        </div>
        </div>

     


        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
          <hr />

          <div
            *ngIf="inspect.InspectionStatusList != null && inspect.InspectionStatusList.length > 0"
            class="col-lg-12 col-md-12 col-sm-12 form-group table-top table-responsive"
            style="padding: 0 45px 0 32px; height: auto; overflow-y: auto; max-height: 310px"
          >
            <h4>Status Activity</h4>
            <div class="box-body table-responsive">
              <table class="table table-hover table-responsive">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Time</th>
                    <th>User</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let inspects of inspect.InspectionStatusList">
                    <td class="cursor">{{ inspects.InsStatus }}</td>
                    <td class="cursor">{{ inspects.ModifiedOn | date: 'MM/dd/yyyy, hh:mm:ss a' }}</td>
                    <td class="cursor">{{ inspects.UpdatedUserName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr />

          <div
            class="col-md-12 col-sm-12 col-lg-12 form-group"
            style="padding: 0 45px 0 32px; height: auto; overflow-y: auto; max-height: 310px"
          >
            <h4>Beacon Activity</h4>
            <div *ngIf="!hasInspectionBeacons()">
              <h4 class="listerror">No beacon data.</h4>
            </div>

            <div id="workorderBeacons" *ngIf="hasInspectionBeacons()">
              <div class="box-body table-responsive">
                <table class="table table-hover table-responsive">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>First Time In Range</th>
                      <th>Last Time In Range</th>
                      <th>Battery Level</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let beacon of inspectionDetailsBeacons">
                      <tr *ngIf="beacon.timeElapsed == null">
                        <td style="cursor: auto">{{ beacon.Beacon.BeaconName }}</td>
                        <td style="cursor: auto" *ngIf="beacon.OptIn != null">
                          {{ beacon.OptIn | date: 'MM/dd/yyyy, hh:mm:ss a' }}
                        </td>
                        <td style="cursor: auto" *ngIf="beacon.OptIn == null"></td>
                        <td style="cursor: auto" *ngIf="beacon.OptOut != null">
                          {{ beacon.OptOut | date: 'MM/dd/yyyy, hh:mm:ss a' }}
                        </td>
                        <td style="cursor: auto" *ngIf="beacon.OptOut == null"></td>
                        <td style="cursor: auto" *ngIf="beacon.BatteryLevel != null">{{ beacon.BatteryLevel }}%</td>
                        <td style="cursor: auto" *ngIf="beacon.BatteryLevel == null">-</td>
                      </tr>
                      <tr *ngIf="beacon.timeElapsed != null">
                        <td style="cursor: auto" class="text-right" colspan="3">Time Elapsed</td>
                        <td style="cursor: auto">{{ beacon.timeElapsed }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <hr *ngIf="inspect.InspectionStatusList != null && inspect.InspectionStatusList.length > 0" />

          <div
            *ngIf="inspect.InspectionElements != null && inspect.InspectionElements.length > 0"
            class="col-sm-12 col-md-12 col-lg-12 form-group table-top table-responsive table-outer"
          >
            <table class="table table-bordered table-background">
              <div class="table-inner">
                <!--<div class="col-lg-2 col-md-2 col-sm-2 font">Element #</div>-->
                <div class="col-lg-4 col-md-4 col-sm-4 font">Element Name</div>
                <div class="col-lg-4 col-md-4 col-sm-4 font">Score Factor</div>
                <div class="col-lg-2 col-md-2 col-sm-2 font">Rating</div>
                <div class="col-lg-2 col-md-2 col-sm-2 font">Attachment</div>
              </div>
              <div *ngFor="let element of inspect.InspectionElements; let index = index">
                <div class="col-lg-12 col-md-12 first table-innerbody">
                  <!--<div class="col-lg-2 col-md-2 col-sm-2">{{element.Element.ElementId}}</div>-->
                  <div class="col-lg-4 col-md-4 col-sm-4" style="word-break: break-all">
                    {{ element.Element.ElementName }}
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4" style="word-break: break-all">
                    {{
                      element.InspectionScoreFactor &&
                      (element.InspectionScoreFactor.ScoreFactorName != null ||
                        element.InspectionScoreFactor.ScoreFactorName != '')
                        ? element.InspectionScoreFactor.ScoreFactorName
                        : ''
                    }}
                  </div>
                  <div class="col-lg-2 col-md-2 col-sm-2">{{ element.Element.Rating }}</div>
                  <div class="col-lg-2 col-md-2 col-sm-2">
                    <a
                      *ngIf="element.InspectElementAttachments != null && element.InspectElementAttachments.length > 0"
                      (click)="showSubItem(index)"
                      ><i class="fa fa-paperclip" aria-hidden="true" style="font-size: 20px; cursor: pointer"></i
                    ></a>
                  </div>
                </div>

                <div
                  class="col-lg-12 col-md-12 attachment-body"
                  *ngIf="
                    visibleIndex === index &&
                    element.InspectElementAttachments != null &&
                    element.InspectElementAttachments.length > 0
                  "
                >
                  <div class="col-lg-12 col-md-12" *ngFor="let file of element.InspectElementAttachments">
                    <div class="col-lg-2 col-md-2 col-sm-2">
                      <img
                        *ngIf="
                          file.AttachmentType == 'PNG' ||
                          file.AttachmentType == 'png' ||
                          file.AttachmentType == 'jpg' ||
                          file.AttachmentType == 'JPG' ||
                          file.AttachmentType == 'jpeg' ||
                          file.AttachmentType == 'JPEG' ||
                          file.AttachmentType == 'gif' ||
                          file.AttachmentType == 'GIF' ||
                          file.AttachmentType == 'doc' ||
                          file.AttachmentType == 'DOC'
                        "
                        class="img-responsive cursor-pointer image-type"
                        src="{{ file.AttachmentURL }}"
                        alt="{{ file.AttachmentName }}"
                        data-toggle="modal"
                        data-target="#imageModal"
                        (click)="openInspectionLogImage(file.AttachmentURL)"
                      />
                      <a
                        data-toggle="tooltip"
                        data-html="true"
                        title="View"
                        *ngIf="
                          file.AttachmentType == 'MP4' ||
                          file.AttachmentType == 'mp4' ||
                          file.AttachmentType == 'MOV' ||
                          file.AttachmentType == 'mov' ||
                          file.AttachmentType == 'avi' ||
                          file.AttachmentType == 'AVI' ||
                          file.AttachmentType == 'mpg' ||
                          file.AttachmentType == 'MPG' ||
                          file.AttachmentType == 'mpeg' ||
                          file.AttachmentType == 'MPEG'
                        "
                        (click)="downloadCTImage(file.AttachmentURL)"
                        class="fa fa-download video-type"
                      ></a>
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-10">
                      <textarea
                        *ngIf="file.Comment != null"
                        type="text"
                        class="form-control outer-border comment-section"
                        rows="4"
                        name="Comments"
                        readonly
                        >{{ file.Comment }}</textarea
                      >
                      <textarea
                        *ngIf="file.Comment == null"
                        type="text"
                        class="form-control outer-border comment-section"
                        rows="4"
                        name="Comments"
                        readonly
                        >{{ OverallComment }}</textarea
                      >
                    </div>
                  </div>
                </div>
              </div>
            </table>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <input
            type="button"
            value="Save"
            class="btn btn-type btn-save"
            [disabled]="
              !inspectionLogForm.valid ||
              !inspect.InspectionStatus.InspectionStatusId ||
              (isAssignToReq && !inspect.User.UserId) ||
              disableStatusField ||
              isReqComplete ||
              !('INSPECTION_LOG_MODIFY' | canAccess)
            "
            (click)="addInspection(inspectionLogForm)"
          />
          <input
            type="button"
            value="Cancel"
            class="btn btn-type pull-right btn-cancel"
            (click)="clearFormData(inspectionLogForm)"
          />
        </div>
      </div>
    </form>
  </div>

  <div class="modal fade" id="imageModal" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close close-button" data-dismiss="modal" style="color: black; margin-top: -10px">
            &times;
          </button>
          <img class="img-responsive" src="{{ InspectionImg }}" style="max-height: 424px; margin: auto" />
        </div>
      </div>
    </div>
  </div>
</section>
