import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { StateMaintain } from 'src/app/core/models/common.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { CountryService } from 'src/app/features/venuemanagement/country/components/country.http.service';
import { VenueService } from 'src/app/features/venuemanagement/venue/components/venue.http.service';
import { setUserImage, setUserName } from 'src/app/root-store/user-data/user-action';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { CorporationModule } from '../../../corporationmanagement/corporation/corporation.module';
import { ServiceProviderService } from '../../../serviceprovider/components/serviceprovider.http.service';
import { ServiceProviderModule } from '../../../serviceprovider/serviceprovider.module';
import { BadgeModule } from '../../../venuemanagement/badge/badge.module';
import { CityModule } from '../../../venuemanagement/city/city.module';
import { CityService } from '../../../venuemanagement/city/components/city.http.service';
import { CountryModule } from '../../../venuemanagement/country/country.module';
import { StateService } from '../../../venuemanagement/state/components/state.http.service';
import { StateModule } from '../../../venuemanagement/state/state.module';
import { UserLicenseRequestLogService } from '../../../venuemanagement/userlicenserequestlog/components/userlicenserequestlog.http.service';
import {
  SProviderSalesforceLicenseRequestModule,
  ServiceProviderActiveUserLicensesModule,
  UserLicenseRequestLogModule,
} from '../../../venuemanagement/userlicenserequestlog/userlicenserequestlog.module';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { ConfigureDefaultVenueService } from '../../configuredefaultvenue/components/configuredefaultvenue.http.service';
import { UserRoleService } from '../../role/components/role.http.service';
import { UserRoleModule } from '../../role/role.module';
import { RelUserCoporation, RelUserVenue, User, UserTypeModule } from '../user.model';
import { UserService } from './user.http.service';
@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'user-component',
  templateUrl: 'user.component.html',
  styleUrls: ['./user.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    UserService,
    UtilitiesService,
    UserRoleService,
    VenueService,
    CountryService,
    StateService,
    CityService,
    ConfigureDefaultVenueService,
    UserLicenseRequestLogService,
  ],
})
export class UserComponent implements OnInit, OnDestroy {
  @ViewChild('userQRCodeReportIframe', { static: true }) userQRCodeReportIframe: ElementRef;
  @ViewChild('userQRCodeReportIframe', { static: true }) iframe: ElementRef;
  public userQRCodeReportUrl = '';
  public userQRCodeModal = false;

  @Output() selected = new EventEmitter<any>();
  @Output() clear = new EventEmitter<any>();
  @Input() backToList = new Subject<any>();
  @ViewChild('fileInput') fileInputVariable: any;

  public message: string;
  public users: User[] = [];
  public userTypes: UserTypeModule[] = [];
  public user: User = new User();
  public userLicenceRequest: UserLicenseRequestLogModule = new UserLicenseRequestLogModule();
  public licenseRequestDetail: ServiceProviderActiveUserLicensesModule = new ServiceProviderActiveUserLicensesModule();
  public licenseRequestPayload: SProviderSalesforceLicenseRequestModule = new SProviderSalesforceLicenseRequestModule();

  public userroles: UserRoleModule[] = [];
  public userVenueIds: number[] = [];

  public venues: VenueModule[] = [];
  public venue: VenueModule = new VenueModule();
  public userVenues: RelUserVenue[] = [];
  public corporations: CorporationModule[] = [];

  public countries: CountryModule[] = [];
  public states: StateModule[] = [];
  public cities: CityModule[] = [];
  public badges: BadgeModule[] = [];
  public serviceProviders: ServiceProviderModule[] = [];

  currentUser: User;
  Isdataloaded = false;
  isCorporation = false;
  TodayDate = new Date();

  page = 1;
  size = 10;
  count = 0;
  pageSizes: any[] = [];

  searchString = '';
  searchTerm: string;
  createbox = false;
  isUpdateForm = false;
  isProfileForm = false;
  isUserForm = false;
  isVendorForm = false;
  modalTitle = 'User';
  modalMessage = 'User updated successfully!';
  title = 'Users';
  countryset = true;
  password = '';
  routeTitle: string;
  editColumnName = 'Edit';
  isSupervisor = false;
  isReqComplete = false;
  userCount = 0;
  spLicenseCount = 0;
  genders = [
    { id: 'M', name: 'Male' },
    { id: 'F', name: 'Female' },
  ];

  file: File = null;
  profileImg = 'assets/no-image.jpg';
  profileImgSrc: any = null;
  vendorId = null;
  userVenuesIds = false;
  listviewtypes: any[];
  viewType: any = null;

  listVenueAuthentication: any[] = [];
  isHostNameMatched = false;
  isSysAdmin = false;

  showHidePassword = false;
  showHideQRCode = false;

  stateDisabled = true;
  cityDisabled = true;
  defaultVenueName: string;

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;
  venueBind: number;
  public pageState: StateMaintain = { pageName: 'userForm', page: this.page, size: this.size, viewType: this.viewType };
  formFieldDisabled = false;
  madeActive = false;
  public corporationDropdownSettings: any = {};
  public venueDropdownSettings: any = {};
  public corporationIds: any[] = [];
  public venueIds: any[] = [];

  public requestLicenseModal = false;
  public confirmationRequestLicenseModal = false;
  public licenseRequestErrorMsg = '';
  public userNgForm: any;
  public currentUserLicenseCount: number;
  public isServiceProviderReq = true;
  public isSysAdminRoleSelected = false;
  public ProfileCorporation: any;
  public ProfileVenue: any;
  public AddedByName: string;
  public ModifiedByName: string;
  public perMonthCharges: number;
  public deleteUserConfirmationModal = false;
  public checkContractIdModal = false;
  public isUserImageRemoved = false;

  public internalProRateTotal: number;
  public internalProRate: number;
  public externalProRateTotal: number;
  public externalProRate: number;
  public checkBillingDate = false;
  public removeProfileModal = false;
  public qrCodeLoader = false;
  hiddenCorporations: RelUserCoporation[] = [];
  hiddenVenues: RelUserVenue[] = [];
  userLimitExceeded = false;
  constructor(
    private router: Router,
    private userservice: UserService,
    private utilitiesService: UtilitiesService,
    private userRoleService: UserRoleService,
    public configuration: Configuration,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private countryService: CountryService,
    private serviceprovidersService: ServiceProviderService,
    private stateService: StateService,
    private cityService: CityService,
    private configureDefaultVenueService: ConfigureDefaultVenueService,
    private userLicenseRequestService: UserLicenseRequestLogService,
    public authState: AuthState,
    private store: Store,
  ) {
    this.currentUser = this.authState.getCurrentUser();

    this.routeTitle = route.root.firstChild.snapshot.url.toString(); //.split('-')[0];
    this.message = 'UserComponent';
    this.route.params.subscribe((params) => (this.vendorId = params.id));
    this.isSysAdmin = this.currentUser.UserApplicationLevel == 'ADMIN';

    this.user.UserRole = new UserRoleModule();
    this.user.Country = new CountryModule();
    this.user.State = new StateModule();
    this.user.City = new CityModule();
    this.user.Badge = new BadgeModule();
    this.user.UserType = new UserTypeModule();
    this.user.ServiceProvider = new ServiceProviderModule();
    this.userLicenceRequest = new UserLicenseRequestLogModule();
  }

  ngOnInit() {
    this.corporationDropdownSettings = {
      singleSelection: false,
      idField: 'CorporationId',
      textField: 'CorporationName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'Sorry no data found!',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.venueDropdownSettings = {
      singleSelection: false,
      idField: 'VenueId',
      textField: 'VenueName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'Sorry no data found!',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.venueBind = null;
    this.venueBind = this.authState.getStoredContextVenueId();
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;

    switch (this.routeTitle.toLowerCase()) {
      case 'profile-menu':
        this.isProfileForm = true;
        this.isUserForm = false;
        this.isVendorForm = false;
        this.createbox = true;
        this.isSupervisor = true;
        this.formFieldDisabled = false;
        this.getUserById(this.currentUser.UserId);
        this.modalTitle = 'My Profile';
        this.modalMessage = 'Your profile updated successfully!';
        break;
      case 'user':
      default:
        this.isUserForm = true;
        this.isProfileForm = false;
        this.isVendorForm = false;
        this.createbox = false;
        this.isSupervisor = false;
        this.title = 'User';
        this.editColumnName = 'Edit';
        this.getPageState();
        this.modalTitle = 'User';
        this.modalMessage = 'User updated successfully!';
        // this.isReadOnlyField = false;
        // this.user.RequesterId = this.currentUser.UserId;
        break;
    }

    this.backToList.subscribe((_event) => {
      this.cancel(_event);
    });
    this.AddedByName = this.currentUser.FirstName + ' ' + this.currentUser.LastName;
    this.ModifiedByName = this.currentUser.FirstName + ' ' + this.currentUser.LastName;

    let _this = this;
    window.addEventListener(
      'message',
      function (event) {
        try {
          const message = JSON.parse(event.data);
          if (message.pageLoaded) {
            _this.userQRCodeReportIframe.nativeElement.contentWindow.postMessage(
              '{ "bearerToken": "' + _this.configuration.Token + '"}',
              '*',
            );
          }
        } catch (ex) {}
      },
      false,
    );
  }

  ngOnDestroy() {
    this.backToList.unsubscribe();
  }

  public getPageState() {
    this.getUserCountForServiceProvider();
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null && this.pageState.viewType != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.viewType = this.pageState.viewType;
      this.getAllUsers(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllUsers(this.searchString, this.page, this.size, this.viewType);
    }
  }

  private commonDropDownBind() {
    this.getAllUserRoles('', -1, -1);
    this.getAllServiceProviders();
    this.getAllcountries('', -1, -1);
    this.getAllBadge('', -1, -1);
    this.getAllUserTypes();
  }

  public setAddForm() {
    if (this.userLimitExceeded) {
      let msg = `You have reached the total number of users (${this.spLicenseCount}) for this account. Please delete or inactivate another user, or contact support@traxinsights.com to increase your license before proceeding.`;
      this.utilitiesService.smmodal('User license', msg);
    } else {
      this.profileImgSrc = null;
      this.count = 0;
      this.createbox = true;
      this.isServiceProviderReq = true;
      this.isUpdateForm = false;
      this.searchString = '';
      this.user = new User();
      this.user.UserRole = new UserRoleModule();
      this.user.Country = new CountryModule();
      this.user.State = new StateModule();
      this.user.City = new CityModule();
      this.user.Badge = new BadgeModule();
      this.user.ServiceProvider = new ServiceProviderModule();
      this.user.UserType = new UserTypeModule();
      this.userNgForm = null;
      this.commonDropDownBind();
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });

      setTimeout(() => {
        const element = document.getElementById('Firstname');
        element.focus();
      }, 1000);
      this.venues = [];
      this.corporations = [];
      this.AddedByName = this.currentUser.FirstName + ' ' + this.currentUser.LastName;
      this.ModifiedByName = this.currentUser.FirstName + ' ' + this.currentUser.LastName;
    }
  }

  public setupdateForm() {
    this.createbox = true;
    this.searchString = '';
    this.users = null;
    this.count = 0;
    if (!this.isProfileForm) {
      this.isUpdateForm = true;
    }
    this.commonDropDownBind();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  public cancel(form: NgForm) {
    this.clearFormData(form);
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllUsers(this.searchString, this.page, this.size, this.viewType);
  }

  public UserPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllUsers(this.searchString, this.page, this.size, this.viewType);
  }

  public clearFormData(userForm: NgForm) {
    this.user = new User();
    this.searchString = '';
    this.password = '';
    this.createbox = false;
    this.clear.emit();
    this.user.RelUserVenues = [];
    this.userVenueIds = [];
    this.venueIds = [];
    this.userVenuesIds = false;
    this.corporationIds = [];
    this.user.RelUserCorporations = [];
    this.getAllUsers(this.searchString, this.page, this.size, this.viewType);
    this.listVenueAuthentication = [];
    this.profileImgSrc = null;
    this.file = null;
    this.showHidePassword = false;
    this.showHideQRCode = false;
    this.cities = [];
    this.cityDisabled = true;
    this.serviceProviders = [];
    this.licenseRequestErrorMsg = '';
    this.requestLicenseModal = false;
    this.confirmationRequestLicenseModal = false;
    this.isServiceProviderReq = true;
    this.isSysAdminRoleSelected = false;
    this.isUserImageRemoved = false;
    this.AddedByName = '';
    this.ModifiedByName = '';
    userForm.reset();
  }

  public hasData(): boolean {
    return this.users != null && this.users.length > 0;
  }

  public fileChange(event: any) {
    if (event != null && event && event.target && event.target.files && event.target.files.length > 0) {
      let uploadedFileName = this.utilitiesService.checkUploadedFileName(event.target.files[0].name.split(''));
      if (uploadedFileName) {
        this.utilitiesService.smmodal(this.modalTitle, this.utilitiesService.fileUploadErrorMessage);
        event.target.value = '';
        return;
      }

      const extention = event.target.files[0].name.split('.');
      const ext = extention[extention.length - 1].toLowerCase();
      if (ext == 'jpg' || ext == 'jpeg' || ext == 'png') {
        if (event.target.files && event.target.files[0]) {
          const filename = event.target.files[0].name;
          let name = filename.split('');
          for (let n = 0; n < name.length; n++) {
            if (
              name[n] === '<' ||
              name[n] === '>' ||
              name[n] === '%' ||
              name[n] === ':' ||
              name[n] === '?' ||
              name[n] === '/' ||
              name[n] === '*' ||
              name[n] === '"' ||
              name[n] === '|' ||
              name[n] === '#' ||
              name[n] === '{' ||
              name[n] === '}' ||
              name[n] === '&' ||
              name[n] === '='
            ) {
              this.utilitiesService.smmodal('User', this.utilitiesService.fileUploadErrorMessage);
              event.target.value = '';
              return;
            }
          }

          const filesize = event.target.files[0].size / Math.pow(1024, 2);
          if (filesize > this.utilitiesService.fileUploadSize) {
            this.utilitiesService.mdmodal(
              this.modalTitle,
              'Picture should not be more than ' + this.utilitiesService.fileUploadSize + ' MB.',
            );
            event.target.value = '';
            return;
          } else {
            const fileList: FileList = event.target.files;
            if (fileList.length > 0) {
              this.file = fileList[0];
              const reader = new FileReader();
              reader.onload = this._handleReaderLoaded.bind(this);
              reader.readAsBinaryString(this.file);
            }
          }
        }
      } else {
        this.utilitiesService.smmodal(this.modalTitle, 'Please upload image of type .jpg, .jpeg, .png.');
        event.target.value = '';
      }
      this.isUserImageRemoved = true;
    }
  }

  _handleReaderLoaded(readerEvt: any) {
    const binaryString = readerEvt.target.result;
    this.profileImgSrc = 'data:image/jpeg;base64,' + btoa(binaryString); // Converting binary string data.
  }

  public openRemoveProfileModal() {
    this.removeProfileModal = true;
  }

  public closeRemoveProfileModal() {
    this.removeProfileModal = false;
  }

  fileReset() {
    this.removeProfileModal = false;
    this.fileInputVariable.nativeElement.value = '';
    this.file = null;
    this.profileImgSrc = null;
    this.isUserImageRemoved = true;
  }

  public userSearch(searchString: string) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllUsers(searchString, 1, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllUsers('', this.page, this.size, this.viewType);
  }

  public cancelProfile() {
    const userPermissions = this.authState.getUserPermissions();
    if (userPermissions && userPermissions.length > 0) {
      userPermissions.forEach((item) => {
        if (item.Permission.PermissionName == 'dashboard') {
          this.router.navigate(['dashboard']);
        } else if (item.Permission.PermissionName == 'restroom-overview') {
          this.router.navigate(['restroom-overview']);
        }
      });
    }
  }

  public showHide(event: any) {
    if (event == 'Password') {
      this.showHidePassword = !this.showHidePassword;
    } else if (event == 'QRCode') {
      this.showHideQRCode = !this.showHideQRCode;
    }
  }

  public openUserQRCardPDF() {
    const userId = this.currentUser.UserId;
    const serviceProviderId = this.authState.getStoredContextServiceProviderId();
    const corporationId = this.authState.getStoredContextCorporationId();
    const venueId = this.authState.getStoredContextVenueId();
    this.loaderService.display(true);
    this.userQRCodeModal = true;
    this.userQRCodeReportUrl = this.userservice.userQRCodeReport(userId, serviceProviderId, corporationId, venueId);
    this.addIframeLoadListener();
  }

  public closeUserQRCodeModal() {
    this.userQRCodeModal = false;
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName != this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order == 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllUsers(this.searchString, this.page, this.size, this.viewType);
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType === null) {
      this.viewType = 1;
    }
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllUsers(this.searchString, this.page, this.size, this.viewType);
  }

  public newuser(userForm: NgForm, saveType: string) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (userForm.status == 'INVALID' && userForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('User', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    // Validation to check whether venue level users create only venue level users
    const userApplicationLevel = this.currentUser.UserApplicationLevel;
    if (userApplicationLevel === 'VENUE' && !this.venueIds.length) {
      this.utilitiesService.snackBarWithAutoDismiss('User', 'Please select atleast one venue to proceed!');
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    // Validation to check whether corporation level users create only corporation level users
    if (userApplicationLevel === 'CORPORATION' && !this.corporationIds.length) {
      this.utilitiesService.snackBarWithAutoDismiss('User', 'Please select atleast one corporation to proceed!');
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    const hostNameValidation = this.utilitiesService.checkHost(this.user.Email, this.listVenueAuthentication);
    if (hostNameValidation) {
      this.isHostNameMatched = this.formFieldDisabled ? this.formFieldDisabled : true;
      this.user.Password = null;
      this.user.QRCode = null;
    } else {
      this.isHostNameMatched = this.formFieldDisabled ? this.formFieldDisabled : false;
    }
    const _user: User = new User();

    _user.FirstName = this.utilitiesService.removeInnerSpaces(this.user.FirstName);
    if (_user.FirstName == null || _user.FirstName == '') {
      this.user.FirstName = _user.FirstName;
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    _user.LastName = this.utilitiesService.removeInnerSpaces(this.user.LastName);
    if (_user.LastName == null || _user.LastName == '') {
      this.user.LastName = _user.LastName;
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    _user.QRCode = this.utilitiesService.removeInnerSpaces(this.user.QRCode);
    _user.QRCode = this.authState.AESEncryptText(_user.QRCode);
    if (!this.isHostNameMatched && (_user.QRCode == null || _user.QRCode == '')) {
      this.user.QRCode = _user.QRCode;
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    _user.AddressLineOne = this.utilitiesService.removeInnerSpaces(this.user.AddressLineOne);
    if (_user.AddressLineOne == null || _user.AddressLineOne == '') {
      this.user.AddressLineOne = _user.AddressLineOne;
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    if (this.user.AddressLineTwo != null) {
      _user.AddressLineTwo = this.utilitiesService.removeInnerSpaces(this.user.AddressLineTwo);
      if (_user.AddressLineTwo == null || _user.AddressLineTwo == '') {
        this.user.AddressLineTwo = _user.AddressLineTwo;
      }
    }

    _user.UserRole = new UserRoleModule();
    _user.UserRole.UserRoleId = this.user.UserRole.UserRoleId;
    _user.AddedBy = this.currentUser.UserId;
    _user.Email = this.user.Email;
    _user.Password = this.authState.AESEncryptText(this.user.Password);
    _user.Contact = this.user.Contact;
    _user.Zipcode = this.user.Zipcode;
    _user.Gender = this.user.Gender;

    _user.Country = new CountryModule();
    _user.Country.CountryId = this.user.Country.CountryId;
    _user.State = new StateModule();
    _user.State.StateId = this.user.State.StateId;
    _user.City = new CityModule();
    _user.City.CityId = this.user.City.CityId;
    _user.Badge = new BadgeModule();
    _user.Badge.BadgeId = this.user.Badge.BadgeId;

    _user.ServiceProvider = new ServiceProviderModule();
    if (this.user.ServiceProvider != null && this.user.ServiceProvider.ServiceProviderId != null) {
      _user.ServiceProvider.ServiceProviderId = this.user.ServiceProvider.ServiceProviderId;
    }

    _user.RelUserCorporations = [];
    _user.RelUserCorporations = this.corporationIds;
    _user.RelUserVenues = [];
    _user.RelUserVenues = this.venueIds;
    // _user.RequesterId = this.currentUser.UserId;

    _user.UserType = new UserTypeModule();
    if (this.isSysAdminRoleSelected) {
      _user.UserType.UserTypeId = 1;
    } else {
      _user.UserType.UserTypeId = this.user.UserType.UserTypeId;
    }
    // console.log(_user);

    this.userservice.AddUser(this.utilitiesService.stripScript(_user)).subscribe(
      (data) => {
        if (this.file) {
          let that = this;
          this.userservice
            .UserImage(this.file, data.id)
            .then(function (_response) {
              that.restFun(saveType, userForm, data.Message);
            })
            .catch((err) => {
              console.log(err);
              this.restFun(saveType, userForm, data.Message);
            });
        } else {
          this.restFun(saveType, userForm, data.Message);
        }
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        if (error.status == 409) {
          if (error._body) {
            if (this.authState.canAccess('USER_LICENSE_REQUEST')) {
              let obj = JSON.parse(error._body);
              let objSplit = obj.exception.split(':');
              if (objSplit != null && objSplit.length > 0) {
                this.currentUserLicenseCount = objSplit[1];
              }
              this.licenseRequestErrorMsg = obj.exception;
              this.requestLicenseModal = true;
            } else {
              this.licenseRequestErrorMsg =
                'Unable to add the user! It seems you have consumed all of your licenses, please contact to your administrator.';
            }
            this.userNgForm = userForm;
          }
        }
        console.log(
          'Userservice AddUser Service Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public restFun(saveType: any, userForm: any, msg: any) {
    if (saveType == 'single') {
      this.page = 1;
      this.isReqComplete = false;
      this.loaderService.display(false);
      this.utilitiesService.snackBarWithAutoDismiss(this.modalTitle, msg);
      this.clearFormData(userForm);
    } else if (saveType == 'multiple') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      this.utilitiesService.snackBarWithAutoDismiss(this.modalTitle, msg);
    }
  }

  public updateuser(userForm: NgForm) {
    if (this.madeActive && this.userLimitExceeded) {
      let msg = `You have reached the total number of users (${this.spLicenseCount}) for this account. Please delete or inactivate another user, or contact support@traxinsights.com to increase your license before proceeding.`;
      this.utilitiesService.smmodal('User license', msg);
    } else {
      this.loaderService.display(true);
      this.isReqComplete = true;

      if (userForm.status == 'INVALID' && userForm.disabled == false) {
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.utilitiesService.smmodal('User', 'You cannot proceed ahead until all the fields are filled properly.');
        return;
      }

      // Validation to check whether venue level users create only venue level users
      const userApplicationLevel = this.currentUser.UserApplicationLevel;
      if (userApplicationLevel === 'VENUE' && !this.venueIds?.length && !this.hiddenVenues?.length) {
        this.utilitiesService.snackBarWithAutoDismiss('User', 'Please select atleast one venue to proceed!');
        this.isReqComplete = false;
        this.loaderService.display(false);
        return;
      }

      // Validation to check whether corporation level users create only corporation level users
      if (userApplicationLevel === 'CORPORATION' && !this.corporationIds?.length && !this.hiddenCorporations?.length) {
        this.utilitiesService.snackBarWithAutoDismiss('User', 'Please select atleast one corporation to proceed!');
        this.isReqComplete = false;
        this.loaderService.display(false);
        return;
      }
      const _user: User = new User();

      _user.RelUserCorporations = [];
      _user.RelUserCorporations =
        this.corporationIds?.length || this.hiddenCorporations.length
          ? [...this.corporationIds, ...this.hiddenCorporations]
          : [];
      _user.RelUserVenues = [];
      _user.RelUserVenues =
        this.venueIds?.length || this.hiddenVenues.length ? [...this.venueIds, ...this.hiddenVenues] : [];

      _user.FirstName = this.utilitiesService.removeInnerSpaces(this.user.FirstName);
      if (_user.FirstName == null || _user.FirstName == '') {
        this.user.FirstName = _user.FirstName;
        this.loaderService.display(false);
        this.isReqComplete = false;
        return;
      }

      _user.LastName = this.utilitiesService.removeInnerSpaces(this.user.LastName);
      if (_user.LastName == null || _user.LastName == '') {
        this.user.LastName = _user.LastName;
        this.loaderService.display(false);
        this.isReqComplete = false;
        return;
      }

      _user.QRCode = this.utilitiesService.removeInnerSpaces(this.user.QRCode);
      _user.QRCode = this.authState.AESEncryptText(_user.QRCode);
      if (!this.isHostNameMatched && (_user.QRCode == null || _user.QRCode == '')) {
        this.user.QRCode = _user.QRCode;
        this.loaderService.display(false);
        this.isReqComplete = false;
        return;
      }

      _user.AddressLineOne = this.utilitiesService.removeInnerSpaces(this.user.AddressLineOne);
      if (_user.AddressLineOne == null || _user.AddressLineOne == '') {
        this.user.AddressLineOne = _user.AddressLineOne;
        this.loaderService.display(false);
        this.isReqComplete = false;
        return;
      }

      if (this.user.AddressLineTwo != null) {
        _user.AddressLineTwo = this.utilitiesService.removeInnerSpaces(this.user.AddressLineTwo);
        if (_user.AddressLineTwo == null || _user.AddressLineTwo == '') {
          this.user.AddressLineTwo = _user.AddressLineTwo;
        }
      }

      _user.ModifiedBy = this.currentUser.UserId;
      _user.IsActive = this.user.IsActive ? 1 : 0;
      _user.UserRole = new UserRoleModule();
      _user.UserRole.UserRoleId = this.user.UserRole.UserRoleId;
      _user.Email = this.user.Email;
      _user.Password = this.authState.AESEncryptText(this.user.Password);
      _user.Contact = this.user.Contact;
      _user.Zipcode = this.user.Zipcode;
      _user.Gender = this.user.Gender;
      _user.UserId = this.user.UserId;
      // _user.RequesterId = this.currentUser.UserId;

      _user.Country = new CountryModule();
      _user.Country.CountryId = this.user.Country.CountryId;
      _user.State = new StateModule();
      _user.State.StateId = this.user.State.StateId;
      _user.City = new CityModule();
      _user.City.CityId = this.user.City.CityId;
      _user.Badge = new BadgeModule();
      _user.Badge.BadgeId = this.user.Badge.BadgeId;

      _user.UserType = new UserTypeModule();
      if (this.isSysAdminRoleSelected) {
        _user.UserType.UserTypeId = 1;
      } else {
        _user.UserType.UserTypeId = this.user.UserType.UserTypeId;
      }

      _user.ServiceProvider = new ServiceProviderModule();
      if (this.user.ServiceProvider != null && this.user.ServiceProvider.ServiceProviderId != null) {
        _user.ServiceProvider.ServiceProviderId = this.user.ServiceProvider.ServiceProviderId;
      }
      // console.log(_user);

      this.userservice.Update(this.utilitiesService.stripScript(_user)).subscribe(
        () => {
          if (this.file) {
            this.userservice.UserImage(this.file, this.user.UserId).then(() => this.updateUserImage(this.file.name));
          } else if (this.profileImgSrc == null && this.isUserImageRemoved) {
            this.userservice.RemoveUserImage(this.user.UserId).subscribe(() => this.updateUserImage(null));
          }

          if (this.currentUser.UserId == this.user.UserId) {
            this.store.dispatch(setUserName({ FirstName: this.user.FirstName, LastName: this.user.LastName }));
          }
          this.getUserCountForServiceProvider();
          this.isReqComplete = false;
          this.loaderService.display(false);
          this.utilitiesService.snackBarWithAutoDismiss(this.modalTitle, this.modalMessage);
          this.isUserImageRemoved = false;

          if (!this.isProfileForm) {
            this.clearFormData(userForm);
          }
        },
        (error) => {
          this.isReqComplete = false;
          this.loaderService.display(false);
          if (error.status == 409) {
            if (error._body) {
              if (this.authState.canAccess('USER_LICENSE_REQUEST')) {
                let obj = JSON.parse(error._body);
                let objSplit = obj.exception.split(':');
                if (objSplit != null && objSplit.length > 0) {
                  this.currentUserLicenseCount = objSplit[1];
                }
                this.licenseRequestErrorMsg = obj.exception;
                this.requestLicenseModal = true;
              } else {
                this.licenseRequestErrorMsg =
                  'Unable to add the user! It seems you have consumed all of your licenses, please contact to your administrator';
              }
              this.userNgForm = userForm;
            }
          }
          console.log(
            'Userservice Update Service Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
      );
    }
  }

  private updateUserImage(imageName: string | null) {
    if (this.currentUser.UserId === this.user.UserId) {
      this.store.dispatch(setUserImage({ ImageName: imageName }));
    }
  }

  public getUserById(userId: number) {
    if (
      this.authState.canAccess('USER_VIEW') ||
      this.authState.canAccess('VIEW_MY_LOCATIONS_USERS') ||
      this.isProfileForm
    ) {
      this.getUserDetailsById(userId);
    } else {
      this.utilitiesService.smmodal('User', 'You do not have permission to view user details.');
    }
  }

  public getUserDetailsById(userId: number) {
    this.loaderService.display(true);
    const CPuser = new User();
    CPuser.UserCPId = this.authState.AESEncryptText(userId);
    this.userservice.GetById(CPuser).subscribe(
      (data) => {
        this.user = data;
        this.loaderService.display(false);
        this.AddedByName = this.user.AddedByName;
        this.ModifiedByName = this.user.ModifiedByName;
        this.setRole();
        this.corporations = [];
        this.venues = [];

        const payload = new CorporationModule();
        payload.ContextVenueId = null;
        payload.ServiceProviderId = this.user.ServiceProvider.ServiceProviderId;
        payload.CorporationId = null;
        payload.UserId = null;

        // Fix for fetching the corporations and venues only with user access
        // Only the corporations and venues that have user access are shown to users. rest is kept in hiddenCorporations/hiddenvenues
        // They will be passed on user update
        // TODO: Handle the filtering from the API
        this.configureDefaultVenueService.GetServiceProviderCorporations(payload).subscribe((cor) => {
          this.corporations = cor;
          this.corporationIds = [];
          if (this.user.RelUserCorporations?.length) {
            const activeCorporationIdsSet = new Set(this.corporations.map((corp) => corp.CorporationId));
            const corporationIds = [];
            const hiddenCorporations = [];

            this.user.RelUserCorporations.forEach((corporation) => {
              if (corporation.IsActive) {
                if (activeCorporationIdsSet.has(corporation.CorporationId)) {
                  corporationIds.push(corporation);
                } else {
                  hiddenCorporations.push(corporation);
                }
              }
            });

            this.corporationIds = corporationIds;
            this.hiddenCorporations = hiddenCorporations;
          }

          let userInput = new User();
          userInput.UserId = this.user.UserId != null ? this.user.UserId : 0;
          userInput.userCorporations = this.corporations;
          if (this.corporations?.length) {
            this.userservice.GetCorporationsVenues(userInput).subscribe((venues) => {
              this.venues = venues;
              this.venueIds = [];
              if (this.user.RelUserVenues?.length) {
                const activeVenueIdsSet = new Set(this.venues.map((ven) => ven.VenueId));
                const venueIds = [];
                const hiddenVenues = [];

                this.user.RelUserVenues.forEach((venue) => {
                  if (venue.IsActive) {
                    if (activeVenueIdsSet.has(venue.VenueId)) {
                      venueIds.push(venue);
                    } else {
                      hiddenVenues.push(venue);
                    }
                  }
                });

                this.venueIds = venueIds;
                this.hiddenVenues = hiddenVenues;
              }
            });
          }
        });

        this.user.Password = this.authState.AESDecryptText(this.user.Password);
        this.user.QRCode = this.authState.AESDecryptText(this.user.QRCode);

        if (this.user.AttachmentURL != null) {
          this.profileImgSrc = this.user.AttachmentURL;
        } else {
          this.profileImg = 'assets/no-image.jpg';
        }

        if (this.user.Country.CountryId) {
          this.getAllstatesByCountry(this.user.Country.CountryId);
          if (this.user.State.StateId) {
            this.getAllcitiesByState(this.user.State.StateId);
          }
        }

        if (this.user.DefaultVenueId) {
          const obj = this.venues.filter((v) => v.VenueId == this.user.DefaultVenueId);
          if (obj[0]) {
            this.defaultVenueName = obj[0].VenueName;
          }
        }

        if (this.user.RelUserVenues != null && this.user.RelUserVenues.length > 0) {
          for (const venue of this.user.RelUserVenues) {
            this.changeVenue(venue.VenueId);
          }
        }

        if (this.isProfileForm) {
          if (this.user.RelUserCorporations != null && this.user.RelUserCorporations.length > 0) {
            this.ProfileCorporation = [];
            this.user.RelUserCorporations.forEach(function (corporation) {
              if (corporation.IsActive == 1 && corporation.CorporationName != null) {
                this.ProfileCorporation.push(corporation.CorporationName);
              }
            });
            this.ProfileCorporation = this.ProfileCorporation.join(', ').toString();
          }

          if (this.user.RelUserVenues != null && this.user.RelUserVenues.length > 0) {
            this.ProfileVenue = [];
            this.user.RelUserVenues.forEach((venue) => {
              if (venue.IsActive == 1 && venue.VenueName != null) {
                this.ProfileVenue.push(venue.VenueName);
              }
            });
            this.ProfileVenue = this.ProfileVenue.join(', ').toString();
          }
        }
        this.setupdateForm();
      },
      (error) => {
        this.loaderService.display(false);
      },
    );
  }

  onIsactiveChange(value: boolean): void {
    if (value == true) {
      this.madeActive = true;
    } else {
      this.madeActive = false;
    }
  }

  setRole() {
    if (
      this.user.UserRole != null &&
      this.user.UserRole.RoleCode != null &&
      this.user.UserRole.RoleCode == 'sysadmin'
    ) {
      this.isServiceProviderReq = false;
      this.isSysAdminRoleSelected = true;
    } else {
      this.isServiceProviderReq = true;
      this.isSysAdminRoleSelected = false;
    }
  }

  public getUserCountForServiceProvider() {
    this.loaderService.display(true);
    const CPSP = new UserRoleModule();
    CPSP.UserRoleId = this.authState.AESEncryptText(0);

    this.userRoleService.GetServiceProviderListForDropdown(CPSP).subscribe(
      (data) => {
        this.serviceProviders = data;
        this.loaderService.display(false);
        const CPServiceProvider = new ServiceProviderModule();
        CPServiceProvider.ServiceProviderCPId = this.authState.AESEncryptText(
          this.serviceProviders[0].ServiceProviderId,
        );

        this.serviceprovidersService.GetSingle(CPServiceProvider).subscribe((data) => {
          let serviceProvider = data;
          this.userCount = serviceProvider.InternalUserLicenseCount + serviceProvider.ExternalUserLicenseCount;
          if (this.userCount >= serviceProvider.TotalUserLicenseCount) {
            this.spLicenseCount = serviceProvider.TotalUserLicenseCount;
            this.userLimitExceeded = true;
          } else {
            this.userLimitExceeded = false;
          }
        });
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'userRoleService GetServiceProviderListForDropdown call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  public getAllUsers(searchTerm: string, page: number, size: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.userservice.GetAllUsers(JSON.stringify(searchfilter), page, size).subscribe(
      (data) => {
        this.Isdataloaded = true;
        this.users = data;
        if (this.users.length < 0 && this.users[0].Count / this.utilitiesService.initialPageSize < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'userRoleService getAllUsers Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
      () => (this.count = this.users[0] ? this.users[0].Count : 0),
    );
  }

  public getAllUserRoles(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.userRoleService.GetUserroleDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.Isdataloaded = true;
        this.userroles = data;
        this.loaderService.display(false);
      },
      (error) => {
        console.log('userRoleService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
        this.loaderService.display(false);
      },
    );
  }

  public changeRole($event: number) {
    this.user.UserRole.UserRoleId = $event;
    if (this.user.UserRole.UserRoleId) {
      const obj = this.userroles.filter((r) => r.UserRoleId == this.user.UserRole.UserRoleId);
      if (
        obj[0] &&
        (obj[0].ServiceProviderId == null || obj[0].ServiceProviderId == 0) &&
        (obj[0].CorporationId == null || obj[0].CorporationId == 0) &&
        (obj[0].VenueId == null || obj[0].VenueId == 0)
      ) {
        this.isServiceProviderReq = false;
        this.isSysAdminRoleSelected = true;

        this.user.ServiceProvider.ServiceProviderId = null;
        this.corporations = [];
        this.corporationIds = [];

        this.venues = [];
        this.venueIds = [];
        this.userVenueIds = [];
      } else {
        this.isServiceProviderReq = true;
        this.isSysAdminRoleSelected = false;
      }
    } else {
      this.user.UserRole.RoleCode = '';
      this.isServiceProviderReq = true;
      this.isSysAdminRoleSelected = false;
    }
  }

  public getAllServiceProviders() {
    this.loaderService.display(true);
    const CPSP = new UserRoleModule();
    CPSP.UserRoleId = this.authState.AESEncryptText(0);

    this.userRoleService.GetServiceProviderListForDropdown(CPSP).subscribe(
      (data) => {
        this.serviceProviders = data;
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'userRoleService GetServiceProviderListForDropdown call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  public changeServiceProvider($event: number) {
    this.user.ServiceProvider.ServiceProviderId = $event;
    if (this.user.ServiceProvider.ServiceProviderId) {
      this.corporations = [];
      this.corporationIds = [];
      this.getAllCorporations(this.user.ServiceProvider.ServiceProviderId);
      this.venues = [];
      this.venueIds = [];
      this.userVenueIds = [];
    } else {
      this.user.ServiceProvider.ServiceProviderId = null;
      this.corporations = [];
      this.venues = [];
      this.userVenueIds = [];
      this.corporationIds = [];
      this.venueIds = [];
    }
  }

  public getAllCorporations(serviceProviderId: number) {
    this.loaderService.display(true);
    const payload = new CorporationModule();
    payload.ContextVenueId = null;
    payload.ServiceProviderId = serviceProviderId;
    payload.CorporationId = null;
    payload.UserId = null;

    this.configureDefaultVenueService.GetServiceProviderCorporations(payload).subscribe(
      (data) => {
        this.corporations = data;
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'configureDefaultVenueService GetServiceProviderCorporations call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  public getAllVenues(serviceProviderId: number, corporationId: number) {
    this.loaderService.display(true);
    const payload = new VenueModule();
    payload.ContextVenueId = null;
    payload.ServiceProviderId = serviceProviderId;
    payload.CorporationId = corporationId;
    payload.UserId = null;

    this.configureDefaultVenueService.GetCorporationVenues(payload).subscribe(
      (data) => {
        this.venues = data;
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'configureDefaultVenueService GetCorporationVenues call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  public changeVenue($event: number) {
    let isValueExist = false;

    this.isHostNameMatched = this.formFieldDisabled ? this.formFieldDisabled : false;
    this.listVenueAuthentication = [];

    // this below condition is used for to insert only single venue id so we set the userVenueIds array to empty
    // but when requirement/functionality changes from single venue to multiple then
    // only remove/ comment the below single line
    this.userVenueIds = [];
    this.userVenueIds.push($event);

    for (const venue of this.userVenueIds) {
      if (venue != null) {
        isValueExist = true;
        this.userVenuesIds = true;

        this.getVenueAuthentication(venue);

        if (this.user.Email != null && this.listVenueAuthentication) {
          const hostNameValidation = this.utilitiesService.checkHost(this.user.Email, this.listVenueAuthentication);

          if (hostNameValidation) {
            this.isHostNameMatched = this.formFieldDisabled ? this.formFieldDisabled : true;
            this.user.Password = null;
            this.user.QRCode = null;
          } else {
            this.isHostNameMatched = this.formFieldDisabled ? this.formFieldDisabled : false;
          }
        }
      }
    }

    if (!isValueExist) {
      this.userVenuesIds = false;
      this.isHostNameMatched = this.formFieldDisabled ? this.formFieldDisabled : false;
    }
  }

  public gender($event: any) {
    this.user.Gender = $event;
  }

  public getVenueAuthentication(selectedVenue: any) {
    const obj = this.venues.filter((v) => v.VenueId == selectedVenue);

    if (obj && obj[0] != null) {
      if (obj[0].RelVenueAuthentication != null && obj[0].RelVenueAuthentication.length > 0) {
        switch (obj[0].RelVenueAuthentication[0].AuthenticationType.AuthenticatonTypeName) {
          case 'AZURE_AD':
            for (let i = 0; i < obj[0].RelVenueAuthentication.length; i++) {
              if (
                obj[0].RelVenueAuthentication[i].AuthenticationType.AuthenticationTypeKey.AuthenticationTypeKeyName ==
                'DOMAIN'
              ) {
                const keyObj = obj[0].RelVenueAuthentication.filter(
                  (v) => v.AuthenticationType.AuthenticationTypeKey.AuthenticationTypeKeyName == 'DOMAIN',
                );

                if (keyObj[0] != null) {
                  const keys = keyObj[0].AuthenticationType.AuthenticationTypeKey.AuthenticationTypeKeyValue.replace(
                    /\s/g,
                    '',
                  ).split(',');
                  this.listVenueAuthentication = keys;
                }
              }
            }
            break;

          default:
            this.listVenueAuthentication = [];
            break;
        }
      }
    }
  }

  //// call below function on email field keyup
  public checkEmail() {
    if (this.listVenueAuthentication.length > 0) {
      const hostNameValidation = this.utilitiesService.checkHost(this.user.Email, this.listVenueAuthentication);

      if (hostNameValidation) {
        this.isHostNameMatched = this.formFieldDisabled ? this.formFieldDisabled : true;
        this.user.Password = null;
        this.user.QRCode = null;
      } else {
        this.isHostNameMatched = this.formFieldDisabled ? this.formFieldDisabled : false;
      }
    }
  }

  public getAllcountries(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.countryService.GetCountryDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.countries = data;

        if (!this.isUpdateForm && !this.isProfileForm) {
          const obj = this.countries.filter(
            (c) => c.CountryName == 'USA' || c.CountryName == 'United States Of America',
          );
          if (obj[0]) {
            this.user.Country.CountryId = obj[0].CountryId;
            this.getAllstatesByCountry(this.user.Country.CountryId);
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'countryService GetCountryDropdown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public countryChange($event: any) {
    this.user.Country.CountryId = $event;
    if (this.user.Country.CountryId) {
      this.states = [];
      this.user.State.StateId = null;
      this.getAllstatesByCountry(this.user.Country.CountryId);

      this.cities = [];
      this.cityDisabled = true;
      this.user.City.CityId = null;
    } else {
      this.states = [];
      this.stateDisabled = true;
      this.user.State.StateId = null;

      this.cities = [];
      this.cityDisabled = true;
      this.user.City.CityId = null;
    }
  }

  public getAllstatesByCountry(countryId: number) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: this.searchTerm ? this.searchTerm.trim() : '',
      GenericSearch: 0,
      country: { countryId },
    };

    if (countryId) {
      this.stateService.GetStateDropdown(JSON.stringify(searchfilter), -1, -1).subscribe(
        (data) => {
          this.states = data;
          this.stateDisabled = false;
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'stateService GetStateDropdown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
          this.loaderService.display(false);
        },
      );
    } else {
      this.states = [];
      this.stateDisabled = true;
      this.loaderService.display(false);
    }
  }

  public stateChange($event: any) {
    this.user.State.StateId = $event;
    if (this.user.State.StateId) {
      this.cities = [];
      this.user.City.CityId = null;

      this.getAllcitiesByState(this.user.State.StateId);
    } else {
      this.cities = [];
      this.cityDisabled = true;
      this.user.City.CityId = null;
    }
  }

  public getAllcitiesByState(stateId: number) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: this.searchTerm ? this.searchTerm.trim() : '',
      GenericSearch: 0,
      state: { stateId },
    };
    if (stateId) {
      this.cityService.GetCitiesDropDown(JSON.stringify(searchfilter), -1, -1).subscribe(
        (data) => {
          this.cities = data;
          this.cityDisabled = false;
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'cityService GetCitiesDropDown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
          this.loaderService.display(false);
        },
      );
    } else {
      this.cities = [];
      this.cityDisabled = true;
      this.loaderService.display(false);
    }
  }

  public getAllBadge(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 1 };

    this.userservice.GetBadgeDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.badges = data;
        if (this.user.Badge != null) {
          this.badges.push(this.user.Badge);
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'userservice GetBadgeDropdown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  //// Get venues -------------------------------------------------------------
  public getAllCorporationsVenues(corporationIds: any) {
    this.loaderService.display(true);

    let userInput = new User();
    userInput.UserId = this.user.UserId != null ? this.user.UserId : 0;
    userInput.userCorporations = corporationIds;

    this.userservice.GetCorporationsVenues(userInput).subscribe(
      (data) => {
        this.venues = data;
        this.loaderService.display(false);
        // this.bindVenueIds();
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'userservice GetCorporationsVenues call failed. status:' + error.status + 'Status Text: ' + error.statusText,
        );
      },
    );
  }

  public changeMultiselectCorporation(_$event: any) {
    this.venues = [];
    this.venueIds = [];
    this.getAllCorporationsVenues(this.corporationIds);
  }

  public onSelectAllCorporations($event: any) {
    this.venues = [];
    this.venueIds = [];

    this.corporationIds = [];
    this.corporationIds = $event;
    this.getAllCorporationsVenues($event);
  }

  public onDeSelectCorporation(_$event: any) {
    this.venues = [];
    this.venueIds = [];
    this.getAllCorporationsVenues(this.corporationIds);
  }

  public onDeSelectAllCorporations(_$event: any) {
    this.venues = [];
    this.venueIds = [];
    this.corporationIds = [];
    this.bindCorporationIds();
  }

  public changeMultiselectVenue(_$event: any) {}

  public onSelectAllVenues($event: any) {
    this.venueIds = [];
    this.venueIds = $event;
  }

  public onDeSelectVenue(_$event: any) {}

  public onDeSelectAllVenues(_$event: any) {
    this.venueIds = [];
  }

  public bindCorporationIds() {
    if (this.corporations != null && this.corporations.length > 0) {
      for (let i = 0; i < this.corporations.length; i++) {
        let cpID: CorporationModule = new CorporationModule();
        cpID.CorporationId = this.corporations[i].CorporationId;
        if (cpID != null) {
          this.corporationIds.push(cpID);
        }
      }
    }
  }

  public getAllUserTypes() {
    const searchfilter = { SearchQuery: '', GenericSearch: 1 };

    this.userservice.GetAllUserTypes(JSON.stringify(searchfilter)).subscribe(
      (data) => {
        this.userTypes = data;
      },
      (error) => {
        console.log(
          'userservice GetAllUserTypes Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  //// user license request work flow below ----------------------
  public closeRequestLicenseModal() {
    this.requestLicenseModal = false;
    this.confirmationRequestLicenseModal = false;

    this.currentUserLicenseCount = null;
  }

  public openCreateRequestLicenseModal() {
    this.userLicenceRequest = new UserLicenseRequestLogModule();
    this.confirmationRequestLicenseModal = false;
    this.confirmationRequestLicenseModal = true;
  }

  public closeconfirmationRequestLicenseModal(requestLicenseForm: NgForm) {
    this.confirmationRequestLicenseModal = false;
    this.currentUserLicenseCount = null;
    if (requestLicenseForm != null) {
      requestLicenseForm.reset();
    }
    if (this.userNgForm != null) {
      this.clearFormData(this.userNgForm);
    }
  }

  public getLicenseRequestDetails() {
    this.requestLicenseModal = false;
    this.loaderService.display(true);
    const requestPayload = { ServiceProviderId: this.user.ServiceProvider.ServiceProviderId };

    this.userLicenseRequestService.licenseRequest(JSON.stringify(requestPayload)).subscribe(
      (data) => {
        this.loaderService.display(false);
        this.licenseRequestDetail = new ServiceProviderActiveUserLicensesModule();
        if (data != null) {
          if (data.ContractId && data.ContractId != null) {
            this.licenseRequestDetail = data;
            this.confirmationRequestLicenseModal = true;
            // console.log(this.licenseRequestDetail);
          } else {
            this.checkContractIdModal = true;
          }
        }
      },
      (error) => {
        console.log(
          'userLicenseRequestService licenseRequest Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public closecheckContractIdModalModal() {
    this.checkContractIdModal = false;
  }

  public changeUserAgree() {
    let internalUserLicenseFeeTotal = 0;
    let externalUserLicenseFee = 0;

    if (
      this.licenseRequestPayload.RequestedInternalLicenseCount != null &&
      this.licenseRequestPayload.RequestedInternalLicenseCount != 0
    ) {
      internalUserLicenseFeeTotal =
        this.licenseRequestDetail.InternalUserLicenseFee * this.licenseRequestPayload.RequestedInternalLicenseCount;
    }
    if (
      this.licenseRequestPayload.RequestedExternalLicenseCount != null &&
      this.licenseRequestPayload.RequestedExternalLicenseCount != 0
    ) {
      externalUserLicenseFee =
        this.licenseRequestDetail.ExternalUserLicenseFee * this.licenseRequestPayload.RequestedExternalLicenseCount;
    }

    this.perMonthCharges = internalUserLicenseFeeTotal + externalUserLicenseFee;

    const getDay = this.getDiffDetweenTwoDays(this.licenseRequestDetail.BillingDay);
    if (getDay) {
      this.internalProRate = this.licenseRequestDetail.InternalUserLicenseFee / getDay.DateDifference;
      this.externalProRate = this.licenseRequestDetail.ExternalUserLicenseFee / getDay.DateDifference;

      this.internalProRateTotal =
        this.internalProRate * getDay.Days * this.licenseRequestPayload.RequestedInternalLicenseCount;
      this.externalProRateTotal =
        this.externalProRate * getDay.Days * this.licenseRequestPayload.RequestedExternalLicenseCount;
    }
  }

  public getDiffDetweenTwoDays(billingDay: any) {
    this.checkBillingDate = true;

    let currentDate = new Date();
    let bill = currentDate.getFullYear() + '/' + (currentDate.getMonth() + 1) + '/' + billingDay;
    let a1 = new Date(bill);

    let current = currentDate.getFullYear() + '/' + (currentDate.getMonth() + 1) + '/' + currentDate.getDate();
    let b1 = new Date(current);

    //// calculate difference between billing date with current date -------------------------------------
    let d1 = currentDate.getFullYear() + '/' + (currentDate.getMonth() + 1) + '/' + (billingDay + 1);
    let d2 = currentDate.getFullYear() + '/' + (currentDate.getMonth() + 2) + '/' + (billingDay + 1);

    const bill1Date = new Date(d1);
    const current2Date = new Date(d2);
    const Difference_In_Time_1 = current2Date.getTime() - bill1Date.getTime();
    let diffBtn = Difference_In_Time_1 / (1000 * 3600 * 24);

    if (a1 < b1) {
      let c2 = currentDate.getFullYear() + '/' + (currentDate.getMonth() + 2) + '/' + (billingDay + 1);

      const billDate = new Date(c2);
      const Difference_In_Time = billDate.getTime() - currentDate.getTime();
      const dayDiff = Difference_In_Time / (1000 * 3600 * 24);

      let obj = { Days: Math.floor(dayDiff), DateDifference: diffBtn };
      return obj;
    } else if (a1 > b1) {
      let c2 = currentDate.getFullYear() + '/' + (currentDate.getMonth() + 1) + '/' + (billingDay + 1);

      const billDate = new Date(c2);
      const Difference_In_Time = billDate.getTime() - currentDate.getTime();
      const dayDiff = Difference_In_Time / (1000 * 3600 * 24);

      let obj = { Days: Math.floor(dayDiff), DateDifference: diffBtn };
      return obj;
    } else {
      this.checkBillingDate = false;
    }
  }

  public createLicenseRequest(requestLicenseForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    this.licenseRequestPayload.RequestType = 1;
    this.licenseRequestPayload.ServiceProviderId = this.licenseRequestDetail.ServiceProviderId;
    this.licenseRequestPayload.ServiceProviderName = this.licenseRequestDetail.ServiceProviderName;
    this.licenseRequestPayload.IsUserAgree = this.licenseRequestPayload.IsUserAgree ? 1 : 0;

    this.userLicenseRequestService
      .createLicenseRequest(this.utilitiesService.stripScript(this.licenseRequestPayload))
      .subscribe(
        (data) => {
          this.loaderService.display(false);
          this.isReqComplete = false;
          this.utilitiesService.smmodal('Request User License', data.Message);
          this.clearUserLicenceRequestData(requestLicenseForm);
        },
        (error) => {
          this.isReqComplete = false;
          this.loaderService.display(false);
          console.log(
            'userLicenseRequestService createLicenseRequest Service Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
        },
      );
  }

  public clearUserLicenceRequestData(requestLicenseForm: NgForm) {
    this.confirmationRequestLicenseModal = false;
    this.currentUserLicenseCount = null;
    this.userLicenceRequest = new UserLicenseRequestLogModule();

    this.checkBillingDate = false;
    this.internalProRate = null;
    this.externalProRate = null;
    this.internalProRateTotal = null;
    this.externalProRateTotal = null;

    if (requestLicenseForm != null) {
      requestLicenseForm.reset();
    }
  }

  public openTermsandConditions() {
    let pageUrl = '';
    const baseURL = window.location.href.split('#')[0];
    pageUrl = baseURL + '#' + '/terms-and-conditions/';
    window.open(pageUrl, '_blank');
  }

  public deleteUser() {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (this.userNgForm.status == 'INVALID' && this.userNgForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('User', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }
    const _user: User = new User();
    _user.UserId = this.user.UserId;

    this.userservice.DeleteUser(this.utilitiesService.stripScript(_user)).subscribe(
      (data) => {
        this.getUserCountForServiceProvider();
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.deleteUserConfirmationModal = false;
        this.utilitiesService.smmodal(this.modalTitle, data.Message);

        if (!this.isProfileForm) {
          this.clearFormData(this.userNgForm);
        }
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.deleteUserConfirmationModal = false;
        console.log(
          'Userservice DeleteUser Service Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public openDeleteUserConfirmationModal(userForm: NgForm) {
    this.userNgForm = userForm;
    this.deleteUserConfirmationModal = true;
  }

  public closeDeleteUserConfirmationModal() {
    this.deleteUserConfirmationModal = false;
  }

  public addIframeLoadListener() {
    const iframeElement: HTMLIFrameElement = this.iframe.nativeElement;
    iframeElement.onload = () => {
      this.loaderService.display(false);
      this.qrCodeLoader = true;
    };
    if (this.qrCodeLoader) {
      this.loaderService.display(false);
    }
  }
}
