import {
  IAdditionalCleaningAlerts,
  WorkOrderStatus,
} from 'src/app/features/workordermanagement/workorder/models/cleaning-alert-log.model';
import { IAsset } from './asset.model';
import { IBeacon } from './beacon.model';
import { IBuilding } from './building.model';
import { IElement, IInspectElementAttachment, ILocationElement } from './element.model';
import { Inventory } from './inventory';
import { ILocation } from './location.model';
import { ITasks } from './task.model';
import { User } from './user.model';
import { Venue } from './venue.model';

export interface Pin {
  PinId: number;
  PinColor: string;
}

export interface WorkorderPriority {
  PriorityId: number;
  PriorityLevel: string;
}

export interface WorkorderSeverity {
  SeverityId: number;
  SeverityLevel: string;
}

export interface IOpenAlerts {
  CreatedDate: string;
  Name: string;
  StatusId: number;
  WorkorderId: number;
}

export interface ICreateCleaningAlertRequest {
  VenueName: string;
  BuildingName: string;
  RestRoom: string;
  VenueId: number;
  LocationId: number;
  Level: number;
  CreatorComments: string;
  SkipNotificationIfAttachmentAvilable: boolean;
  Workorder: ICreateCleaningAlertLocation;
}

export interface ICreateCleaningAlertLocation {
  Location: ICreateCleaningAlertLocationTasks;
  isTaskBased: boolean;
}

export interface ICreateCleaningAlertLocationTasks {
  LocationTasks: Partial<ITasks>[];
}

export interface ICreateAlertResponse {
  Message: string;
  id: number;
  msg: number;
}

export interface WorkOrderAttachments {
  rowid: any;
  attachmentImage: any;
  attachmentImageFile: any;
  attachmentImageType: any;
  IsActive: number;
}
export interface ICleaningAlertLogs {
  AdditionalAlerts: IAdditionalCleaningAlerts[];
  GenerateNewWorkOrder: number;
  IsReassign: number;
  ModifiedBy: any;
  ParentWorkorderId: number;
  AddedOn: string;
  AssignedTo: Partial<ICleaningAlertLogAssigned>;
  Building: IBuilding;
  ChildWorkorderId: number;
  Count: number;
  Location: ILocation;
  LocationId: number;
  LocationName: string;
  RequestLocation: string;
  ScheduleStartDate: string;
  SkipNotificationIfAttachmentAvilable: boolean;
  Title: string;
  WorkorderCategory: IWorkOrderCategory;
  WorkorderPriority: Partial<WorkorderPriority>;
  WorkorderSeverity: WorkorderSeverity;
  WorkorderStatus: IWorkOrderStatus;
  ModifiedOn: string;
  WorkorderId: number;
  Inspection: IInspection;
  WorkorderComment: string;
  InspectionId: number;
  WorkorderTransaction: Partial<IWorkorderTransaction>;
  workorderTasks: IWorkorderTask;
  WorkorderInventory: IWorkorderDetailsInventory;
  WorkorderTaskComment: string;
}

export interface ICleaningAlertLogAssigned {
  FirstName: string;
  LastName: string;
  UserId: number;
}

export interface IWorkOrderCategory {
  WorkorderCategoryId: number;
  WorkorderCategoryName: string;
}

export interface IWorkOrderStatus {
  Status: WorkOrderStatus;
  StatusId: number;
  StatusCPId: string;
  CPtype: string;
}

export interface IInspectionBeacon {
  InspectionBeaconId?: number;
  Beacon: Partial<IBeacon>;
  OptIn: string;
  OptOut: string;
  BatteryLevel: number;
  BatteryVoltage?: number;
  timeElapsed: string;
}

export interface IInspection {
  InspectionId: number;
  InspectionCPId: string;
  InspectionTitle: string;
  InspectionDescription: string;
  Location: ILocation;
  Venue: Venue;
  User: User;
  InspectionElements: IInspectionElement[];
  AvgLocationRating: string;
  Element: IElement;
  ElementList: IElement[];
  FollowUpCaNo: number;
  FollowUpRequired: number;
  InspectionComment: string;
  MapID: string;
  InspectionStatus: InspectionStatus;
  OverallComment: string;
  OverallRating: string;
  InspectionBeacons: IInspectionBeacon[];
  InspectionScoreFactor: IInspectionScoreFactor;
}

export interface IInspectionElement {
  InspectionElementId: number;
  InspectionId: number;
  Element: IElement;
  LocationElements: ILocationElement[];
  InspectElementAttachments: IInspectElementAttachment[];
}

export interface InspectionStatus {
  InspectionStatusId: number;
  InsStatus: string;
  InspectionStatusSequence: number;
}

export interface IInspectionScoreFactor {
  InspectionScoreFactorId: number;
  ScoreFactorName: string;
  ScoreFactorCode: string;
  ScoreFactorValue: string;
  VenueInspectionScoreFactorId: any;
}

export interface IWorkorderAndQCLogAndHistoryDetails {
  CleaningAlertDetails: IWorkOrder;
  QCDetails: IQCMaster;
  WorkorderDetails: IWorkorderDetails;
}

export interface IWorkOrder {
  WorkorderId: number;
  WorkorderCPId: string;
  LocationId: number;
  LocationName: string;
  Title: string;
  Description: string;
  WorkorderTypeId: number;
  RequestLocation: string;
  Longitude: number;
  Latitude: number;
  Level: number;
  PinId: number;
  AssetId: number;
  AssetName: string;
  ReportedById: number;
  ReportedByEmail: string;
  ReportedByContactNo: number;
  ReportedBy: number;
  ScheduleStartDate: any;
  NotificationText: string;
  CompletionTime: number;
  ParentWorkorderId: number;
  Asset: IAsset;
  WorkorderCategory: IWorkOrderCategory;
  WorkorderType: IWorkorderType;
  Building: IBuilding;
  WorkorderPriority: WorkorderPriority;
  WorkorderSeverity: WorkorderSeverity;
  WorkorderStatus: IWorkOrderStatus;
  WorkorderTransaction: IWorkorderTransaction;
  AssignedTo: User;
  Supervisor: User;
  User: User;
  CreateUser: User;
  UpdatedUser: User;
  Location: ILocation;
  Locations: ILocation[];
  WorkorderBeacons: IWorkorderBeacon[];
  //  infaxRequest: WorkorderScheduleModule;
  workorderTasks: Task[];
  WorkorderInventory: Inventory[];
  InitialSurvey: string;
  WorkorderTaskComment: IWorkorderTaskComment;
  IsReassign: number;
  GenerateNewWorkOrder: number;
  CleaningAlertCompletionDate: any;
  InspectionId: number;
  Inspection: IInspection;
  WorkorderCompletionDate: string;

  WorkorderComment: string;
  IsCustodial: number;
}

export interface IWorkorderType {
  WorkorderTypeId: number;
  WorkorderTypeName: string;
  PM: number;
}

export interface IWorkorderTransaction {
  AssingedTo: number;
  Comments: string;
  ClosureTemplate: string;
  PriorityId: number;
  SeverityId: number;
  StatusId: number;
  WorkorderId: number;
  WorkorderTransactionId: number;
}

export interface IWorkorderBeacon {
  WorkorderBeaconId: number;
  Beacon: IBeacon;
  OptIn: any;
  OptOut: any;
  BatteryLevel: any;
}

export interface IWorkorderTaskComment {
  WorkorderTaskCommentId: number;
  Comment: string;
  TaskDetails: IWorkorderTask[];
  WorkorderInspections: IWorkorderInspections[];
}

export interface IWorkorderInspections {
  InspectionTask: string;
}

export interface IWorkorderTask {
  workorderTaskId: number;
  WorkorderId: number;
  workorderTaskCommentId: number;
  task: Task;
}

export interface IQCMaster {
  QcId: number;
  QcCPId: string;
  Title: string;
  Description: string;
  Location: ILocation;
  LocationId: number;
  Latitude: string;
  Longitude: string;
  Comments: string;
  Level: number;
  ScheduleStartDate: any;
  VenueId: number;
  QcTransactionId: number;
  WorkorderId: number;
  PreQCFeedback: string;
  PreCleaningFeedback: string;
  QCTransaction: IQCTransaction;
  QCTaskComment: IQCTaskComment;
  QCTaskFeedbacks: IQCTaskFeedback[];
  QCTaskFeedback: IQCTaskFeedback[];
  QCInventoryRefills: IQCInventoryRefill[];
  AssignedTo: User;
  QCAssignedTo: User;
  ParentWorkorderId: number;
  QCImages: IWorkorderQCImages[];
  QCComplitionDate: any;
  QCWorkorderDetails: IWorkOrder;
  QCBeacons: IQCBeacon[];
  QCstatus: IWorkOrderStatus[];
}

export interface IQCTransaction {
  QcTransactionId: number;
  QcId: number;
  WorkorderId: number;
  Comments: string;
  StatusId: number;
  Status: string;
  AssignedTo: number;
  GenerateNewWorkorder: number;
}

export interface IQCTaskComment {
  QcTaskCommentId: number;
  QcId: number;
  QcComment: string;
}

export interface IQCTaskFeedback {
  QcTaskId: number;
  QcId: number;
  TaskId: number;
  TaskName: string;
  QcTaskCommentId: number;
  QcInput: string;
  QcComment: string;

  ImageName: string;
  ImageType: string;
}

export interface IQCInventoryRefill {
  QcInventoryRefillId: number;
  QcId: number;
  InventoryId: number;
  InventoryName: string;
  Quantity: number;

  ImageName: string;
  ImageType: string;
}

export interface IQCBeacon {
  QcId?: number;
  QcBeaconId?: number;
  // QcMaster: QCMaster;
  Beacon: Partial<IBeacon>;
  OptIn: any;
  OptOut: any;
  BatteryLevel: number;
  BatteryVoltage?: number;
  timeElapsed: string;
}

export interface IWorkorderQCImages {
  WorkorderId: number;
  WorkorderQCImageId: number;
  ImageName: string;
  ImageType: string;
}

export interface IWorkorderDetails {
  CleaningAlertNumber: number;
  Name: string;
  VenueName: string;
  BuildingName: string;
  RestRoomName: string;
  CleaningAlertCreateTime: any;
  AssignedToName: string;
  Beacons: IWorkorderDetailsBeacon[];
  Badges: IWorkorderDetailsBeacon[];
  Inventory: IWorkorderDetailsInventory[];
  Task: IWorkorderDetailsTaskComment;
  Status: IWorkorderDetailsStatus[];
  WorkorderQCImages: IWorkorderQCImages[];
  IsBadgeEnable: number;
  CleaningAlertCompletionDate: any;
  InitialSurvey: string;
  CreatorComments: string;
  WorkorderCreatorAttachments: IWorkorderCreatorAttachments[];
}

export interface IWorkorderCreatorAttachments {
  WorkorderCreatorAttachmentId: number;
  AttachmentTitle: string;
  AttachmentName: string;
  AttachmentType: string;
  Comment: string;

  rowid: any;
  attachmentImage: any;
  attachmentImageFile: any;
  attachmentImageType: string;
  IsActive: number;
  AttachmentURL: string;
}

export interface IWorkorderDetailsStatus {
  StatusId: number;
  Status: string;
  Time: any;
  UserName: string;
}

export interface IWorkorderDetailsBeacon {
  Name: number;
  Optin: any;
  Optout: any;
  BatteryLevel: any;
  TimeElapsed: string;
  Tag?: string;
}

export interface ICleaningAlertDetailsBadge {
  Name: number;
  Optin: any;
  Optout: any;
  BatteryLevel: any;
  TimeElapsed: string;
  Tag: string;
}

export interface IWorkorderDetailsTaskComment {
  WorkorderTaskCommentId?: number;
  TaskDetails?: IWorkorderDetailsTask[];
  Comment: string;
  QcComment?: string;
}

export interface IWorkorderDetailsTask {
  ItemName: number;
  IsActive: any;
}

export interface IWorkorderDetailsInventory {
  ItemName: number;
  Status: string;
  Quantity: string;
}

export interface IWorkorderQCImages {
  WorkorderId: number;
  WorkorderQCImageId: number;
  ImageName: string;
  ImageType: string;
}
export enum AlertStatus {
  Open = 1,
  Assigned = 19,
  Declined = 20,
  InProgress = 4,
  Complete = 21,
  AwaitingQC = 8,
  Cancel = 23,
  ClosedNotComplete = 24,
  QCAssigned = 22,
  QCInProgress = 10,
  QCRejected = 5,
  QCApproved = 6,
}
