// import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';;
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { SalesforceUserLicenceRequest, User, UserType } from 'src/app/core/models/user.model';
import { ResponseMessage } from 'src/app/shared/genericentity/genericentity';
import { BadgeModule } from '../../../venuemanagement/badge/badge.module';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { UserRoleModule } from '../../role/role.module';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public AddUser = (body: User): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/user';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public UpdatePassword = (email: string, password: string): Observable<ResponseMessage> => {
    const body = { email: email, password: password };
    const _url: string = this.configuration.AuthServer + 'sso/update-password';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public Update = (body: User): Observable<User> => {
    const _url: string = this.configuration.Server + 'admin/user';
    return this.http.put<User>(_url, body);
  };

  public DeleteUser = (body: User): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/delete/user';
    return this.http.put<ResponseMessage>(_url, body);
  };

  public GetAllUsers = (body: string, page: number, size: number): Observable<User[]> => {
    const _url: string = this.configuration.Server + 'admin/all/users/' + page + '/' + size;
    return this.http.post<User[]>(_url, body);
  };

  // This method is used to bind user dropdown
  public GetAllUsersForDropdown = (body: string, page: number, size: number): Observable<User[]> => {
    const _url: string = this.configuration.Server + 'admin/users/' + page + '/' + size;
    return this.http.post<User[]>(_url, body);
  };

  public GetAllUserRoles = (userId: number): Observable<UserRoleModule[]> => {
    const _url: string = this.configuration.Server + 'admin/user/child/roles/' + userId;
    return this.http.get<UserRoleModule[]>(_url);
  };

  public GetById = (body: Partial<User>): Observable<User> => {
    const _url: string = this.configuration.Server + 'admin/user/details';
    return this.http.post<User>(_url, body);
  };

  public RemoveUserImage = (userId: number): Observable<ResponseMessage> => {
    const toUpdate = JSON.stringify({});
    const _url: string = this.configuration.Server + 'admin/user/remove/profile/image/' + userId;
    return this.http.put<ResponseMessage>(_url, toUpdate);
  };

  UserImage(filedata: File, id: number): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response) as ResponseMessage);
          } else {
            reject(xhr.response);
          }
        }
      };
      const _url: string = this.configuration.Server + 'admin/user/' + id;

      xhr.open('PUT', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);

      const formData = new FormData();
      formData.append('file', filedata, filedata.name);
      xhr.send(formData);
    });
  }

  public GetAllUnscheduledUsers = (body: string, page: number, size: number, zoneId: number): Observable<User[]> => {
    const _url: string = this.configuration.Server + 'admin/unschedule/zone/' + zoneId + '/users/' + page + '/' + size;
    return this.http.post<User[]>(_url, body);
  };

  public GetAllParentRoleUsersByVenue = (body: User): Observable<User[]> => {
    const _url: string = this.configuration.Server + 'admin/parent/role/venue/users';
    return this.http.post<User[]>(_url, body);
  };

  public GetAuthenticationVenues = (body: string, page: number, size: number): Observable<VenueModule[]> => {
    const _url: string = this.configuration.Server + 'venue/authentication/venues/' + page + '/' + size;
    return this.http.post<VenueModule[]>(_url, body);
  };

  public GetAllRequesters = (body: User): Observable<User[]> => {
    const _url: string = this.configuration.Server + 'admin/role/venue/requesters';
    return this.http.post<User[]>(_url, body);
  };

  public GetBadgeDropdown = (body: string, page: number, size: number): Observable<BadgeModule[]> => {
    const _url: string = this.configuration.Server + 'badge/unassigned/badge/' + page + '/' + size;
    return this.http.post<BadgeModule[]>(_url, body);
  };

  public CreateUserLicenceRequest = (body: SalesforceUserLicenceRequest): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/salesforce/users/licence';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public GetCorporationsVenues = (body: Partial<User>): Observable<VenueModule[]> => {
    const _url: string = this.configuration.Server + 'admin/coporations/venues';
    return this.http.post<VenueModule[]>(_url, body);
  };

  public GetAllUserTypes = (body: string): Observable<UserType[]> => {
    const _url: string = this.configuration.Server + 'admin/user/types';
    return this.http.post<UserType[]>(_url, body);
  };

  public userQRCodeReport = (
    userId: number,
    serviceProviderId: number,
    corporationId: number,
    venueId: number,
  ): string => {
    const _url: string =
      this.configuration.Server +
      'UserCard.aspx?userId=' +
      userId +
      '&serviceProviderId=' +
      serviceProviderId +
      '&corporationId=' +
      corporationId +
      '&venueId=' +
      venueId;
    return _url;
  };
}
