<section class="content-header">
  <div class="row">
    <div class="col-lg-2 col-md-3 col-sm-12 col-xs-12 form-heading">
      <i class="fa fa-calendar heading-icon"></i>
      <span class="list-heading">CALENDAR</span>
    </div>

    <div class="col-lg-10 col-md-10 col-sm-10 add-section add-section" *ngIf="'CALENDAR_SCHEDULE_ADD' | canAccess">
      <button type="submit" class="btn btn-add pull-right" (click)="scheduleDialogOpen(scheduleDialog)">
        New Schedule
      </button>
    </div>
  </div>
</section>

<section class="content" style="overflow-y: auto !important">
  <div class="formsnmap calender-view">
    <div class="row text-center">
      <div class="col-md-4" style="padding-right: 0">
        <div class="btn-group">
          <div
            class="btn btn-calendar"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
          >
            Previous
          </div>

          <div
            #mwlCalendarToday
            class="btn btn-calendar"
            mwlCalendarToday
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
          >
            Today
          </div>

          <div
            class="btn btn-calendar"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
          >
            Next
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <h3>{{ viewDate | calendarDate: view + 'ViewTitle' : 'en' }}</h3>
      </div>

      <div class="col-md-4">
        <div class="btn-group">
          <div
            class="btn btn-calendar"
            (click)="setView(CalendarView.Month)"
            [class.active]="view === CalendarView.Month"
          >
            Month
          </div>

          <div
            class="btn btn-calendar"
            (click)="setView(CalendarView.Week)"
            [class.active]="view === CalendarView.Week"
          >
            Week
          </div>

          <div class="btn btn-calendar" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
            Day
          </div>
        </div>
      </div>
    </div>

    <br />

    <div [ngSwitch]="view">
      <mwl-calendar-month-view
        *ngSwitchCase="CalendarView.Month"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="getVenueById('OpenUpdateScheduleModel', null, openDialogTemplate, $event.event, scheduleDialog)"
        (beforeViewRender)="beforeViewRender($event)"
      >
      </mwl-calendar-month-view>

      <mwl-calendar-week-view
        *ngSwitchCase="CalendarView.Week"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="getVenueById('OpenUpdateScheduleModel', null, openDialogTemplate, $event.event, scheduleDialog)"
        (beforeViewRender)="beforeViewRender($event)"
      >
      </mwl-calendar-week-view>

      <mwl-calendar-day-view
        *ngSwitchCase="CalendarView.Day"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="getVenueById('OpenUpdateScheduleModel', null, openDialogTemplate, $event.event, scheduleDialog)"
        (beforeViewRender)="beforeViewRender($event)"
      >
      </mwl-calendar-day-view>
    </div>

    <br /><br /><br />

    <ng-template #openDialogTemplate>
      <h2 matDialogTitle>Update Schedule</h2>
      <mat-dialog-content>
        <div>
          <p>This is one appointment in series. What do you want to open?</p>

          <mat-radio-group name="IsEntireSeriesModifiedControl" id="IsEntireSeriesModifiedControl">
            <div class="radioBtns">
              <mat-radio-button
                *ngIf="IsrbJustThisOneVisible"
                id="rbJustThisOne"
                value="rbJustThisOne"
                (change)="rbSelectionChange($event)"
                [checked]="IsrbJustThisOneChecked"
                >This appointment</mat-radio-button
              >
              <mat-radio-button
                id="rbTheEntireSeries"
                value="rbTheEntireSeries"
                (change)="rbSelectionChange($event)"
                [checked]="IsrbTheEntireSeriesChecked"
                >The entire series</mat-radio-button
              >
            </div>
          </mat-radio-group>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="center">
        <button
          class="btn btn-types schedule-save"
          (click)="UpdateScheduleDetails(scheduleDialog, calendar.ScheduleId)"
        >
          Yes
        </button>
        <button
          class="btn btn-types pull-right schedule-cancel"
          [mat-dialog-close]="true"
          (click)="scheduleDialogClose()"
        >
          No
        </button>
      </mat-dialog-actions>
    </ng-template>

    <!--Delete-->
    <ng-template #openDeletDialogTemplate>
      <h2 matDialogTitle style="text-align: center">Delete Schedule</h2>
      <mat-dialog-content>
        <div>
          <div class="deleteIcon">
            <i class="fas fa-trash-alt"></i>
          </div>

          <p>This is one appointment in series. What do you want to delete?</p>

          <mat-radio-group name="IsEntireSeriesModifiedControl" id="IsEntireSeriesModifiedControl">
            <div class="radioBtns">
              <mat-radio-button id="rbJustThisOne" value="rbJustThisOne" (change)="rbSelectionChange($event)" checked
                >This appointment</mat-radio-button
              >
              <mat-radio-button
                id="rbTheEntireSeries"
                value="rbTheEntireSeries"
                (change)="rbSelectionChange($event)"
                *ngIf="isDeleteOptionVisible"
                >The entire series</mat-radio-button
              >
            </div>
          </mat-radio-group>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="center">
        <button
          class="btn btn-types schedule-save btn-delete"
          (click)="deleteScheduleDetails(calendar.ScheduleId, openConfirmDeletDialogTemplate)"
        >
          Delete
        </button>
        <button class="btn btn-types pull-right schedule-cancel" [mat-dialog-close]="true">Cancel</button>
      </mat-dialog-actions>
    </ng-template>

    <!--Are you sure!(delete)-->
    <ng-template #openConfirmDeletDialogTemplate>
      <h2 matDialogTitle>Delete Schedule</h2>
      <mat-dialog-content style="text-align: center">
        <div>
          <p class="deleteSchedule">Are you sure you want to delete?</p>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="center">
        <button class="btn btn-types schedule-save" (click)="DeleteSchedule()">Yes</button>
        <button
          class="btn btn-types pull-right schedule-cancel"
          [mat-dialog-close]="true"
          (click)="scheduleDialogClose()"
        >
          No
        </button>
      </mat-dialog-actions>
    </ng-template>

    <!--Are you sure!(Add schedule)-->
    <ng-template #openConfirmAddscheduleDialogTemplate>
      <h2 matDialogTitle>New Schedule</h2>
      <mat-dialog-content style="text-align: center">
        <div>
          <p class="startSchedule">
            Schedule start time already past of today so do you want to create schedule from tomorrow ?
          </p>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="center">
        <button class="btn btn-types schedule-save" (click)="addScheduleFromNextDay()">
          Create schedule from tomorrow
        </button>
        <button
          class="btn btn-types pull-right schedule-cancel"
          [mat-dialog-close]="true"
          (click)="scheduleDialogClose()"
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </ng-template>
  </div>
</section>

<ng-template #scheduleDialog>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="scheduleDialogClose(scheduleForm)"
    style="margin: 13px 20px 0px 0px"
  >
    <span aria-hidden="true">×</span>
  </button>

  <h2 matDialogTitle>{{ scheduleDialogTitle }}</h2>
  <mat-dialog-content class="outer-box">
    <form #scheduleForm="ngForm" class="form-horizontal scheduleBox">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
          <label for="Title"><span id="red-circle">&#x25CF;</span> Title</label>
          <div>
            <input
              type="text"
              class="form-control"
              name="ScheduleTitle"
              placeholder="Title"
              [(ngModel)]="calendar.ScheduleTitle"
              #ScheduleTitle="ngModel"
              maxlength="100"
              required
              [pattern]="utilitiesService.alphanumericRegex"
            />
            <div
              *ngIf="ScheduleTitle.errors && (ScheduleTitle.dirty || ScheduleTitle.touched)"
              class="alert alert-danger"
              style="padding: 5px"
            >
              <div [hidden]="!ScheduleTitle.errors.required" class="text-danger">Title is required.</div>
              <div [hidden]="!ScheduleTitle.errors.pattern" class="text-danger">
                {{ utilitiesService.alphanumericRegexMessage }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
          <label for="description">Description</label>
          <div>
            <textarea
              id="description"
              name="description"
              #description="ngModel"
              type="text"
              class="form-control"
              placeholder="Description"
              [(ngModel)]="calendar.ScheduleDescription"
              autocomplete="off"
              maxlength="250"
            ></textarea>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="calendar.ScheduleType && !isScheduleUpdate">
          <label for="ScheduleType"><span id="red-circle">&#x25CF;</span> Event Types</label>
          <ngx-atlas-select
            (model)="changeScheduleType($event)"
            [list]="ScheduleTypes"
            [idField]="'ScheduleTypeId'"
            [textField]="'ScheduleTypeName'"
            [placeholder]="'Select Event Type'"
            [allowClear]="true"
            [multiple]="false"
            [required]="false"
            [disabled]="eventTypeDisabled"
            [selectedValue]="calendar.ScheduleType.ScheduleTypeId"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="calendar.ScheduleType && isScheduleUpdate">
          <label for="ScheduleType"><span id="red-circle">&#x25CF;</span> Event Types</label>
          <div>
            <div class="clipping-wrapper">
              <p class="readOnly clipping">{{ calendar.ScheduleType.ScheduleTypeName }}</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group is-billable-section">
          <mat-checkbox
            [(ngModel)]="calendar.IsBillableWork"
            name="IsBillableWork"
            id="isBillableWorkControl"
            *ngIf="calendar.ScheduleType.ScheduleTypeCode === 'PROJECT_WORK'"
            >Billable Work</mat-checkbox
          >
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group">
          <label for="Corporation"><span id="red-circle">&#x25CF;</span> Corporation</label>
          <div>
            <div class="clipping-wrapper">
              <p class="readOnly clipping">{{ calendar.CorporationName }}</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group">
          <label for="Venue"><span id="red-circle">&#x25CF;</span> Venue</label>
          <div>
            <div class="clipping-wrapper">
              <p class="readOnly clipping" style="height: 20px">{{ calendar.Venue.VenueName }}</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="!isScheduleUpdate">
          <label for="building"> Building</label>
          <div>
            <ngx-atlas-select
              (model)="changeBuilding($event)"
              [list]="buildings"
              [idField]="'BuildingId'"
              [textField]="'BuildingName'"
              [placeholder]="'Select Building'"
              [allowClear]="true"
              [multiple]="false"
              [required]="false"
              [disabled]="buildingDisabled"
              [selectedValue]="filterBuildingId"
            >
            </ngx-atlas-select>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="isScheduleUpdate">
          <label for="building">Building</label>
          <div>
            <div class="clipping-wrapper">
              <p class="readOnly clipping" style="height: 20px">{{ calendar.Location.Building.BuildingName }}</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="!isScheduleUpdate">
          <label for="zone"> Zone</label>
          <div>
            <ngx-atlas-select
              (model)="changeZone($event)"
              [list]="zones"
              [idField]="'ZoneId'"
              [textField]="'ZoneName'"
              [placeholder]="'Select Zone'"
              [allowClear]="true"
              [multiple]="false"
              [required]="false"
              [disabled]="zoneDisabled"
              [selectedValue]="filterZoneId"
            >
            </ngx-atlas-select>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="isScheduleUpdate">
          <label for="zone">Zone</label>
          <div>
            <div class="clipping-wrapper">
              <p class="readOnly clipping" style="height: 20px">{{ calendar.Location.Zone.ZoneName }}</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="!isScheduleUpdate">
          <label for="Location"
            ><span
              id="red-circle"
              *ngIf="
                calendar.ScheduleType.ScheduleTypeCode !== 'EQUIPMENT_PM' &&
                calendar.ScheduleType.ScheduleTypeCode !== 'PROJECT_WORK'
              "
              >&#x25CF;</span
            >
            Location</label
          >
          <ngx-atlas-select
            (model)="changeLocation($event)"
            [list]="locations"
            [idField]="'LocationId'"
            [textField]="'LocationName'"
            [placeholder]="'Select Location'"
            [allowClear]="true"
            [multiple]="false"
            [required]="false"
            [disabled]="locationDisabled"
            [selectedValue]="calendar.Location.LocationId"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="isScheduleUpdate">
          <label for="zone"
            ><span
              id="red-circle"
              *ngIf="
                calendar.ScheduleType.ScheduleTypeCode !== 'EQUIPMENT_PM' &&
                calendar.ScheduleType.ScheduleTypeCode !== 'PROJECT_WORK'
              "
              >&#x25CF;</span
            >
            Location</label
          >
          <div>
            <div class="clipping-wrapper">
              <p class="readOnly clipping" style="height: 20px">{{ calendar.Location.LocationName }}</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="calendar.ScheduleType.ScheduleTypeCode === 'INSPECT'">
          <label for="Inspector">Inspector</label>
          <ngx-atlas-select
            (model)="calendar.Inspector.UserId = $event"
            [list]="inspectors"
            [idField]="'UserId'"
            [textField]="'FirstName'"
            [placeholder]="'Select Inspector'"
            [allowClear]="true"
            [multiple]="false"
            [required]="false"
            [disabled]="isINS_ASSIGNMENT"
            [selectedValue]="calendar.Inspector.UserId"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="calendar.ScheduleType.ScheduleTypeCode === 'QC'">
          <label for="Supervisor"> Supervisor</label>
          <ngx-atlas-select
            (model)="calendar.Supervisor.UserId = $event"
            [list]="supervisors"
            [idField]="'UserId'"
            [textField]="'FirstName'"
            [placeholder]="'Select Supervisor'"
            [allowClear]="true"
            [multiple]="false"
            [required]="false"
            [disabled]="isQC_ASSIGNMENT"
            [selectedValue]="calendar.Supervisor.UserId"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-6 col-md-6 col-sm-6 form-group"
          *ngIf="calendar.ScheduleType.ScheduleTypeCode === 'CLEANING_ALERT'"
        >
          <label for="Worker"> Worker</label>
          <ngx-atlas-select
            (model)="calendar.AssigneTo.UserId = $event"
            [list]="workers"
            [idField]="'UserId'"
            [textField]="'FirstName'"
            [placeholder]="'Select Worker'"
            [allowClear]="true"
            [multiple]="false"
            [required]="false"
            [disabled]="isCA_ASSIGNMENT"
            [selectedValue]="calendar.AssigneTo.UserId"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-6 col-md-6 col-sm-6 form-group"
          *ngIf="calendar.ScheduleType.ScheduleTypeCode === 'EQUIPMENT_PM'"
        >
          <label for="Worker"><span id="red-circle">&#x25CF;</span> Worker</label>
          <ngx-atlas-select
            (model)="calendar.AssigneTo.UserId = $event"
            [list]="workers"
            [idField]="'UserId'"
            [textField]="'FirstName'"
            [placeholder]="'Select Worker'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="calendar.AssigneTo.UserId"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-6 col-md-6 col-sm-6 form-group"
          *ngIf="calendar.ScheduleType.ScheduleTypeCode === 'PROJECT_WORK'"
        >
          <label for="Worker"><span id="red-circle">&#x25CF;</span> Worker</label>
          <ngx-atlas-select
            (model)="calendar.AssigneTo.UserId = $event"
            [list]="workers"
            [idField]="'UserId'"
            [textField]="'FirstName'"
            [placeholder]="'Select Worker'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="calendar.AssigneTo.UserId"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12" style="padding: 0">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="startTimeValue"><span id="red-circle">&#x25CF;</span> Start Time</label>
            <div>
              <div style="display: flex">
                <input
                  id="startTimeValue"
                  name="startTimeValue"
                  class="form-control dateFormat"
                  aria-label="default time"
                  [ngxTimepicker]="startTimeValue"
                  required
                  [(ngModel)]="calendar.StartTime"
                  placeholder="Select Start Time"
                  [disableClick]="true"
                  readonly
                />
                <ngx-material-timepicker-toggle
                  [for]="startTimeValue"
                  [disabled]="StartTimeDisabled"
                ></ngx-material-timepicker-toggle>
                <ngx-material-timepicker
                  (closed)="dateTimePickerClose($event)"
                  #startTimeValue
                ></ngx-material-timepicker>
              </div>
              <div
                *ngIf="startTimeValue.errors && (startTimeValue.dirty || startTimeValue.touched)"
                class="alert alert-danger validation-message"
              >
                <div [hidden]="!startTimeValue.errors.required" class="text-danger">Start time is required.</div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="endTimeValue"><span id="red-circle">&#x25CF;</span> End Time</label>
            <div>
              <div style="display: flex">
                <input
                  id="endTimeValue"
                  name="endTimeValue"
                  class="form-control dateFormat"
                  aria-label="default time"
                  [ngxTimepicker]="endTimeValue"
                  required
                  [(ngModel)]="calendar.EndTime"
                  placeholder="Select Start Time"
                  [disableClick]="true"
                  readonly
                />
                <ngx-material-timepicker-toggle
                  [for]="endTimeValue"
                  [disabled]="EndTimeDisabled"
                ></ngx-material-timepicker-toggle>
                <ngx-material-timepicker (closed)="dateTimePickerClose($event)" #endTimeValue></ngx-material-timepicker>
              </div>
              <div
                *ngIf="endTimeValue.errors && (endTimeValue.dirty || endTimeValue.touched)"
                class="alert alert-danger validation-message"
              >
                <div [hidden]="!endTimeValue.errors.required" class="text-danger">End time is required.</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12" style="padding: 0">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group is-billable-section" style="padding: 0; display: block">
            <mat-checkbox
              name="IsBetweenSelectedTime"
              [(ngModel)]="calendar.IsBetweenSelectedTime"
              id="isTimeIntervalController"
              (change)="IsTimeIntervalEvent($event)"
              [disabled]="isTimeIntervalDisabled"
              >Create Events for Time Interval</mat-checkbox
            >
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="Worker"
              ><span id="red-circle" *ngIf="calendar.IsBetweenSelectedTime">&#x25CF;</span> Time Interval</label
            >
            <ngx-atlas-select
              (model)="changeTimeInterval($event)"
              [list]="intervalMinutes"
              [idField]="'ScheduleIntervalValue'"
              [textField]="'ScheduleIntervalName'"
              [placeholder]="'Select Time Interval'"
              [allowClear]="true"
              [multiple]="false"
              [required]="calendar.IsBetweenSelectedTime"
              [disabled]="timeIntervalDisabled"
              [selectedValue]="calendar.IntervalMinutes"
            >
            </ngx-atlas-select>
          </div>
        </div>

        <div
          class="col-lg-12 col-md-12 col-sm-12"
          style="padding: 0"
          *ngIf="calendar.IsBetweenSelectedTime && calendar.IntervalMinutes !== null && intervalTimeSlotes.length === 0"
        >
          <div class="col-lg-12 col-md-12 col-sm-12" style="color: #be3f42; text-align: center; padding-bottom: 15px">
            <label> No events can be created for the selected time period. </label>
          </div>
        </div>

        <div
          class="col-lg-12 col-md-12 col-sm-12"
          style="padding: 0"
          *ngIf="intervalTimeSlotes && intervalTimeSlotes.length > 0"
        >
          <div class="col-lg-12 col-md-12 col-sm-12">
            <label> Total {{ intervalTimeSlotes.length }} event(s) will be created between selected time period </label>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
            <div class="box-body table-responsive" style="max-height: 200px">
              <table class="table table-hover table-responsive" style="background-color: #eee !important">
                <tbody *ngFor="let interval of intervalTimeSlotes">
                  <tr>
                    <td style="cursor: default">{{ interval.RowId }}.</td>
                    <td style="cursor: default">{{ interval.Time }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12" style="padding: 0">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group is-active">
            <mat-checkbox
              id="isFullDayEventControler"
              name="IsFullDayEvent"
              [(ngModel)]="calendar.IsFullDayEvent"
              (change)="IsFullDayEvent($event)"
              [disabled]="isFullDayEventDisabled"
              >Is A Full Day Event</mat-checkbox
            >
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group is-active">
            <mat-checkbox
              [(ngModel)]="calendar.IsRecurring"
              name="IsRecurring"
              (change)="IsRecurring($event)"
              id="isRecurringControl"
              [disabled]="isRecurringCheckboxDisabled"
              >Is Recurring</mat-checkbox
            >
          </div>
        </div>

        <div
          *ngIf="IsFullDayDatePanalVisible"
          class="col-lg-12 col-md-12 col-sm-12 form-group"
          style="border: 1px solid; margin: 0 0 15px 14px"
        >
          <div class="col-lg-6 col-md-6 col-sm-6 form-group float-left">
            <mat-form-field style="padding-top: 16px">
              <input
                matInput
                [(ngModel)]="calendar.StartDate"
                name="StartDate"
                (focus)="picker1.open()"
                [min]="TodayDate"
                [matDatepicker]="picker1"
                placeholder="Start Date"
                (dateChange)="onNonRecurringDateSelection(true)"
                readonly
                [disabled]="eventIsRecurring"
              />
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group float-left">
            <mat-form-field style="padding-top: 16px">
              <input
                id="endDate1"
                matInput
                [(ngModel)]="calendar.EndDate"
                name="EndDate1"
                (focus)="picker2.open()"
                [min]="calendar.StartDate"
                [max]="maxDate"
                [matDatepicker]="picker2"
                placeholder="End Date"
                (dateChange)="onNonRecurringDateSelection()"
                readonly
                [disabled]="eventIsRecurring"
              />
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div
          *ngIf="IsVisible"
          class="col-lg-12 col-md-12 col-sm-12 form-group"
          style="border: 1px solid; margin: 0 0 5px 9px; padding-top: 15px"
        >
          <mat-label><b>Recurring Type</b></mat-label>

          <mat-tab-group
            animationDuration="500ms"
            #tabGroup
            (selectedTabChange)="onTabChanged($event)"
            [selectedIndex]="selectedTabIndex"
          >
            <mat-tab label="Daily" id="tab1">
              <mat-radio-group class="col-md-2" name="dailyRadioControl">
                <mat-radio-button
                  id="rbDailyDays"
                  (change)="rbDailyChange($event)"
                  value="rbDailyDays"
                  [checked]="IsrbDailyDaysChecked"
                  >Every</mat-radio-button
                >
              </mat-radio-group>

              <mat-form-field appearance="outline" class="col-md-1" style="padding: 0 0 10px; width: 75px">
                <input
                  matInput
                  type="number"
                  id="dayControl"
                  name="dayControl"
                  #dayControl="ngModel"
                  [(ngModel)]="SeperationCountDaily"
                  (focus)="DailyonFocus('rbDailyDays')"
                  min="1"
                  maxlength="3"
                  (ngModelChange)="dayMonthYearValidation($event, 'day')"
                  app_onlynumber
                />
                <div *ngIf="dayControl.errors && (dayControl.dirty || dayControl.touched)" class="alert alert-danger">
                  <div [hidden]="!dayControl.errors.max" class="text-danger">
                    Value should be less that or equal to 365
                  </div>
                </div>
              </mat-form-field>
              day(s)

              <br />

              <mat-radio-group class="col-lg-12 col-md-12 col-sm-12" name="dailyRadioControl">
                <mat-radio-button
                  id="rbDailyEveryWeekdays"
                  (change)="rbDailyChange($event)"
                  value="rbDailyEveryWeekdays"
                  [checked]="rbDailyEveryWeekdays"
                  >Every Weekday</mat-radio-button
                >
              </mat-radio-group>
            </mat-tab>

            <mat-tab label="Weekly" id="tab2">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <label class="col-md-2"> Recur Every</label>

                <mat-form-field appearance="outline" class="col-md-1" style="padding: 0 0 10px; width: 75px">
                  <input
                    matInput
                    type="number"
                    id="weekControl"
                    name="weekControl"
                    [(ngModel)]="calendar.RecurringPattern.SeperationCount"
                    maxlength="2"
                    (ngModelChange)="dayMonthYearValidation($event, 'week')"
                    app_onlynumber
                  />
                </mat-form-field>

                <label class="col-md-2"> week(s) on:</label>
              </div>

              <br />

              <div class="col-lg-12 col-md-12 col-sm-12">
                <mat-checkbox
                  name="sundaycheckboxControl"
                  [(ngModel)]="calendar.RecurringPattern.Sunday"
                  id="sundaycheckboxControl"
                  >Sunday</mat-checkbox
                >
                &nbsp;&nbsp;
                <mat-checkbox
                  name="mondaycheckboxControl"
                  [(ngModel)]="calendar.RecurringPattern.Monday"
                  id="mondaycheckboxControl"
                  >Monday</mat-checkbox
                >
                &nbsp;&nbsp;
                <mat-checkbox
                  name="thuesdaycheckboxControl"
                  [(ngModel)]="calendar.RecurringPattern.Tuesday"
                  id="thuesdaycheckboxControl"
                  >Tuesday</mat-checkbox
                >
                &nbsp;&nbsp;
                <mat-checkbox
                  name="wednesdaycheckboxControl"
                  [(ngModel)]="calendar.RecurringPattern.Wednesday"
                  id="wednesdaycheckboxControl"
                  >Wednesday</mat-checkbox
                >
                &nbsp;&nbsp;
                <mat-checkbox
                  name="thursdaycheckboxControl"
                  [(ngModel)]="calendar.RecurringPattern.Thusday"
                  id="thursdaycheckboxControl"
                  >Thursday</mat-checkbox
                >
                &nbsp;&nbsp;
                <mat-checkbox
                  name="fridaycheckboxControl"
                  [(ngModel)]="calendar.RecurringPattern.Friday"
                  id="fridaycheckboxControl"
                  >Friday</mat-checkbox
                >
                &nbsp;&nbsp;
                <mat-checkbox
                  name="saturdaycheckboxControl"
                  [(ngModel)]="calendar.RecurringPattern.Saturday"
                  id="saturdaycheckboxControl"
                  >Saturday</mat-checkbox
                >
                &nbsp;&nbsp;
              </div>
            </mat-tab>

            <mat-tab label="Monthly" id="tab3">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <mat-radio-group class="col-md-2" name="dayRadioControl" id="dayRadioControl">
                  <mat-radio-button
                    id="rbMonthlyDays"
                    (change)="rbMonthlyChange($event)"
                    value="rbMonthlyDays"
                    [checked]="rbMonthlyDays"
                    >Day</mat-radio-button
                  >
                </mat-radio-group>

                <mat-form-field appearance="outline" class="col-md-1" style="padding: 0 0 10px; width: 75px">
                  <input
                    matInput
                    type="number"
                    id="everyDayControl"
                    name="everyDayControl"
                    [(ngModel)]="DayOfMonthMonthly1"
                    (focus)="MonthlyonFocus1()"
                    min="1"
                    maxlength="2"
                    digitOnly
                    (ngModelChange)="dayMonthYearValidation($event, 'dayCount')"
                    app_onlynumber
                  />
                </mat-form-field>
                <div class="col-md-2">of every</div>

                <mat-form-field appearance="outline" class="col-md-1" style="padding: 0 0 10px; width: 75px">
                  <input
                    matInput
                    type="number"
                    id="everyMonthControl"
                    name="everyMonthControl"
                    [(ngModel)]="SeperationCountMonthly1"
                    (focus)="MonthlyonFocus1()"
                    min="1"
                    maxlength="2"
                    digitOnly
                    (ngModelChange)="dayMonthYearValidation($event, 'month')"
                    app_onlynumber
                  />
                </mat-form-field>

                <div class="col-md-2">month(s)</div>
              </div>

              <br />

              <div class="col-lg-12 col-md-12 col-sm-12 monthYearDrodown">
                <mat-radio-group class="col-md-2" name="dayRadioControl" id="dayRadioControl">
                  <mat-radio-button
                    id="rbMonthlyMonths"
                    (change)="rbMonthlyChange($event)"
                    value="rbMonthlyMonths"
                    [checked]="rbMonthlyMonths"
                    >The</mat-radio-button
                  >
                </mat-radio-group>

                <div class="col-md-2" title="Select Week Of Month">
                  <ngx-atlas-select
                    (model)="MonthlyonFocusWeek($event)"
                    [list]="utilitiesService.weekOfMonths"
                    [idField]="'id'"
                    [textField]="'name'"
                    [placeholder]="'Select Week Of Month'"
                    [allowClear]="true"
                    [multiple]="false"
                    [required]="false"
                    [selectedValue]="WeekOfMonthMonthly"
                  >
                  </ngx-atlas-select>
                </div>
                &nbsp;&nbsp;

                <div class="col-md-2" title="Select Monthly Day">
                  <ngx-atlas-select
                    (model)="MonthlyonFocusDays($event)"
                    [list]="utilitiesService.monthlyDays"
                    [idField]="'id'"
                    [textField]="'name'"
                    [placeholder]="'Select Monthly Day'"
                    [allowClear]="true"
                    [multiple]="false"
                    [required]="false"
                    [selectedValue]="DayOfMonthMonthly2"
                  >
                  </ngx-atlas-select>
                </div>

                <label class="col-md-2">of every</label>

                <mat-form-field appearance="outline" class="col-md-1" style="padding: 0 0 10px; width: 75px">
                  <input
                    matInput
                    type="number"
                    id="everyMonthsControl"
                    name="everyMonthsControl"
                    (focus)="MonthlyonFocus2()"
                    [(ngModel)]="SeperationCountMonthly2"
                    min="1"
                    maxlength="2"
                    (ngModelChange)="dayMonthYearValidation($event, 'month')"
                    app_onlynumber
                  />
                </mat-form-field>

                <label class="col-md-2">month(s)</label>
              </div>
            </mat-tab>

            <mat-tab label="Yearly" id="tab4">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <label class="col-md-2">Recur Every</label>
                <mat-form-field appearance="outline" class="col-md-1" style="padding: 0 0 10px; width: 75px">
                  <input
                    matInput
                    type="number"
                    min="1"
                    [(ngModel)]="SeperationCountYearly"
                    maxlength="2"
                    oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                    name="yearsControl"
                    app_onlynumber
                  />
                </mat-form-field>
                <label class="col-md-2">year(s)</label>
              </div>

              <br />

              <div class="col-lg-12 col-md-12 col-sm-12 monthYearDrodown">
                <mat-radio-group class="col-md-2" name="yearlyWeekRadioControl" fid="yearlyWeekRadioControl">
                  <mat-radio-button
                    id="rbYearlyMonths"
                    (change)="rbYearlyChange($event)"
                    value="rbYearlyMonths"
                    [checked]="rbYearlyMonths"
                    >On the:</mat-radio-button
                  >
                </mat-radio-group>

                <div class="col-md-2" title="Select Month Of Year">
                  <ngx-atlas-select
                    (model)="YearlyonFocusOnMonth1($event)"
                    [list]="utilitiesService.monthsOfYear"
                    [idField]="'id'"
                    [textField]="'name'"
                    [placeholder]="'Select Month Of Year'"
                    [allowClear]="true"
                    [multiple]="false"
                    [required]="false"
                    [selectedValue]="MonthOfYearYearly"
                  >
                  </ngx-atlas-select>
                </div>
                &nbsp;&nbsp;

                <mat-form-field appearance="outline" class="col-md-1" style="padding: 0 0 10px; width: 75px">
                  <input
                    matInput
                    type="number"
                    id="monthsDayControl"
                    name="monthsDayControl"
                    [(ngModel)]="DayOfMonthYearly"
                    (focus)="YearlyonFocus1()"
                    min="1"
                    maxlength="2"
                    (ngModelChange)="dayMonthYearValidation($event, 'dayMonth')"
                    app_onlynumber
                  />
                </mat-form-field>
              </div>

              <br />

              <div class="col-lg-12 col-md-12 col-sm-12 monthYearDrodown">
                <mat-radio-group class="col-md-2" name="yearlyWeekRadioControl" id="yearlyWeekRadioControl">
                  <mat-radio-button
                    id="rbYearlyMonthWeekDay"
                    (change)="rbYearlyChange($event)"
                    value="rbYearlyMonthWeekDay"
                    [checked]="rbYearlyMonthWeekDay"
                    >The</mat-radio-button
                  >
                </mat-radio-group>

                <div class="col-md-2" title="Select Week Of Month">
                  <ngx-atlas-select
                    (model)="YearlyonFocusonWeek2($event)"
                    [list]="utilitiesService.weekOfMonths"
                    [idField]="'id'"
                    [textField]="'name'"
                    [placeholder]="'Select Week Of Month'"
                    [allowClear]="true"
                    [multiple]="false"
                    [required]="false"
                    [selectedValue]="YearlyWeekOfMonth"
                  >
                  </ngx-atlas-select>
                </div>
                &nbsp;&nbsp;

                <div class="col-md-2" title="Select Monthly Day">
                  <ngx-atlas-select
                    (model)="YearlyonFocusonDay2($event)"
                    [list]="utilitiesService.monthlyDays"
                    [idField]="'id'"
                    [textField]="'name'"
                    [placeholder]="'Select Monthly Day'"
                    [allowClear]="true"
                    [multiple]="false"
                    [required]="false"
                    [selectedValue]="YearlyDayOfMonth"
                  >
                  </ngx-atlas-select>
                </div>

                <label class="col-md-1">of</label>

                <div class="col-md-2" title="Select Month Of Year">
                  <ngx-atlas-select
                    (model)="YearlyonFocusonMonth2($event)"
                    [list]="utilitiesService.monthsOfYear"
                    [idField]="'id'"
                    [textField]="'name'"
                    [placeholder]="'Select Month Of Year'"
                    [allowClear]="true"
                    [multiple]="false"
                    [required]="false"
                    [selectedValue]="YearlyMonthOfYear"
                  >
                  </ngx-atlas-select>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <div
          *ngIf="IsVisible"
          class="col-lg-12 col-md-12 col-sm-12 form-group"
          style="border: 1px solid; padding-top: 15px; margin: 0 0 15px 9px"
        >
          <div class="col-md-4 col-lg-4 form-group float-left">
            <label class="">Range of Recurrence</label>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <mat-form-field style="padding-top: 16px">
                <input
                  matInput
                  [(ngModel)]="calendar.StartDate"
                  name="StartDate"
                  [min]="TodayDate"
                  (focus)="picker1.open()"
                  [matDatepicker]="picker1"
                  placeholder="Start Date"
                  (dateChange)="BindAllSupervisors()"
                  readonly
                />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="col-md-8 col-lg-8 form-group">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <mat-radio-group
                  name="rangeOfRecurrenceRadioControl"
                  id="rangeOfRecurrenceRadioControl"
                  (change)="onChangerangeOfRecurrenceRadioControl($event)"
                >
                  <mat-radio-button
                    #rbRecurrenceNoEndDate
                    id="rbRecurrenceNoEndDate"
                    value="1"
                    (focus)="rangeOfRecurrenceFocus('rbRecurrenceNoEndDate')"
                    (change)="rangeOfRecurrenceRadioclick($event)"
                    [checked]="IsrbRecurrenceNoEndDateCheked"
                    >No End Date</mat-radio-button
                  >
                </mat-radio-group>
              </div>
            </div>

            <br />

            <div class="col-lg-12 col-md-12 col-sm-12 rangeOccurance">
              <div class="col-md-4 col-lg-4">
                <mat-radio-group
                  name="rangeOfRecurrenceRadioControl"
                  id="rangeOfRecurrenceRadioControl"
                  (change)="onChangerangeOfRecurrenceRadioControl($event)"
                >
                  <mat-radio-button
                    id="rbRecurrenceEndAfter"
                    value="2"
                    (change)="rangeOfRecurrenceRadioclick($event)"
                    [checked]="IsrbRecurrenceEndAfterChecked"
                    >End After:</mat-radio-button
                  > </mat-radio-group
                >&nbsp;&nbsp;
              </div>

              <div class="col-md-4 col-lg-4">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    type="number"
                    (focus)="rangeOfRecurrenceFocus('rbRecurrenceEndAfter')"
                    [(ngModel)]="calendar.RecurringPattern.Occurences"
                    maxlength="3"
                    oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                    name="occurencesControl"
                    app_onlynumber
                  />
                </mat-form-field>
              </div>

              <div class="col-md-4 col-lg-4" style="margin-top: 10px">occurences</div>
            </div>

            <br />

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <mat-radio-group
                  name="rangeOfRecurrenceRadioControl"
                  id="rangeOfRecurrenceRadioControl"
                  (change)="onChangerangeOfRecurrenceRadioControl($event)"
                >
                  <mat-radio-button
                    id="rbRecurrenceEndby"
                    value="3"
                    (change)="rangeOfRecurrenceRadioclick($event)"
                    [checked]="IsrbRecurrenceEndbyChecked"
                    >End By: &nbsp;&nbsp;</mat-radio-button
                  >
                </mat-radio-group>

                <mat-form-field>
                  <input
                    id="endDate2"
                    matInput
                    [(ngModel)]="calendar.EndDate"
                    [min]="calendar.StartDate"
                    name="EndDate2"
                    (focus)="[picker2.open(), rangeOfRecurrenceFocus('rbRecurrenceEndby')]"
                    [matDatepicker]="picker2"
                    placeholder="End Date"
                    (dateChange)="BindAllSupervisors()"
                    readonly
                    [disabled]="calendar.IsRecurring && !IsrbRecurrenceEndbyChecked"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <br />
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <button
            class="btn btn-types schedule-save"
            *ngIf="isNewState"
            (click)="getVenueById('addSchedule', scheduleForm, null, null, null)"
            [disabled]="
              calendar.ScheduleTitle == '' ||
              !calendar.ScheduleType.ScheduleTypeId ||
              !calendar.CorporationId ||
              !calendar.Venue.VenueId ||
              (calendar.ScheduleType.ScheduleTypeCode !== 'EQUIPMENT_PM' &&
                calendar.ScheduleType.ScheduleTypeCode !== 'PROJECT_WORK' &&
                !calendar.Location.LocationId) ||
              ((calendar.ScheduleType.ScheduleTypeCode === 'EQUIPMENT_PM' ||
                calendar.ScheduleType.ScheduleTypeCode === 'PROJECT_WORK') &&
                !calendar.AssigneTo.UserId) ||
              (calendar.IsBetweenSelectedTime && !calendar.IntervalMinutes) ||
              isReqComplete ||
              (intervalTimeSlotes && calendar.IntervalMinutes != null && intervalTimeSlotes.length === 0)
            "
          >
            Save
          </button>
          <button
            class="btn btn-types schedule-save"
            *ngIf="!isNewState"
            (click)="getVenueById('updateSchedule', scheduleForm, null, null, null)"
            [disabled]="
              calendar.ScheduleTitle == '' ||
              !calendar.ScheduleType.ScheduleTypeId ||
              !calendar.CorporationId ||
              !calendar.Venue.VenueId ||
              (calendar.ScheduleType.ScheduleTypeCode !== 'EQUIPMENT_PM' &&
                calendar.ScheduleType.ScheduleTypeCode !== 'PROJECT_WORK' &&
                !calendar.Location.LocationId) ||
              ((calendar.ScheduleType.ScheduleTypeCode === 'EQUIPMENT_PM' ||
                calendar.ScheduleType.ScheduleTypeCode === 'PROJECT_WORK') &&
                !calendar.AssigneTo.UserId) ||
              (calendar.IsBetweenSelectedTime && !calendar.IntervalMinutes) ||
              isReqComplete ||
              (intervalTimeSlotes && calendar.IntervalMinutes != null && intervalTimeSlotes.length === 0)
            "
          >
            Update
          </button>
          <button class="btn btn-types pull-right schedule-cancel" (click)="scheduleDialogClose(scheduleForm)">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</ng-template>

<div
  class="modal col-lg-12 col-md-12 col-sm-12 col-xs-12"
  id="occurrenceDetails"
  [ngStyle]="occurrenceDetails ? { display: 'block' } : { display: 'none' }"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" style="height: 60px; padding: 0 0 0 10px">
        <h3 style="text-align: center">Occurrence Details</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeModalContent()"
          style="position: absolute; top: 20px; right: 10px"
        >
          &times;
        </button>
      </div>

      <div class="modal-body" style="text-align: left; padding: 30px">
        <div>
          <p><b>Title:</b> {{ modalData?.event?.title }}</p>

          <p *ngIf="modalData?.event?.ScheduleDescription != null && modalData?.event?.ScheduleDescription != ''">
            <b>Description:</b> {{ modalData?.event?.ScheduleDescription }}
          </p>

          <p><b>Event Type:</b> {{ modalData?.event?.scheduleTypeName }}</p>

          <p><b>Corporation:</b> {{ modalData?.event?.CorporationName }}</p>

          <p><b>Venue:</b> {{ modalData?.event?.venueName }}</p>

          <p *ngIf="modalData?.event?.buildingName != null && modalData?.event?.buildingName != ''">
            <b>Building:</b> {{ modalData?.event?.buildingName }}
          </p>

          <p *ngIf="modalData?.event?.zoneName != null && modalData?.event?.zoneName != ''">
            <b>Zone:</b> {{ modalData?.event?.zoneName }}
          </p>

          <p><b>Location:</b> {{ modalData?.event?.locationName }}</p>

          <p *ngIf="modalData?.event?.scheduleTypeCode == 'QC'">
            <b>Supervisor:</b>
            {{ modalData?.event?.SupervisorName == 'null null' ? ' ' : modalData?.event?.SupervisorName }}
          </p>

          <p *ngIf="modalData?.event?.scheduleTypeCode == 'CLEANING_ALERT'">
            <b>Worker:</b> {{ modalData?.event?.WorkerName == 'null null' ? ' ' : modalData?.event?.WorkerName }}
          </p>

          <p *ngIf="modalData?.event?.scheduleTypeCode == 'EQUIPMENT_PM'">
            <b>Worker:</b> {{ modalData?.event?.WorkerName == 'null null' ? ' ' : modalData?.event?.WorkerName }}
          </p>

          <p *ngIf="modalData?.event?.scheduleTypeCode == 'PROJECT_WORK'">
            <b>Worker:</b> {{ modalData?.event?.WorkerName == 'null null' ? ' ' : modalData?.event?.WorkerName }}
          </p>

          <p *ngIf="modalData?.event?.scheduleTypeCode == 'INSPECT'">
            <b>Inspector:</b>
            {{ modalData?.event?.InspectorName == 'null null' ? ' ' : modalData?.event?.InspectorName }}
          </p>

          <p>
            <b>Start Time:</b> {{ modalData?.event?.IsFullDayEvent == 1 ? '12:00 am' : modalData?.event?.StartTime }}
          </p>

          <p><b>End Time:</b> {{ modalData?.event?.IsFullDayEvent == 1 ? '12:00 am' : modalData?.event?.EndTime }}</p>

          <p><b>Start Date:</b> {{ modalData?.event?.start | date: 'MM/dd/yyyy' }}</p>

          <p *ngIf="modalData?.event?.IsFullDayEvent == 1">
            <b>End Date:</b> {{ modalData?.event?.IsFullDayEndDate | date: 'MM/dd/yyyy' }}
          </p>

          <p *ngIf="modalData?.event?.IsFullDayEvent == 0">
            <b>End Date:</b>
            <span *ngIf="modalData?.event?.IsRecurring === 1 && modalData?.event?.isNoEndDateEvent; else endDateTemplate"> --</span>
            <ng-template #endDateTemplate>
              <span>{{ (modalData?.event?.endDate ? modalData?.event?.endDate :
                modalData?.event?.end) | date: 'MM/dd/yyyy' }}</span>
            </ng-template>
          </p>
        </div>
      </div>

      <div class="modal-footer add-section modal-footer-btn" style="padding: 5px">
        <button type="submit" class="btn btn-types schedule-cancel" (click)="closeModalContent()">Close</button>
      </div>
    </div>
  </div>
</div>
