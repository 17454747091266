<div class="trax-table">
  <div class="table-container mat-elevation-z8">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col-md-7 col-sm-12">
          <mat-paginator
            [pageSizeOptions]="[25, 100, 200, 400]"
            showFirstLastButtons
            aria-label="Select page"
            [pageSize]="25"
            [length]="tableDataSource?.data?.length"
          >
          </mat-paginator>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1">
          <button
            class="btn-primary btn btn-xs button-text"
            id="templateReset"
            type="button"
            style="margin-top: 20px"
            (click)="refresh()"
          >
            <span *ngIf="isDataLoading"> Loading... </span>
            <span *ngIf="!tableDataSource && !isDataLoading">
              <i class="fa fa-search"></i>
              Load
            </span>
            <span *ngIf="!!tableDataSource && !isDataLoading">
              <i class="fa fa-refresh"></i>
              Refresh
            </span>
          </button>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1">
          <button
            class="btn-primary btn btn-xs button-text"
            id="templateReset"
            type="button"
            style="margin-top: 20px"
            (click)="clearFilters()"
            *ngIf="showFilter"
          >
            <i class="fa fa-window-close"></i> Clear Filters
          </button>
        </div>
      </div>
    </div>

    <!-- Column Filters -->
    <ng-container *ngIf="filterForm">
      <form [formGroup]="filterForm" class="column-filters">
        <ng-container *ngFor="let filter of columnFilters">
          <mat-form-field appearance="outline" class="filter-field">
            <mat-label>{{ getColumnHeader(filter.column) }}</mat-label>
            <mat-select [formControlName]="filter.control" multiple #select="matSelect">
              <mat-select-trigger>
                <span
                  title="{{
                    select.value?.length
                      ? select.value?.length === 1
                        ? select.value[0]
                        : select.value[0] + ' + ' + (select.selected.length - 1) + ' others'
                      : ''
                  }}"
                >
                  {{ select.value?.length ? select.value[0] : '' }}</span
                >
                <span *ngIf="select.selected?.length > 1"> (+{{ select.selected.length - 1 }} others) </span>
              </mat-select-trigger>

              <div class="select-search-box">
                <mat-form-field appearance="outline" class="search-field">
                  <input
                    matInput
                    [formControl]="filter.searchControl"
                    placeholder="Search..."
                    (keydown.Space)="$event.stopPropagation()"
                  />
                </mat-form-field>
              </div>

              <mat-option *ngFor="let option of filter.filteredOptions" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </form>
    </ng-container>

    <!-- Rest of the template remains the same -->
    <div class="table-scroll">
      <table
        mat-table
        [dataSource]="dataSource$ | async"
        class="mat-elevation-z8 w-100 hover-highlight"
        #exporter="matTableExporter"
        matTableExporter
        (exportCompleted)="onExportComplete($event)"
        matSort
        [matSortActive]="firstField"
        matSortDirection="asc"
        matSortDisableClear
      >
        <ng-container *ngFor="let column of columnConfig" [matColumnDef]="column.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [style]="tableHeaderStyle">
            {{ getColumnHeader(column) }}
          </th>
          <td
            mat-cell
            *matCellDef="let data"
            [matTooltip]="formatValue(data[column.name], column)"
            matTooltipPosition="below"
            [style]="tableDataStyle"
            class="data24"
          >
            {{ formatValue(data[column.name], column) }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick(row)"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">No data matching the filter.</td>
        </tr>
      </table>
    </div>
    <div *ngIf="isExporting" class="export-progress">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      <div class="export-status">Exporting to {{ exportType.toUpperCase() }}...</div>
    </div>

    <button mat-raised-button (click)="export('xlsx')">
      <ng-container *ngIf="isExporting && exportType === 'xlsx'">
        <mat-spinner diameter="20"></mat-spinner> Exporting...
      </ng-container>
      <ng-container *ngIf="!(isExporting && exportType === 'xlsx')"> Excel </ng-container>
    </button>

    <button mat-raised-button (click)="export('csv')">
      <ng-container *ngIf="isExporting && exportType === 'csv'">
        <mat-spinner diameter="20"></mat-spinner> Exporting...
      </ng-container>
      <ng-container *ngIf="!(isExporting && exportType === 'csv')"> CSV </ng-container>
    </button>

    <button mat-raised-button (click)="export('txt')">
      <ng-container *ngIf="isExporting && exportType === 'txt'">
        <mat-spinner diameter="20"></mat-spinner> Exporting...
      </ng-container>
      <ng-container *ngIf="!(isExporting && exportType === 'txt')"> Text </ng-container>
    </button>
  </div>
</div>
