import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { catchError } from 'rxjs/operators';
import { Configuration } from 'src/app/app.constants';
import { LoaderService } from '../../directives/loader.service';
import { UtilitiesService } from './utilityservice';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private ignoreExtensions = ['.svg', '.png', '.jpeg', '.jpg', '.mov', '.m4v', '.mp4'];
  private ignoreExtensionsRegex: RegExp[] = [];
  constructor(
    private utilitiesService: UtilitiesService,
    private router: Router,
    private configuration: Configuration,
    private localSt: LocalStorageService,
    private loaderService: LoaderService,
  ) {
    this.ignoreExtensionsRegex = this.ignoreExtensions.map((ext) => new RegExp(`.${ext}$`, 'i'));
  }

  private getHTTPHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Authorization', 'bearer ' + this.configuration.Token);
    return headers;
  }

  private getReportHeaders() {
    return new HttpHeaders({
      Accept: 'text/html, application/xhtml+xml, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith(this.configuration.Server)) {
      return next.handle(request);
    }

    const ignoredExtension = this.ignoreExtensionsRegex.some((ext) => !!request.url.match(ext));
    if (ignoredExtension) {
      return next.handle(request);
    }

    if (request.url.startsWith(this.configuration.Server + 'Report/')) {
      const reportRequest = request.clone({
        headers: this.getReportHeaders(),
        responseType: 'text',
      });
      return next.handle(reportRequest);
    }
    const apiRequest = request.clone({
      headers: this.getHTTPHeaders(),
    });

    return next.handle(apiRequest).pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  clearTraxUser() {
    this.localSt.clear('traxcurrentuser');
    this.localSt.clear('traxtoken');
    this.localSt.clear('traxuservenueid');
    this.localSt.clear('traxuserzoneid');
  }

  public handleError = (error: any) => {
    const _obj = error && error.error ? error.error : {};
    if (window.navigator.onLine && error.status == 401 && !this.configuration.isUnauthorizedState) {
      this.configuration.isUnauthorizedState = true;
      this.configuration.Token = '';
      this.configuration.userVenueId = 0;
      this.clearTraxUser();

      if (this.router.url.indexOf('/login') < 0) {
        this.utilitiesService.smmodal('Sign In', 'Your session has been expired.');
        this.router.navigate(['login']);
      }
    } else if (window.navigator.onLine && error.status != 401) {
      switch (error.url.replace(this.configuration.Server, '')) {
        case 'oauth/login':
        case 'open/claims/web/login':
          this.configuration.isUnauthorizedState = false;
          if (error.status == 400) {
            this.utilitiesService.smmodal('Sign In', 'Authentication failed. Email Id or Password is incorrect.');
          }
          break;
        case 'admin/user':
          const errorNumbers = [-2, 547, 50000, 515, 2627, 409];
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('Uq_nc_ix_user_email')) {
              this.utilitiesService.smmodal('User', 'User email already exists.');
            } else if (_obj.message.match('UX_users_qr_code')) {
              this.utilitiesService.smmodal('User', 'QR Code already exists.');
            }
          } else if (_obj.number && errorNumbers.includes(_obj.number)) {
            this.utilitiesService.smmodal('User', 'Something went wrong please try again later.');
          }
          break;

        case 'admin/role':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            this.utilitiesService.smmodal('Role', 'Role already exists.');
          } else {
            this.utilitiesService.smmodal('Role', 'Something went wrong please try again later.');
          }
          break;

        case 'admin/user/role':
          this.configuration.isUnauthorizedState = false;
          if (
            (_obj.number && _obj.number == 2601) ||
            _obj.message.match('Uq_ix_service_provider_role_name_corp_id_venue_id')
          ) {
            this.utilitiesService.smmodal('Role', 'Role already exists.');
          } else {
            this.utilitiesService.smmodal('Role', 'Something went wrong please try again later.');
          }
          break;

        case 'admin/user/role/permission/template':
          this.configuration.isUnauthorizedState = false;
          if ((_obj.number && _obj.number == 2601) || _obj.message.match('Uq_ix_user_role_template_name')) {
            this.utilitiesService.smmodal('User Role Template', 'User role template already exists.');
          } else {
            this.utilitiesService.smmodal('User Role Template', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/venue':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('Uq_nc_ix_agency_id')) {
              this.utilitiesService.smmodal('Venue', 'Agency Id already exists.');
            } else if (_obj.message.match('Uq_nc_ix_iato_code')) {
              this.utilitiesService.smmodal('Venue', 'Iata Code already exists.');
            } else if (_obj.message.match('Uq_nc_ix_icao_code')) {
              this.utilitiesService.smmodal('Venue', 'Icao Code already exists.');
            } else if (_obj.message.match('Uq_nc_ix_venue_survey_id')) {
              this.utilitiesService.smmodal('Venue', 'Venue Servey Id already exists.');
            } else if (_obj.message.match('Uq_nc_ix_venue_corporation_id_venue_name')) {
              this.utilitiesService.smmodal('Venue', 'Venue Name already exists.');
            }
          } else {
            this.utilitiesService.smmodal('Venue', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/building':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('Uq_nc_ix_terminalname_airportid')) {
              this.utilitiesService.smmodal('Building', 'Building already exists.');
            }
          } else if (_obj.number && _obj.number == -2) {
            this.utilitiesService.smmodal('Building', 'Something went wrong please try again later.');
          } else if (_obj.number && _obj.number == 547) {
            this.utilitiesService.smmodal('Building', 'Something went wrong please try again later.');
          } else if (_obj.number && _obj.number == 50000) {
            this.utilitiesService.smmodal('Building', 'Something went wrong please try again later.');
          } else if (_obj.number && _obj.number == 515) {
            this.utilitiesService.smmodal('Building', 'Something went wrong please try again later.');
          } else if (_obj.number && _obj.number == 2627) {
            this.utilitiesService.smmodal('Building', 'Something went wrong please try again later.');
          } else {
            this.utilitiesService.smmodal('Building', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/break/schedule':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            this.utilitiesService.smmodal('Shift Break Schedule', 'Shift Break Schedule already exists.');
          } else {
            this.utilitiesService.smmodal('Shift Break Schedule', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/zone':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            this.utilitiesService.smmodal('Zone', 'Zone already exists.');
          } else if (_obj.number && _obj.number == 409) {
            this.utilitiesService.smmodal('Zone', 'Zone already in use.');
          } else {
            this.utilitiesService.smmodal('Zone', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/shift':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            this.utilitiesService.smmodal('Shift', 'Shift already exists.');
          } else {
            this.utilitiesService.smmodal('Shift', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/venue/location':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('UQ_NC_IX_Location_Building')) {
              this.utilitiesService.smmodal('Location', 'Location already exists.');
            }
          } else {
            this.utilitiesService.smmodal('Location', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/venue/location/template':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('Uq_nc_ix_location_template_name_location_type_venue_id')) {
              this.utilitiesService.smmodal('Location Template', 'Location template already exists.');
            }
          } else {
            this.utilitiesService.smmodal('Location Template', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/beacon/beacon':
        case 'workorder/beacon':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            this.utilitiesService.smmodal('Beacon', 'Beacon already exists.');
          } else {
            this.utilitiesService.smmodal('Beacon', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/staff/schedule':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            this.utilitiesService.smmodal('Staff Schedule', 'Schedule already exists for this user.');
          } else {
            this.utilitiesService.smmodal('Staff Schedule', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/task':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            this.utilitiesService.smmodal('Task', 'Task already exists.');
          } else {
            this.utilitiesService.smmodal('Task', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/inventory':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            this.utilitiesService.smmodal('Inventory', 'Inventory already exists.');
          } else {
            this.utilitiesService.smmodal('Inventory', 'Something went wrong please try again later.');
          }
          break;

        case 'admin/device/registration':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            this.utilitiesService.smmodal('Device Registration', 'Device already exists.');
          } else {
            this.utilitiesService.smmodal('Device Registration', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/assignee':
          this.configuration.isUnauthorizedState = false;
          this.utilitiesService.smmodal('Cleaning Alert', 'Something went wrong please try again later.');
          break;

        case 'workorder/status':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            this.utilitiesService.smmodal('Cleaning Alert Status', 'Work Order Status already exists.');
          } else {
            this.utilitiesService.smmodal('Cleaning Alert Status', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/rest/request':
        case 'workorder/check/request/status':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
            this.utilitiesService.smmodal('Cleaning Alert', _obj.exception);
          } else {
            this.utilitiesService.smmodal('Cleaning Alert', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/qc/data':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
            this.utilitiesService.smmodal('Quality Check', _obj.exception);
          }
          break;

        case 'inspection/transaction':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
            this.utilitiesService.smmodal('Inspection Log', _obj.exception);
          } else {
            this.utilitiesService.smmodal('Inspection Log', 'Something went wrong please try again later.');
          }
          break;

        case 'admin/accessgroup':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
            this.utilitiesService.smmodal('Access Group', 'Access Group already in use.');
          } else if (_obj.number && (_obj.number == 2601 || _obj.number == 2627)) {
            this.utilitiesService.smmodal('Access Group', 'Access Group already exists.');
          } else {
            this.utilitiesService.smmodal('Access Group', 'Something went wrong please try again later.');
          }
          break;

        case 'admin/subscription':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601 && _obj.message.match('Uq_nc_subscription_type')) {
            this.utilitiesService.smmodal('Subscription', 'Subscription already exists.');
          } else {
            this.utilitiesService.smmodal('Subscription', 'Something went wrong please try again later.');
          }
          break;

        case 'admin/userrole/subscription/accessgroup':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601 && _obj.message.match('UQ_IX_NC_role_subscription_access_group')) {
            this.utilitiesService.smmodal('Subscription Role Access', 'Subscription role access already exists.');
          } else {
            this.utilitiesService.smmodal('Subscription Role Access', 'Something went wrong please try again later.');
          }
          break;

        case 'admin/country':
          this.configuration.isUnauthorizedState = false;
          if (
            _obj.number &&
            _obj.number == 2601 &&
            (_obj.message.match('UQ_IX_NC_country') || _obj.message.match('UQ_IX_NC_country_name'))
          ) {
            this.utilitiesService.smmodal('Country', 'Country already exists.');
          } else {
            this.utilitiesService.smmodal('Country', 'Something went wrong please try again later.');
          }
          break;

        case 'admin/state':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601 && _obj.message.match('UQ_IX_NC_state')) {
            this.utilitiesService.smmodal('State', 'State already exists.');
          } else {
            this.utilitiesService.smmodal('State', 'Something went wrong please try again later.');
          }
          break;

        case 'admin/city':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601 && _obj.message.match('UQ_IX_NC_city_city')) {
          } else {
            this.utilitiesService.smmodal('City', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/level':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601 && _obj.message.match('UQ_IX_NC_level_level')) {
            this.utilitiesService.smmodal('Level', 'Level already exists.');
          } else {
            this.utilitiesService.smmodal('Level', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/level/buildings':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('UQ_IX_NC_Level_name_venue_id')) {
              this.utilitiesService.smmodal('Level Assignment', 'Level name already exists.');
            } else if (_obj.message.match('UQ_IX_NC_Level_ordinal_venue_id')) {
              this.utilitiesService.smmodal('Level Assignment', 'Level ordinal already exists.');
            }
          } else if (_obj.number && _obj.number == 409) {
            this.utilitiesService.smmodal('Level Assignment', _obj.exception);
          } else {
            this.utilitiesService.smmodal('Level Assignment', 'Something went wrong please try again later.');
          }
          break;

        case 'asset/product/model':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601 && _obj.message.match('UQ_IX_NC_ar_product_model')) {
            this.utilitiesService.smmodal('Product Model', 'Product model already exists.');
          } else {
            this.utilitiesService.smmodal('Product Model', 'Something went wrong please try again later.');
          }
          break;

        case 'asset/product/manufacturer':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601 && _obj.message.match('UQ_IX_NC_ar_product_manufacturer')) {
            this.utilitiesService.smmodal('Product Manufacturer', 'Product manufacturer already exists.');
          } else {
            this.utilitiesService.smmodal('Product Manufacturer', 'Something went wrong please try again later.');
          }
          break;

        case 'asset/asset/assettype':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601 && _obj.message.match('UQ_IX_NC_ar_asset_type')) {
            this.utilitiesService.smmodal('Asset Type', 'Asset type already exists.');
          } else {
            this.utilitiesService.smmodal('Asset Type', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/pin/category':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601 && _obj.message.match('UQ_IX_NC_workorder_category_venue_id')) {
            this.utilitiesService.smmodal('Work Order Category', 'Work order category already exists.');
          } else {
            this.utilitiesService.smmodal('Work Order Category', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/workordertype':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601 && _obj.message.match('UQ_IX_NC_workorder_type_venue_id')) {
            this.utilitiesService.smmodal('Work Order Category', 'Work order category already exists.');
          } else {
            this.utilitiesService.smmodal('Work Order Category', 'Something went wrong please try again later.');
          }
          break;

        case 'asset/asset':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601 && _obj.message.match('Uq_nc_ix_asset')) {
            this.utilitiesService.smmodal('Asset', 'QR code already exists.');
          } else {
            this.utilitiesService.smmodal('Asset', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/workorder':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
            this.utilitiesService.smmodal('Cleaning Alert', _obj.exception);
          }
          break;

        case 'workorder/maintenance/workorder':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
            this.utilitiesService.smmodal('Work Order', 'Work order has been already QC approved.');
          } else {
            this.utilitiesService.smmodal('Work Order', 'Something went wrong please try again later.');
          }
          break;

        case 'corporation/corporation':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('UQ_IX_NC_corporation_name')) {
              this.utilitiesService.smmodal('Corporation', 'Corporation name already exists.');
            } else if (_obj.message.match('Uq_nc_ix_venue_corporation_id_venue_name')) {
              this.utilitiesService.smmodal('Corporation', 'Venue name already exists.');
            } else if (_obj.message.match('Uq_nc_ix_sales_force_number')) {
              this.utilitiesService.smmodal('Corporation', 'Salesforce number already exists.');
            }
          } else {
            this.utilitiesService.smmodal('Corporation', 'Something went wrong please try again later.');
          }
          break;

        case 'corporation/alert/template':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601 && _obj.message.match('Uq_nc_ix_alert_template')) {
            this.utilitiesService.smmodal('Alert Template', 'Alert Template already exists.');
          } else {
            this.utilitiesService.smmodal('Alert Template', 'Something went wrong please try again later.');
          }
          break;

        case 'corporation/sensor':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 50000) {
            this.utilitiesService.smmodal('Sensor', _obj.message);
          } else if (_obj.number && _obj.number == 409) {
            this.utilitiesService.mdmodal('Error', _obj.exception);
          } else {
            this.utilitiesService.smmodal('Sensor', 'Something went wrong please try again later.');
          }
          break;

        case 'corporation/sensor/type':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601 && _obj.message.match('UQ_IX_NC_sensor_type')) {
            this.utilitiesService.smmodal('Sensor Type', 'Sensor Type already exists.');
          } else {
            this.utilitiesService.smmodal('Sensor Type', 'Something went wrong please try again later.');
          }
          break;

        case 'corporation/alert/contact':
          this.configuration.isUnauthorizedState = false;
          if (
            _obj.number &&
            _obj.number == 2601 &&
            _obj.message.match('Uq_nc_ix_alert_contact_list_venue_id_user_id')
          ) {
            this.utilitiesService.smmodal('Alert Contact List', 'Alert Contact already exists.');
          } else {
            this.utilitiesService.smmodal('Alert Contact List', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/survey/configuration':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('idx_venue_survey_id')) {
              this.utilitiesService.mdmodal('Survey Question Configuration', 'Venue survey id already exists.');
            }
            if (_obj.message.match('idx_survey_id_and_question_id')) {
              this.utilitiesService.mdmodal(
                'Survey Question Configuration',
                'Venue survey question id already exists.',
              );
            }
            if (_obj.message.match('uq_nc_ix_venue_survey_name')) {
              this.utilitiesService.mdmodal('Survey Question Configuration', 'Venue survey name already exists.');
            }
          } else {
            this.utilitiesService.mdmodal(
              'Survey Question Configuration',
              'Something went wrong please try again later.',
            );
          }
          break;

        case 'venue/survey/question/response':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('idx_question_id_and_response_id')) {
              this.utilitiesService.mdmodal('Survey Question Configuration', 'Response id already exists.');
            }
          } else {
            this.utilitiesService.mdmodal(
              'Survey Question Configuration',
              'Something went wrong please try again later.',
            );
          }
          break;

        case 'venue/survey/question/response':
          this.configuration.isUnauthorizedState = false;
          if (
            _obj.number &&
            _obj.number == 2601 &&
            _obj.message.match('idx_question_id_and_response_id_venue_survey_id_venue_id')
          ) {
            this.utilitiesService.mdmodal('Survey Question Configuration', 'Response id already exists.');
          } else {
            this.utilitiesService.mdmodal(
              'Survey Question Configuration',
              'Something went wrong please try again later.',
            );
          }
          break;

        case 'workorder/shift/break/schedule':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
            this.utilitiesService.smmodal('Shift Break Schedule', 'Shift Break Schedule already exists.');
          } else {
            this.utilitiesService.smmodal('Shift Break Schedule', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/pin/category/template':
          this.configuration.isUnauthorizedState = false;
          if (
            _obj.number &&
            _obj.number == 2601 &&
            _obj.message.match('Uq_nc_ix_workorder_category_id_template_title')
          ) {
            this.utilitiesService.smmodal('Closure Template', 'Closure Template already exists with same title');
          } else {
            this.utilitiesService.smmodal('Closure Template', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/pin/category/update/template':
          this.configuration.isUnauthorizedState = false;
          if (
            _obj.number &&
            _obj.number == 2601 &&
            _obj.message.match('Uq_nc_ix_workorder_category_id_template_title')
          ) {
            this.utilitiesService.smmodal('Closure Template', 'Closure Template already exists with same title');
          } else {
            this.utilitiesService.smmodal('Closure Template', 'Something went wrong please try again later.');
          }
          break;

        case 'serviceprovider/serviceprovider':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('Uq_nc_ix_service_provider_name')) {
              this.utilitiesService.smmodal('Service Provider', 'Service Provider already exists.');
            }
          } else {
            this.utilitiesService.smmodal('Service Provider', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/gate':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('Uq_nc_ix_gates_master_gate_name_venue_id')) {
              this.utilitiesService.smmodal('Gate', 'Gate already exists.');
            }
          } else {
            this.utilitiesService.smmodal('Gate', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/location/gates/group':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('Uq_nc_ix_location_gates_group_group_name_venue_id')) {
              this.utilitiesService.smmodal('Location Gate Group', 'Location Gate Group already exists.');
            }
          } else if (_obj.number !== 409) {
            this.utilitiesService.smmodal('Location Gate Group', 'Something went wrong please try again later.');
          }
          break;

        case 'inspection/inspection/element':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
            this.utilitiesService.smmodal('Inspection', _obj.exception);
          } else {
            this.utilitiesService.smmodal('Inspection', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/element':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('Uq_nc_ix_element_element_name')) {
              this.utilitiesService.smmodal('Element', 'Element already exists.');
            }
          } else {
            this.utilitiesService.smmodal('Element', 'Something went wrong please try again later.');
          }
          break;

        case 'schedule/delete/schedule':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
            this.utilitiesService.mdmodal(
              'Calendar',
              'This schedule is either started or completed. It cannot be deleted.',
            );
          } else {
            this.utilitiesService.smmodal('Calendar', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/venueelement':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('UQ_IX_NC_venue_element')) {
              this.utilitiesService.smmodal('Venue Element', 'Venue element already exists');
            }
          } else {
            this.utilitiesService.smmodal('Venue Element', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/inventory':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('UQ_IX_NC_venue_inventory')) {
              this.utilitiesService.smmodal('Venue Inventory', 'Venue inventory already exists');
            }
          } else {
            this.utilitiesService.smmodal('Venue Inventory', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/task':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('UQ_IX_NC_venue_task')) {
              this.utilitiesService.smmodal('Venue Task', 'Venue task already exists');
            }
          } else {
            this.utilitiesService.smmodal('Venue Task', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/venue/location/type':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('UQ_IX_NC_venue_element')) {
              this.utilitiesService.smmodal('Location Type', 'Location type element already exists');
            }
            if (_obj.message.match('UQ_IX_NC_venue_location_type_id_venue_element_id')) {
              this.utilitiesService.smmodal('Location Type', 'Location type element already exists');
            }

            if (_obj.message.match('UQ_IX_NC_venue_inventory')) {
              this.utilitiesService.smmodal('Location Type', 'Location type inventory already exists');
            }
            if (_obj.message.match('UQ_IX_NC_venue_location_type_id_venue_inventory_id')) {
              this.utilitiesService.smmodal('Location Type', 'Location type inventory already exists');
            }

            if (_obj.message.match('UQ_IX_NC_venue_task')) {
              this.utilitiesService.smmodal('Location Type', 'Location type task already exists');
            }
            if (_obj.message.match('UQ_IX_NC_venue_location_type_id_venue_task_id')) {
              this.utilitiesService.smmodal('Location Type', 'Location type task already exists');
            }

            if (_obj.message.match('Uq_nc_ix_venuelocationtypename_venueid')) {
              this.utilitiesService.smmodal('Location Type', 'Location type already exists');
            }

            if (_obj.message.match('uq_nc_ix_area_name')) {
              this.utilitiesService.smmodal('Location Type', 'Location type area already exists');
            }
          } else {
            this.utilitiesService.smmodal('Location Type', 'Something went wrong please try again later.');
          }
          break;

        case 'serviceprovider/salesforce/license/request':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
          }
          break;

        case 'serviceprovider/user/ownership/transfer':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
            this.utilitiesService.smmodal('Transfer Ownership', _obj.exception);
          } else {
            this.utilitiesService.smmodal('Transfer Ownership', 'Something went wrong please try again later.');
          }
          break;

        case 'badge/badge':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('UQ_inx_badge_number')) {
              this.utilitiesService.smmodal('Badge', 'Badge already exists');
            }
          } else {
            this.utilitiesService.smmodal('Badge', 'Something went wrong please try again later.');
          }
          break;

        case 'badge/gateway':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('UQ_inx_gateway_identifier_venue_id')) {
              this.utilitiesService.smmodal('Gateway', 'Gateway already exists');
            }
          } else {
            this.utilitiesService.smmodal('Gateway', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/qc/transaction':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
            this.utilitiesService.smmodal('Quality Check', _obj.exception);
          } else {
            this.utilitiesService.smmodal('Quality Check', 'Something went wrong please try again later.');
          }
          break;

        case 'workorder/qc/details':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
            this.utilitiesService.smmodal('Quality Check', _obj.exception);
          } else {
            this.utilitiesService.smmodal('Quality Check', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/area':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2601) {
            if (_obj.message.match('uq_nc_ix_area_name')) {
              this.utilitiesService.smmodal('Area', 'Area already exists');
            }
          } else {
            this.utilitiesService.smmodal('Area', 'Something went wrong please try again later.');
          }
          break;

        case 'venue/import/location/types':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
          }
          break;

        case 'venue/import/location/template':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
          }
          break;

        case 'schedule/event/record':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 409) {
            this.utilitiesService.smmodal('Project Work Log', _obj.exception);
          } else {
            this.utilitiesService.smmodal('Project Work Log', 'Something went wrong please try again later.');
          }
          break;

        case 'inspection/score/factor/config':
          this.configuration.isUnauthorizedState = false;
          if (_obj.number && _obj.number == 2627) {
            this.utilitiesService.smmodal('Inspection Score Factor', 'Score factor name should not be duplicate.');
          } else if (_obj.number && _obj.number == 409) {
            this.utilitiesService.smmodal('Inspection Score Factor', _obj.exception);
          } else {
            this.utilitiesService.smmodal('Inspection Score Factor', 'Something went wrong please try again later.');
          }
          break;

        default:
          if (error.status == 401) {
            if (!this.configuration.isUnauthorizedState) {
              this.configuration.Token = '';
              this.configuration.userVenueId = 0;
              this.clearLocalStorage();

              this.utilitiesService.smmodal('Sign In', 'Your session has been expired.');
              this.configuration.isUnauthorizedState = true;
              this.router.navigate(['login']);
            }
          } else {
            this.configuration.isUnauthorizedState = false;
            this.utilitiesService.smmodal('', 'Something went wrong please try again later.');
          }
          break;
      }
    } else if (!window.navigator.onLine) {
      this.utilitiesService.smmodal('TRAX', 'No internet connection. Connect to the internet and try again.');
    }
    this.loaderService.display(false);
    return Observable.throw(error);
  };

  clearLocalStorage() {
    this.localSt.clear('traxtoken');
    this.localSt.clear('traxuservenueid');
    this.localSt.clear('traxcurrentuser');
    this.localSt.clear('traxuserzoneid');

    this.localSt.clear('traxcontextserviceproviderid');
    this.localSt.clear('serviceproviderkey');

    this.localSt.clear('traxContextCorporationId');
    this.localSt.clear('corporationkey');

    this.localSt.clear('traxContextVenueId');
    this.localSt.clear('tempvenueid');
    this.localSt.clear('venuekey');

    this.localSt.clear('LocationUserAssignment');
    this.localSt.clear('ShiftStaffSchedule');
    this.localSt.clear('EitherOneOrThese');

    this.localSt.clear('EnableTouchlessFeedback');
    this.localSt.clear('EnableTouchlessFeedbackReason');

    this.localSt.clear('LogoData');
    this.localSt.clear('InspectionDate');
    this.localSt.clear('isAllowToShareUser');
    this.localSt.clear('subscriptiontype');
    this.localSt.clear('StateMaintain');
    this.localSt.clear('venueFeaturesConfig');
    this.localSt.clear('spLicenseExpiryDate');
    this.localSt.clear('ipaddress');
    this.localSt.clear('isBadgeEnable');
    this.localSt.clear('signalr');
  }
}
