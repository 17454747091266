import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthState } from 'src/app/core/services/auth.service';
import { FilteredLogsListState } from 'src/app/features/workordermanagement/workorder/models/cleaning-alert-log.model';
import { UtilitiesService } from '../utilityservices/utilityservice';

@Component({
  selector: 'app-log-filter',
  templateUrl: 'log-filter.component.html',
  styleUrls: ['log-filter.component.scss'],
})
export class LogFilterComponent implements OnInit {
  @Input() pageName: string = '';
  @Input() disable: boolean = false;
  @Input() statuses: any[] = [];
  @Output() filterChanged = new EventEmitter<{ statusListId: number; historyDays: number }>();

  public pageState: FilteredLogsListState = {
    pageName: this.pageName,
    page: undefined,
    size: undefined,
    statusListId: -1,
    historyDays: 1,
  };

  private page: number = 1;
  logHistoryDays = this.utilitiesService.logHistoryDays;

  constructor(
    public authState: AuthState,
    private utilitiesService: UtilitiesService,
  ) {}

  ngOnInit(): void {
    this.pageState.pageName = this.pageName;
    // Retrieve saved state if available
    const savedState = this.authState.retrievePageState(this.pageState);
    if (savedState) {
      this.pageState = savedState;
    }
  }

  public logHistoryDaysSelected($event: any) {
    this.selectedHistoryDays = $event ?? 1;
    this.pageState.historyDays = this.selectedHistoryDays;
    this.page = 1;

    this.authState.storePageState(this.pageState);
    this.emitFilterChange();
  }

  public statusSelected($event: any) {
    this.selectedStatusType = $event ?? 0;
    this.pageState.statusListId = this.selectedStatusType;
    this.page = 1;

    this.authState.storePageState(this.pageState);
    this.emitFilterChange();
  }

  private emitFilterChange() {
    this.filterChanged.emit({
      statusListId: this.selectedStatusType,
      historyDays: this.selectedHistoryDays,
    });
  }

  get selectedHistoryDays(): number {
    return this.customFiltersDisabled ? -1 : (this.pageState?.historyDays ?? 1);
  }
  set selectedHistoryDays(val: number) {
    this.pageState.historyDays = val;
  }

  get selectedStatusType(): number {
    return this.customFiltersDisabled ? -1 : (this.pageState?.statusListId ?? -1);
  }
  set selectedStatusType(val: number) {
    this.pageState.statusListId = val;
  }

  get customFiltersDisabled(): boolean {
    return !!this.disable;
  }
}
