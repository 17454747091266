import { environment as higherEnvironment } from './environment.flagship.sandbox';
export const environment = {
  ...higherEnvironment,

  server: 'https://api.qa.traxinsights.app/',
  touchlessFeedbackAppBaseURL: 'https://touchless.qa.flagship.traxinsights.app/#/',
  cleaningAlertRequestURL: 'https://ca.qa.flagship.traxinsights.app/#/',
  projectTitle: 'Flagship Intuition',
  faviconUrl: 'assets/flagship/flagship_fav_icon.ico',
  authServer: 'https://qa-app-internalapi-trax-eastus-01.azurewebsites.net/',
};
