import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
//
import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from 'src/app/shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';
// import { InspectionDetailModule } from '../../inspectionmanagement/inspection/inspection.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { BeaconModule } from '../../staffscheduling/beacon/beacon.module';
import { ElementModule } from '../../staffscheduling/element/element.module';
import { LocationElementModule, LocationModule } from '../../staffscheduling/location/location.module';
import { User } from '../../usermanagement/user/user.model';
import { InspectionScoreFactor, VenueModule } from '../../venuemanagement/venue/venue.module';
import { InspectionComponent } from './components/inspectionlog.component';
import { InspectionRoutes } from './inspectionlog.routes';

@NgModule({
  imports: [
    CommonModule,
    InspectionRoutes,
    FormsModule,
    //
    NgxPaginationModule,
    HomeModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    SharedModule,
  ],

  declarations: [InspectionComponent],
})
export class InspectionModule extends GenericEntity {
  public InspectionId: number;
  public InspectionCPId: string;
  public InspectionTitle: string;
  public InspectionDescription: string;
  public Location: LocationModule;
  public Venue: VenueModule;
  public User: User;
  public InspectionElements: InspectionElement[];
  public AvgLocationRating: string;
  public Element: ElementModule;
  public ElementList: ElementModule[];
  public FollowUpCaNo: number;
  public FollowUpRequired: number;
  public InspectionComment: string;
  public MapID: string;
  public InspectionStatus: InspectionStatus;
  public OverallComment: string;
  public OverallRating: string;
  public InspectionBeacons: InspectionBeacon[];
  public InspectionScoreFactor: InspectionScoreFactor;
  public TotalWeightageScore: any;
}

export class InspectionElement extends GenericEntity {
  public InspectionElementId: number;
  public InspectionId: number;
  public Element: ElementModule;
  public LocationElements: LocationElementModule[];
  public InspectElementAttachments: InspectElementAttachment[];
}

export class InspectElementAttachment extends GenericEntity {
  public InspectElementAttachmentId: number;
  public InspectionId: number;
  public ElementId: number;
  public Comment: string;
  public AttachmentName: string;
  public AttachmentType: string;
}

export class InspectionStatus extends GenericEntity {
  public InspectionStatusId: number;
  public InsStatus: string;
  public InspectionStatusSequence: number;
}

export class InspectionBeacon extends GenericEntity {
  public InspectionBeaconId: number;
  // public Inspection: InspectionDetailModule;
  public Beacon: BeaconModule;
  public OptIn: any;
  public OptOut: any;
  public BatteryLevel: number;
  public BatteryVoltage: number;
  public timeElapsed: string;
}
