<div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading">
  <ngx-atlas-select
    (model)="statusSelected($event)"
    [list]="statuses"
    [idField]="'id'"
    [textField]="'name'"
    [placeholder]="'Select status'"
    [allowClear]="true"
    [multiple]="false"
    [required]="true"
    [selectedValue]="selectedStatusType"
    [disabled]="customFiltersDisabled"
  >
  </ngx-atlas-select>
</div>
<div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading">
  <ngx-atlas-select
    (model)="logHistoryDaysSelected($event)"
    [list]="logHistoryDays"
    [idField]="'id'"
    [textField]="'name'"
    [placeholder]="'Select status'"
    [allowClear]="true"
    [multiple]="false"
    [required]="true"
    [selectedValue]="selectedHistoryDays"
    [disabled]="customFiltersDisabled"
  >
  </ngx-atlas-select>
</div>
