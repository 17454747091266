<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 form-heading">
        <i class="fa fa-user heading-icon"></i>
        <span class="list-heading" *ngIf="!createbox!; isProfileForm">USERS</span>
        <span class="list-heading" *ngIf="createbox && !isProfileForm && !isUpdateForm">ADD USER</span>
        <span class="list-heading" *ngIf="createbox && !isProfileForm && isUpdateForm">EDIT USER</span>
        <span class="list-heading" *ngIf="isProfileForm">MY PROFILE</span>
      </div>

      <div class="col-lg-9 col-md-12" *ngIf="!createbox!; isProfileForm">
        <div id="page-size" class="col-lg-1 col-sm-3 col-md-2 col-xs-12 pull-right list-heading">
          <ngx-atlas-select (model)="UserPageSizeChange($event)" [list]="pageSizes" [idField]="'id'"
            [textField]="'name'" [placeholder]="'Select Size'" [allowClear]="true" [multiple]="false" [required]="true"
            [selectedValue]="size">
          </ngx-atlas-select>
        </div>
        <div class="col-lg-1 col-md-2 col-sm-3 col-xs-12 input-group pull-right add-section qrCards"
          *ngIf="'USER_PRINT_QR_CARDS' | canAccess">
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="openUserQRCardPDF()">
            Print QR Cards
          </button>
        </div>

        <div class="col-lg-1 col-md-2 col-sm-2 col-xs-12 input-group pull-right add-section"
          *ngIf="!createbox && ('USER_ADD' | canAccess)">
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add User
          </button>
        </div>

        <div class="col-lg-2 col-md-3 col-sm-3 col-xs-12 pull-right list-heading">
          <ngx-atlas-select (model)="listviewtype($event)" [list]="listviewtypes" [idField]="'id'" [textField]="'name'"
            [placeholder]="'Select List'" [allowClear]="true" [multiple]="false" [required]="true"
            [selectedValue]="viewType">
          </ngx-atlas-select>
        </div>

        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search">
          <form>
            <input type="text" name="searchString" class="form-control search-radius searchBoxRadius"
              placeholder="Search" [(ngModel)]="searchString" autocomplete="off" />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="userSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content" [class.vendor-content]="isVendorForm">
  <div class="row" *ngIf="!createbox!; isProfileForm">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData() && Isdataloaded" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive">
          <table class="table table-hover table-responsive" *ngIf="hasData()">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('FirstName', columnSortOrder)">
                  User
                  <span *ngIf="selectedColumnName == 'FirstName' && columnSortOrder == 'DESC'"><i
                      class="fa fa-sort-up"></i></span><span
                    *ngIf="selectedColumnName == 'FirstName' && columnSortOrder == 'ASC'"><i
                      class="fa fa-sort-down"></i></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('RoleName', columnSortOrder)">
                  User Role
                  <span *ngIf="selectedColumnName == 'RoleName' && columnSortOrder == 'DESC'"><i
                      class="fa fa-sort-up"></i></span><span
                    *ngIf="selectedColumnName == 'RoleName' && columnSortOrder == 'ASC'"><i
                      class="fa fa-sort-down"></i></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('UserType', columnSortOrder)">
                  User Type
                  <span *ngIf="selectedColumnName == 'UserType' && columnSortOrder == 'DESC'"><i
                      class="fa fa-sort-up"></i></span><span
                    *ngIf="selectedColumnName == 'UserType' && columnSortOrder == 'ASC'"><i
                      class="fa fa-sort-down"></i></span>
                </th>
                <th>Service Provider</th>
                <th>Corporation</th>
                <th>Venue</th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>

            <tbody
              *ngFor="let userinfo of users | paginate: { itemsPerPage: size, currentPage: page, totalItems: count }">
              <tr (click)="getUserById(userinfo.UserId)">
                <td>{{ userinfo.FirstName }} {{ userinfo.LastName }}</td>
                <td>{{ userinfo.UserRole.RoleName }}</td>
                <td>{{ userinfo.UserType.UserType }}</td>
                <td>
                  <span>{{ userinfo.ServiceProvider.ServiceProviderName }}</span>
                </td>
                <td>
                  <span>{{ userinfo.Corporation.CorporationName }}</span>
                  <span *ngIf="
                      userinfo.Corporation != null &&
                      userinfo.Corporation.Count != null &&
                      userinfo.Corporation.Count > 0
                    ">
                    (+{{ userinfo.Corporation.Count }})</span>
                </td>
                <td>
                  <span *ngIf="userinfo.RelUserVenues != null && userinfo.RelUserVenues.length > 0">{{
                    userinfo.RelUserVenues[0].Venue.VenueName
                    }}</span>
                  <span *ngIf="
                      userinfo.RelUserVenues != null &&
                      userinfo.RelUserVenues[0].Venue.Count != null &&
                      userinfo.RelUserVenues[0].Venue.Count > 0
                    ">
                    (+{{ userinfo.RelUserVenues[0].Venue.Count }})</span>
                  <span *ngIf="userinfo.RelUserVenues < 0"></span>
                </td>
                <td *ngIf="userinfo.IsActive">
                  <span><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top"
                      title="Active"></i></span>
                </td>
                <td *ngIf="!userinfo.IsActive">
                  <span><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top"
                      title="Inactive"></i></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls class="pagination pull-right" (pageChange)="pageChanged($event)"
          *ngIf="hasData() && Isdataloaded && count > 10"></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="createbox && (isUserForm || isProfileForm)">
    <form #f="ngForm" name="createuser" role="form" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-3 col-md-3 col-sm-3">
          <div>
            <div class="form-group">
              <div class="imgBox">
                <button class="btn-primary btn btn-xs button-text" *ngIf="profileImgSrc" id="templateReset"
                  type="button" (click)="openRemoveProfileModal()">
                  <i class="fa fa-times"></i> Remove
                </button>
                <label class="custom-file">
                  <input type="file" #fileInput id="file" name="Userimage" class="custom-file-input"
                    (change)="fileChange($event)" style="display: none" accept=".jpg, .jpeg, .png" />
                  <span class="custom-file-control"></span>
                  <img id="preview" [src]="profileImgSrc != null ? profileImgSrc : profileImg" class="user-profile" />
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-9 col-md-9 col-sm-9">
          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="firstname"><span id="red-circle">&#x25CF;</span> First Name</label>
              <div>
                <input id="Firstname" type="text" autocomplete="invalid" class="form-control" name="Firstname"
                  placeholder="First Name" [(ngModel)]="user.FirstName" required #FirstName="ngModel" maxlength="49"
                  [disabled]="formFieldDisabled" [pattern]="utilitiesService.alphanumericRegex" />
                <div *ngIf="FirstName.errors && (FirstName.dirty || FirstName.touched)" class="alert alert-danger">
                  <div [hidden]="!FirstName.errors.required" class="text-danger">First name is required.</div>
                  <div [hidden]="!FirstName.errors.pattern" class="text-danger">
                    {{ utilitiesService.alphanumericRegexMessage }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="firstname"><span id="red-circle">&#x25CF;</span> First Name</label>
              <div>
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">{{ user.FirstName }}</p>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="lastname"><span id="red-circle">&#x25CF;</span> Last Name</label>
              <div>
                <input type="text" autocomplete="invalid" class="form-control" name="Lastname" placeholder="Last Name"
                  [(ngModel)]="user.LastName" required #LastName="ngModel" maxlength="49" [disabled]="formFieldDisabled"
                  [pattern]="utilitiesService.alphanumericRegex" />
                <div *ngIf="LastName.errors && (LastName.dirty || LastName.touched)" class="alert alert-danger">
                  <div [hidden]="!LastName.errors.required" class="text-danger">Last name is required.</div>
                  <div [hidden]="!LastName.errors.pattern" class="text-danger">
                    {{ utilitiesService.alphanumericRegexMessage }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="lastname"><span id="red-circle">&#x25CF;</span> Last Name</label>
              <div>
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">{{ user.LastName }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="email"><span id="red-circle">&#x25CF;</span> Email</label>
              <div>
                <input type="email" autocomplete="invalid" class="form-control" name="Email" placeholder="Email"
                  [(ngModel)]="user.Email" required #Email="ngModel"
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" maxlength="319" (keyup)="checkEmail()"
                  [disabled]="formFieldDisabled" />
                <div *ngIf="Email.errors && (Email.dirty || Email.touched)" class="alert alert-danger">
                  <div [hidden]="!Email.errors.required" class="text-danger">Email is required.</div>
                  <div [hidden]="!Email.errors.pattern" class="text-danger">Email is invalid</div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="email"><span id="red-circle">&#x25CF;</span> Email</label>
              <div>
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">{{ user.Email }}</p>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="checkpassword"><span id="red-circle" *ngIf="!isHostNameMatched">&#x25CF;</span>
                Password</label>
              <div>
                <div class="input-group">
                  <input [type]="showHidePassword ? 'text' : 'password'" autocomplete="new-password"
                    name="Checkpassword" placeholder="Password" [(ngModel)]="user.Password" class="form-control"
                    validateEqual="ConfirmPassword" reverse="true"
                    pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" minlength="4"
                    maxlength="49" #Password="ngModel" [required]="!isHostNameMatched" [disabled]="isHostNameMatched"
                    style="border-radius: 5px 0px 0px 5px" />
                  <span class="input-group-addon" (click)="showHide('Password')"><i
                      [ngClass]="showHidePassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i></span>
                </div>

                <div *ngIf="Password.errors && (Password.dirty || Password.touched)" class="alert alert-danger">
                  <div [hidden]="!Password.errors.required" class="text-danger">Password is required.</div>
                  <div [hidden]="!Password.errors.pattern" class="text-danger">
                    Passwords must be at least 8 characters long and contains UPPERCASE, lowercase, numbers/special
                    characters.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="checkpassword"><span id="red-circle" *ngIf="!isHostNameMatched">&#x25CF;</span>
                Password</label>
              <div>
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">{{ user.Password }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="userRoleName"><span id="red-circle">&#x25CF;</span> User Role</label>
              <div>
                <ngx-atlas-select (model)="changeRole($event)" [list]="userroles" [idField]="'UserRoleId'"
                  [textField]="'RoleName'" [placeholder]="'Select User Role'" [allowClear]="true" [multiple]="false"
                  [required]="true" [disabled]="formFieldDisabled" [selectedValue]="user.UserRole.UserRoleId">
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="userRoleName"><span id="red-circle">&#x25CF;</span> User Role</label>
              <div>
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">{{ user.UserRole.RoleName }}</p>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="serviceProvider"><span id="red-circle" *ngIf="isServiceProviderReq">&#x25CF;</span> Service
                Provider</label>
              <div>
                <ngx-atlas-select (model)="changeServiceProvider($event)" [list]="serviceProviders"
                  [idField]="'ServiceProviderId'" [textField]="'ServiceProviderName'"
                  [placeholder]="'Select Service Provider'" [allowClear]="true" [multiple]="false"
                  [disabled]="isSysAdminRoleSelected" [required]="isServiceProviderReq"
                  [selectedValue]="user.ServiceProvider.ServiceProviderId">
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="serviceProvider"><span id="red-circle" *ngIf="isServiceProviderReq">&#x25CF;</span> Service
                Provider</label>
              <div>
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">{{ user.ServiceProvider.ServiceProviderName }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="corporationName"> Corporation</label>
              <div>
                <ng-multiselect-dropdown [settings]="corporationDropdownSettings" [placeholder]="'Select Corporation'"
                  [data]="corporations" [(ngModel)]="corporationIds" id="corporation-multiselect" name="corporation"
                  [disabled]="isSysAdminRoleSelected" (onSelect)="changeMultiselectCorporation($event)"
                  (onSelectAll)="onSelectAllCorporations($event)" (onDeSelect)="onDeSelectCorporation($event)"
                  (onDeSelectAll)="onDeSelectAllCorporations($event)">
                </ng-multiselect-dropdown>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="corporationName"> Corporation</label>
              <div>
                <div class="clipping-wrapper" style="max-height: 54px; overflow-y: auto">
                  <p class="readOnly clipping">{{ ProfileCorporation }}</p>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="venueName"> Venue</label>
              <ng-multiselect-dropdown [settings]="venueDropdownSettings" [placeholder]="'Select Venue'" [data]="venues"
                [(ngModel)]="venueIds" id="venue-multiselect" name="venue" [disabled]="isSysAdminRoleSelected"
                (onSelect)="changeMultiselectVenue($event)" (onSelectAll)="onSelectAllVenues($event)"
                (onDeSelect)="onDeSelectVenue($event)" (onDeSelectAll)="onDeSelectAllVenues($event)">
              </ng-multiselect-dropdown>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="venueName"> Venue</label>
              <div>
                <div class="clipping-wrapper" style="max-height: 54px; overflow-y: auto">
                  <p class="readOnly clipping">{{ ProfileVenue }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="mobileNumber"><span id="red-circle">&#x25CF;</span> Mobile Number</label>
              <div>
                <input type="text" autocomplete="invalid" class="form-control" name="contact"
                  placeholder="Mobile No. (123) 456-7890" [(ngModel)]="user.Contact" required #contactNumber="ngModel"
                  minlength="10" mask="(000) 000-0000" [disabled]="formFieldDisabled" />
                <div *ngIf="contactNumber.errors && (contactNumber.dirty || contactNumber.touched)"
                  class="alert alert-danger">
                  <div [hidden]="!contactNumber.errors.required" class="text-danger">Mobile number is required.</div>
                  <div [hidden]="!contactNumber.errors.minlength" class="text-danger">
                    Mobile number should be 10 digit.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="mobileNumber"><span id="red-circle">&#x25CF;</span> Mobile Number</label>
              <div>
                <div class="clipping-wrapper" style="max-height: 54px; overflow-y: auto">
                  <p class="readOnly clipping">{{ user.Contact }}</p>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="addressLine1"><span id="red-circle">&#x25CF;</span> Address Line 1</label>
              <div>
                <input type="text" autocomplete="invalid" class="form-control" name="AddressLine1"
                  placeholder="Address Line 1" [(ngModel)]="user.AddressLineOne" required #AddressLineOne="ngModel"
                  maxlength="249" [disabled]="formFieldDisabled" />
                <div *ngIf="AddressLineOne.errors && (AddressLineOne.dirty || AddressLineOne.touched)"
                  class="alert alert-danger">
                  <div [hidden]="!AddressLineOne.errors.required" class="text-danger">Address line 1 is required.</div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="addressLine1"><span id="red-circle">&#x25CF;</span> Address Line 1</label>
              <div>
                <div class="clipping-wrapper" style="max-height: 54px; overflow-y: auto">
                  <p class="readOnly clipping">{{ user.AddressLineOne }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="addressLine2">Address Line 2</label>
              <div>
                <input type="text" class="form-control" name="AddressLine2" placeholder="Address Line 2"
                  [(ngModel)]="user.AddressLineTwo" #addressLineTwo="ngModel" maxlength="249"
                  [disabled]="formFieldDisabled" />
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="addressLine2"> Address Line 2</label>
              <div>
                <div class="clipping-wrapper" style="max-height: 54px; overflow-y: auto">
                  <p class="readOnly clipping">{{ user.AddressLineTwo }}</p>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="countryName">Country</label>
              <div>
                <ngx-atlas-select (model)="countryChange($event)" [list]="countries" [idField]="'CountryId'"
                  [textField]="'CountryName'" [placeholder]="'Select Country'" [allowClear]="true" [multiple]="false"
                  [disabled]="formFieldDisabled" [selectedValue]="user.Country.CountryId">
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="countryName"> Country</label>
              <div>
                <div class="clipping-wrapper" style="max-height: 54px; overflow-y: auto">
                  <p class="readOnly clipping">{{ user.Country.CountryName }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="stateName">State</label>
              <div>
                <ngx-atlas-select (model)="stateChange($event)" [list]="states" [idField]="'StateId'"
                  [textField]="'StateName'" [placeholder]="'Select State'" [allowClear]="true" [multiple]="false"
                  [disabled]="formFieldDisabled" [selectedValue]="user.State.StateId">
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="stateName"> State</label>
              <div>
                <div class="clipping-wrapper" style="max-height: 54px; overflow-y: auto">
                  <p class="readOnly clipping">{{ user.State.StateName }}</p>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="cityName">City</label>
              <div>
                <ngx-atlas-select (model)="user.City.CityId = $event" [list]="cities" [idField]="'CityId'"
                  [textField]="'CityName'" [placeholder]="'Select City'" [allowClear]="true" [multiple]="false"
                  [disabled]="formFieldDisabled" [selectedValue]="user.City.CityId">
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="cityName"> City</label>
              <div>
                <div class="clipping-wrapper" style="max-height: 54px; overflow-y: auto">
                  <p class="readOnly clipping">{{ user.City.CityName }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="zipcode">Zip Code</label>
              <div>
                <input type="text" autocomplete="invalid" class="form-control" name="Zipcode" placeholder="Zip Code"
                  [(ngModel)]="user.Zipcode" #Zipcode="ngModel" minlength="5" maxlength="5" pattern="[0-9]*$"
                  [disabled]="formFieldDisabled" />
                <div *ngIf="Zipcode.errors && (Zipcode.dirty || Zipcode.touched)" class="alert alert-danger">
                  <div [hidden]="!Zipcode.errors.pattern" class="text-danger">Zip code accepts only numeric.</div>
                  <div [hidden]="!Zipcode.errors.minlength || Zipcode.errors.pattern" class="text-danger">
                    Zip code should be 5 digit.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="zipcode"> Zip Code</label>
              <div>
                <div class="clipping-wrapper" style="max-height: 54px; overflow-y: auto">
                  <p class="readOnly clipping">{{ user.Zipcode }}</p>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="QRcode"><span id="red-circle" *ngIf="!isHostNameMatched">&#x25CF;</span> QR code</label>
              <div>
                <div class="input-group">
                  <input [type]="showHideQRCode ? 'text' : 'password'" autocomplete="invalid" class="form-control"
                    name="qrCode" placeholder="QR Code" [(ngModel)]="user.QRCode" #qrCode="ngModel" maxlength="249"
                    [required]="!isHostNameMatched" [disabled]="isHostNameMatched" pattern="^\S+$"
                    style="border-radius: 5px 0px 0px 5px" />
                  <span class="input-group-addon" (click)="showHide('QRCode')"><i
                      [ngClass]="showHideQRCode ? 'fa fa-eye' : 'fa fa-eye-slash'"></i></span>
                </div>

                <div *ngIf="qrCode.errors && (qrCode.dirty || qrCode.touched)" class="alert alert-danger">
                  <div [hidden]="!qrCode.errors.required" class="text-danger">QR Code is required.</div>
                  <div [hidden]="!qrCode.errors.pattern" class="text-danger">QR Code not accepting a single space.</div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="QRcode"> QR code</label>
              <div>
                <div class="clipping-wrapper" style="max-height: 54px; overflow-y: auto">
                  <p class="readOnly clipping">{{ user.QRCode }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="gender"><span id="red-circle">&#x25CF;</span> Gender</label>
              <div>
                <ngx-atlas-select (model)="gender($event)" [list]="genders" [idField]="'id'" [textField]="'name'"
                  [placeholder]="'Select Gender'" [allowClear]="true" [multiple]="false" [required]="true"
                  [disabled]="formFieldDisabled" [selectedValue]="user.Gender">
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="gender"><span id="red-circle">&#x25CF;</span> Gender</label>
              <div>
                <div class="clipping-wrapper" style="max-height: 54px; overflow-y: auto">
                  <p class="readOnly clipping" *ngIf="user.Gender == 'M'">Male</p>
                  <p class="readOnly clipping" *ngIf="user.Gender == 'F'">Female</p>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="defaultVenueName">
              <label for="defaultVenue">Default Venue</label>
              <div>
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">{{ defaultVenueName }}</p>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="userType"><span id="red-circle">&#x25CF;</span> User Type</label>
              <div *ngIf="createbox && !isUpdateForm && !isProfileForm && !isSysAdminRoleSelected">
                <ngx-atlas-select (model)="user.UserType.UserTypeId = $event" [list]="userTypes"
                  [idField]="'UserTypeId'" [textField]="'UserType'" [placeholder]="'Select User Type'"
                  [allowClear]="true" [multiple]="false" [required]="true" [selectedValue]="user.UserType.UserTypeId">
                </ngx-atlas-select>
                <span class="input-note" *ngIf="!isSysAdmin">User type cannot be changed later</span>
              </div>

              <div *ngIf="
                  ((isUpdateForm &&
                    (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess)) &&
                    !isSysAdminRoleSelected) ||
                    (isProfileForm && ('edit-profile' | canAccess) && !isSysAdminRoleSelected)) &&
                  isSysAdmin
                ">
                <ngx-atlas-select (model)="user.UserType.UserTypeId = $event" [list]="userTypes"
                  [idField]="'UserTypeId'" [textField]="'UserType'" [placeholder]="'Select User Type'"
                  [allowClear]="true" [multiple]="false" [required]="true" [selectedValue]="user.UserType.UserTypeId">
                </ngx-atlas-select>
                <span class="input-note" *ngIf="!isSysAdmin">User type cannot be changed later</span>
              </div>

              <div *ngIf="(isUpdateForm || isProfileForm) && !isSysAdmin && !isSysAdminRoleSelected">
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">{{ user.UserType.UserType }}</p>
                </div>
              </div>

              <div *ngIf="isSysAdminRoleSelected">
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">Internal</p>
                </div>
              </div>
            </div>

          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">


            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && (('USER_EDIT' | canAccess) || ('USER_ADD' | canAccess))) ||
                (isProfileForm && ('edit-profile' | canAccess))
              ">
              <label for="badge">Badge Number</label>
              <div>
                <ngx-atlas-select (model)="user.Badge.BadgeId = $event" [list]="badges" [idField]="'BadgeId'"
                  [textField]="'BadgeNumber'" [placeholder]="'Select Badge Number'" [allowClear]="true"
                  [multiple]="false" [disabled]="formFieldDisabled" [selectedValue]="user.Badge.BadgeId">
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="
                (isUserForm && !('USER_EDIT' | canAccess) && !('USER_ADD' | canAccess)) ||
                (isProfileForm && !('edit-profile' | canAccess))
              ">
              <label for="badge">Badge Number</label>
              <div>
                <div class="clipping-wrapper" style="max-height: 54px; overflow-y: auto">
                  <p class="readOnly clipping">{{ user.Badge.BadgeNumber }}</p>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="AddedByName">Entered By</label>
              <div>
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">{{ AddedByName }}</p>
                </div>
              </div>
            </div>

          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section" *ngIf="isUpdateForm">

            <div class="col-lg-6 col-md-6 col-sm-6" style="padding-top: 15px;">
              <div class="form-group">
                <div>
                  <div>
                    <mat-checkbox [(ngModel)]="user.IsActive" [disabled]="formFieldDisabled" name="isActive"
                      (ngModelChange)="onIsactiveChange($event)">Is Active</mat-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="ModifiedByName">Last Edited By</label>
              <div>
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">{{ ModifiedByName }}</p>
                </div>
              </div>
            </div>


          </div>



          <div class="col-lg-12 col-md-12 col-sm-12 btn-section">
            <input type="button" value="Cancel" *ngIf="!isProfileForm" class="btn btn-type1 pull-right btn-cancel"
              (click)="cancel(f)" />
            <input type="button" value="Cancel" *ngIf="isProfileForm" class="btn btn-type1 pull-right btn-cancel"
              (click)="cancelProfile()" />

            <input type="button" value="Save" *ngIf="!isUpdateForm && !isProfileForm" class="btn btn-type btn-save"
              (click)="newuser(f, 'single')" [disabled]="
                !f.form.valid ||
                isReqComplete ||
                !user.UserRole.UserRoleId ||
                !user.Gender ||
                (isServiceProviderReq && !user.ServiceProvider.ServiceProviderId) ||
                (!isSysAdminRoleSelected && !user.UserType.UserTypeId)
              " />
            <input type="button" value="Save and Add New" *ngIf="!isUpdateForm && !isProfileForm"
              class="btn btn-type save-and-new" (click)="newuser(f, 'multiple')" [disabled]="
                !f.form.valid ||
                isReqComplete ||
                !user.UserRole.UserRoleId ||
                !user.Gender ||
                (isServiceProviderReq && !user.ServiceProvider.ServiceProviderId) ||
                (!isSysAdminRoleSelected && !user.UserType.UserTypeId)
              " />

            <input type="button" value="Delete"
              *ngIf="isUpdateForm && ('DELETE_USER' | canAccess) && currentUser.UserId != user.UserId"
              class="btn btn-type pull-right btn-delete" (click)="openDeleteUserConfirmationModal(f)" [disabled]="
                !f.form.valid ||
                isReqComplete ||
                !user.UserRole.UserRoleId ||
                !user.Gender ||
                (isServiceProviderReq && !user.ServiceProvider.ServiceProviderId) ||
                (!isSysAdminRoleSelected && !user.UserType.UserTypeId)
              " style="margin-right: 15px" />
            <input type="button" value="Save" *ngIf="isUpdateForm" class="btn btn-type btn-save" (click)="updateuser(f)"
              [disabled]="
                !f.form.valid ||
                isReqComplete ||
                !user.UserRole.UserRoleId ||
                !user.Gender ||
                (isServiceProviderReq && !user.ServiceProvider.ServiceProviderId) ||
                (!isSysAdminRoleSelected && !user.UserType.UserTypeId) ||
                !('USER_EDIT' | canAccess)
              " />
            <input type="button" value="Save" *ngIf="isProfileForm" class="btn btn-type btn-save"
              (click)="updateuser(f)" [disabled]="
                !f.form.valid ||
                isReqComplete ||
                !user.UserRole.UserRoleId ||
                !user.Gender ||
                (isServiceProviderReq && !user.ServiceProvider.ServiceProviderId) ||
                (!isSysAdminRoleSelected && !user.UserType.UserTypeId) ||
                !('edit-profile' | canAccess)
              " />
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal" id="checkContractIdModal"
    [ngStyle]="checkContractIdModal ? { display: 'block' } : { display: 'none' }">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" style="font-size: 20px !important">User License Request</h3>
          <button type="button" class="close" data-dismiss="modal" (click)="closecheckContractIdModalModal()"
            style="margin-top: -35px">
            &times;
          </button>
        </div>

        <div class="modal-body" style="text-align: left">
          <p style="text-align: center">
            Unable to send your request to Salesforce. Contract Id is not present for this service provider.
          </p>
        </div>

        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
            <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="closecheckContractIdModalModal()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="requestLicenseModal"
    [ngStyle]="requestLicenseModal ? { display: 'block' } : { display: 'none' }">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">User</h3>
          <button type="button" class="close" data-dismiss="modal" (click)="closeRequestLicenseModal()"
            style="margin-top: -37px">
            &times;
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <h4>{{ licenseRequestErrorMsg }}</h4>
              <br />
              <h4>Do you wish to add licenses from here?</h4>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
            <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="getLicenseRequestDetails()">
              Yes
            </button>
            <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="closeRequestLicenseModal()">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="confirmationRequestLicenseModal"
    [ngStyle]="confirmationRequestLicenseModal ? { display: 'block' } : { display: 'none' }">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">User License Request</h3>
          <button type="button" class="close" data-dismiss="modal"
            (click)="clearUserLicenceRequestData(requestLicenseForm)" style="margin-top: -37px">
            &times;
          </button>
        </div>

        <div class="modal-body" style="text-align: left; font-size: 15px !important">
          <form #requestLicenseForm="ngForm" name="requestLicenseForm" role="form" class="form-horizontal">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <label for="ServiceProvider"> Service Provider: </label>&nbsp;{{
                  licenseRequestDetail.ServiceProviderName
                  }}
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <label for="currentInternalUserLicense"> Current Internal User License(s): </label>&nbsp;{{
                  licenseRequestDetail.TotalInternalUserLicenseCount
                  }}
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <label for="currentExternalUserLicense"> Current External User License(s): </label>&nbsp;{{
                  licenseRequestDetail.TotalExternalUserLicenseCount
                  }}
                </div>
              </div>

              <!--<div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label for="activeInternalUserLicense"> Active Internal User License(s): </label>&nbsp;{{licenseRequestDetail.TotalInternalActiveUsersPresent}}
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <label for="activeExternalUserLicense"> Active External User License(s): </label>&nbsp;{{licenseRequestDetail.TotalExternalActiveUsersPresent}}
                                </div>
                            </div>-->

              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <label for="RequestedUserLicense"> Request Internal User License(s)</label>
                  <div>
                    <input type="number" autocomplete="invalid" class="form-control"
                      name="RequestedInternalLicenseCount" placeholder="Requested Internal User Licenses"
                      [(ngModel)]="licenseRequestPayload.RequestedInternalLicenseCount"
                      #RequestedInternalLicenseCount="ngModel" min="1" maxlength="7" pattern="^[1-9]+[0-9]*$"
                      (input)="changeUserAgree()" oninput="validity.valid||(value='');" />
                    <div *ngIf="
                        RequestedInternalLicenseCount.errors &&
                        (RequestedInternalLicenseCount.dirty || RequestedInternalLicenseCount.touched)
                      " class="alert alert-danger" style="padding: 5px">
                      <div [hidden]="!RequestedInternalLicenseCount.errors.pattern" class="text-danger">
                        Accepts only numeric and greater than 0 values.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <label for="RequestedUserLicense"> Request External User License(s)</label>
                  <div>
                    <input type="number" autocomplete="invalid" class="form-control"
                      name="RequestedExternalLicenseCount" placeholder="Requested External User Licenses"
                      [(ngModel)]="licenseRequestPayload.RequestedExternalLicenseCount"
                      #RequestedExternalLicenseCount="ngModel" min="1" maxlength="7" pattern="^[1-9][0-9]*$"
                      (input)="changeUserAgree()" oninput="validity.valid||(value='');" />
                    <div *ngIf="
                        RequestedExternalLicenseCount.errors &&
                        (RequestedExternalLicenseCount.dirty || RequestedExternalLicenseCount.touched)
                      " class="alert alert-danger" style="padding: 5px">
                      <div [hidden]="!RequestedExternalLicenseCount.errors.pattern" class="text-danger">
                        Accepts only numeric and greater than 0 values.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 form-group" *ngIf="perMonthCharges">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div *ngIf="
                      licenseRequestPayload.RequestedInternalLicenseCount &&
                      !licenseRequestPayload.RequestedExternalLicenseCount
                    ">
                    <p *ngIf="checkBillingDate && internalProRate && internalProRateTotal">
                      Users will be billed at a pro-rate of <b>${{ internalProRate | number: '1.0-0' }}</b>/user (total
                      <b>${{ internalProRateTotal | number: '1.0-0' }}</b>) for internal users for this billing cycle.
                    </p>

                    <p>
                      For regular billing period the users will be billed at a monthly rate of
                      <b>${{ licenseRequestDetail.InternalUserLicenseFee }}</b>/user (total<b>
                        ${{
                        licenseRequestDetail.InternalUserLicenseFee *
                        licenseRequestPayload.RequestedInternalLicenseCount
                        }}</b>) for internal users.
                    </p>
                  </div>

                  <div *ngIf="
                      !licenseRequestPayload.RequestedInternalLicenseCount &&
                      licenseRequestPayload.RequestedExternalLicenseCount
                    ">
                    <p *ngIf="checkBillingDate && externalProRate && externalProRateTotal">
                      Users will be billed at a pro-rate of <b>${{ externalProRate | number: '1.0-0' }}</b>/user (total
                      <b>${{ externalProRateTotal | number: '1.0-0' }}</b>) for external users for this billing cycle.
                    </p>

                    <p>
                      For regular billing period the users will be billed at a monthly rate of
                      <b>${{ licenseRequestDetail.ExternalUserLicenseFee }}</b>/user (total<b>
                        ${{
                        licenseRequestDetail.ExternalUserLicenseFee *
                        licenseRequestPayload.RequestedExternalLicenseCount
                        }}</b>) for external users.
                    </p>
                  </div>

                  <div *ngIf="
                      licenseRequestPayload.RequestedInternalLicenseCount &&
                      licenseRequestPayload.RequestedExternalLicenseCount
                    ">
                    <p *ngIf="
                        checkBillingDate &&
                        internalProRate &&
                        internalProRateTotal != 0 &&
                        externalProRate &&
                        externalProRateTotal != 0
                      ">
                      Users will be billed at a pro-rate of
                      <b>${{ internalProRate | number: '1.0-0' }}</b>/user (total <b>${{ internalProRateTotal | number:
                        '1.0-0' }}</b>) for internal users and <b>${{ externalProRate | number: '1.0-0' }}</b>/user
                      (total <b>${{ externalProRateTotal | number: '1.0-0' }}</b>) for external users for this billing
                      cycle.
                    </p>

                    <p *ngIf="
                        checkBillingDate && internalProRate && internalProRateTotal != 0 && externalProRateTotal == 0
                      ">
                      Users will be billed at a pro-rate of <b>${{ internalProRate | number: '1.0-0' }}</b>/user (total
                      <b>${{ internalProRateTotal | number: '1.0-0' }}</b>) for internal users for this billing cycle.
                    </p>

                    <p *ngIf="
                        checkBillingDate && externalProRate && externalProRateTotal != 0 && internalProRateTotal == 0
                      ">
                      Users will be billed at a pro-rate of <b>${{ externalProRate | number: '1.0-0' }}</b>/user (total
                      <b>${{ externalProRateTotal | number: '1.0-0' }}</b>) for external users for this billing cycle.
                    </p>

                    <p>
                      For regular billing period the users will be billed at a monthly rate of
                      <b>${{ licenseRequestDetail.InternalUserLicenseFee }}</b>/user (total
                      <b>${{
                        licenseRequestDetail.InternalUserLicenseFee *
                        licenseRequestPayload.RequestedInternalLicenseCount
                        }}</b>) for internal users and <b>${{ licenseRequestDetail.ExternalUserLicenseFee }}</b>/user
                      (total
                      <b>${{
                        licenseRequestDetail.ExternalUserLicenseFee *
                        licenseRequestPayload.RequestedExternalLicenseCount
                        }}</b>) for external users.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <mat-checkbox [(ngModel)]="licenseRequestPayload.IsUserAgree"
                    name="isUserAgree"></mat-checkbox>&nbsp;&nbsp;
                  <!--<span style="font-weight: bold;">I agree to the <a (click)="openTermsandConditions()" style="cursor:pointer;">Terms & Conditions</a> and that I have read them.</span>-->
                  <span style="font-weight: bold">I agree to the above listed fees associated with this
                    transaction.</span>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
            <button class="btn btn-type btn-save"
              [disabled]="!requestLicenseForm.form.valid || isReqComplete || !licenseRequestPayload.IsUserAgree"
              (click)="createLicenseRequest(requestLicenseForm)">
              Request
            </button>
            <button class="btn btn-type btn-cancel" data-dismiss="modal"
              (click)="clearUserLicenceRequestData(requestLicenseForm)">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="deleteUserConfirmationModal"
    [ngStyle]="deleteUserConfirmationModal ? { display: 'block' } : { display: 'none' }">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Delete User</h3>
          <button type="button" class="close" data-dismiss="modal" (click)="closeDeleteUserConfirmationModal()"
            style="margin-top: -37px">
            &times;
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <h4>Are you sure you want to delete the user? Clicking on 'Yes' will delete the user permanently.</h4>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
            <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="deleteUser()">Yes</button>
            <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="closeDeleteUserConfirmationModal()">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="removeProfileModal"
    [ngStyle]="removeProfileModal ? { display: 'block' } : { display: 'none' }">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" style="font-size: 20px !important">User</h3>
          <button type="button" class="close" data-dismiss="modal" (click)="closeRemoveProfileModal()"
            style="margin-top: -35px">
            &times;
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <h4>
                Are you sure you want to delete the profile image? Clicking on 'Yes' will delete the profile image
                permanently.
              </h4>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
            <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="fileReset()">Yes</button>
            <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="closeRemoveProfileModal()">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal col-lg-12 col-md-12 col-sm-12 col-xs-12" id="userQRCodeModal"
    [ngStyle]="userQRCodeModal ? { display: 'block' } : { display: 'none' }">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Users QR Cards</h3>
          <button type="button" class="close" data-dismiss="modal" (click)="closeUserQRCodeModal()">&times;</button>
        </div>

        <div class="modal-body" style="max-height: 80vh; font-size: 14px !important">
          <div class="row">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe #userQRCodeReportIframe [src]="userQRCodeReportUrl | safe" class="embed-responsive-item"
                allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>