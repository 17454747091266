<section class="content-header" *ngIf="cleaningAlert">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-4 col-md-6 col-sm-12 form-heading svgRestroom">
        <svg
          enable-background="new 0 0 79.707 79.707"
          version="1.1"
          viewBox="0 0 79.707 79.707"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m67.239 79.707-8.41-28.273c-0.207-7.525-4.795-13.963-11.309-16.849v-26.918c0-4.228-3.439-7.667-7.667-7.667-4.227 0-7.666 3.439-7.666 7.667v26.918c-6.513 2.886-11.099 9.323-11.306 16.844l-8.413 28.275h54.771v3e-3zm-12.81-6-3.431-12.806c-0.287-1.065-1.377-1.704-2.449-1.413-1.067 0.284-1.7 1.381-1.414 2.448l3.152 11.771h-8.436v-12.29c0-1.104-0.896-2-2-2s-2 0.896-2 2v12.287h-7.435l3.153-11.771c0.286-1.065-0.347-2.164-1.414-2.449-1.068-0.289-2.164 0.349-2.449 1.414l-3.431 12.806h-5.765l5.579-18.75h0.012 27.5 0.015l5.575 18.75h-4.763l1e-3 3e-3zm-16.242-66.04c0-0.919 0.748-1.667 1.666-1.667 0.919 0 1.667 0.748 1.667 1.667v25.373c-0.55-0.048-1.104-0.084-1.666-0.084s-1.117 0.036-1.667 0.084v-25.373zm1.667 31.289c6.135 0 11.275 4.276 12.637 10h-25.274c1.36-5.725 6.503-10 12.637-10z"
          />
        </svg>
        <span class="list-heading">EDIT CLEANING ALERT</span>
      </div>

    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12" *ngIf="cleaningAlert">
  <div class="row">
    <form #workorderForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <!-- cleaning alert id -->
        <ng-container
          *ngTemplateOutlet="field;context: { id: 'WorkorderId', name: 'Cleaning Alert #', value: cleaningAlert.WorkorderId, className: 'col-md-6 col-xs-12 form-group' }">
        </ng-container>

        <!-- cleaning alert name -->
        <ng-container
          *ngTemplateOutlet="field;context: { id: 'title', name: 'Cleaning Alert Name', value: cleaningAlert.Title, className: 'col-md-6 col-xs-12 form-group'}">
        </ng-container>

        <div class="col-md-6 col-xs-12 form-group">
          <!-- Priority -->
          <ng-container
            *ngTemplateOutlet="field;context: { id: 'priority', name: 'Priority', value: cleaningAlert.WorkorderPriority.PriorityLevel, className: 'col-lg-6 col-md-6 col-sm-6 p1'}">
          </ng-container>

          <!-- Severity -->
          <ng-container
            *ngTemplateOutlet="field;context: { id: 'severity', name: 'Severity', value: cleaningAlert.WorkorderSeverity.SeverityLevel, className: 'col-lg-6 col-md-6 col-sm-6 s1'}">
          </ng-container>
        </div>

        <!-- status -->
        <div class="col-md-6 col-xs-12 form-group">
          <label for="status"><span id="red-circle">&#x25CF;</span> Status</label>
          <ngx-atlas-select (model)="statusChanged($event)" [list]="workorderStatus" [idField]="'StatusId'"
            [textField]="'Status'" [placeholder]="'Select Status'" [allowClear]="true" [multiple]="false"
            [disabled]="isBadgeEnable" [selectedValue]="cleaningAlert.WorkorderStatus.StatusId">
          </ngx-atlas-select>
        </div>

          <!-- description -->
          <div class="col-md-6 col-xs-12 form-group">
            <label for="description"> Description</label>
              <textarea
                type="text"
                class="form-control"
                rows="3"
                maxlength="500"
                name="Description"
                placeholder="Description"
                [(ngModel)]="cleaningAlert.Description"
                readonly
              ></textarea>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="comment">Comment</label>
              <textarea
                type="text"
                class="form-control"
                rows="3"
                name="Comments"
                placeholder="Comment"
                [(ngModel)]="cleaningAlert.WorkorderComment"
                [readonly]="
                  cleaningAlert.WorkorderStatus.StatusId === 21 ||
                  cleaningAlert.WorkorderStatus.StatusId === 8 ||
                  cleaningAlert.WorkorderStatus.StatusId === 22 ||
                  cleaningAlert.WorkorderStatus.StatusId === 10 ||
                  cleaningAlert.WorkorderStatus.StatusId === 6 ||
                  cleaningAlert.WorkorderStatus.StatusId === 5 ||
                  cleaningAlert.WorkorderStatus.StatusId === 24 ||
                  cleaningAlert.WorkorderStatus.StatusId === 23
                "
              ></textarea>
          </div>

        <div class="col-md-6 col-xs-12 form-group" *ngIf="'CA_ASSIGNMENT_REASSIGN_MANUAL_FROM_WEB' | checkVenueConfig">
          <!--!isStatusInprogress-->
          <label for="assignedto">
            <span id="red-circle" *ngIf="
                  cleaningAlert.WorkorderStatus.StatusId === 19 ||
                  cleaningAlert.WorkorderStatus.StatusId === 21 ||
                  cleaningAlert.WorkorderStatus.StatusId === 8 ||
                  cleaningAlert.WorkorderStatus.StatusId === 22 ||
                  cleaningAlert.WorkorderStatus.StatusId === 10 ||
                  cleaningAlert.WorkorderStatus.StatusId === 5 ||
                  cleaningAlert.WorkorderStatus.StatusId === 6 ||
                  cleaningAlert.WorkorderStatus.StatusId === 4
                ">&#x25CF;</span>
            Assigned To</label>
          <div class="dropdown-select">
            <ngx-atlas-select (model)="cleaningAlert.AssignedTo.UserId = $event" [list]="assignedToUsers"
              [idField]="'UserId'" [textField]="'FirstName'" [placeholder]="'Select Assigned To'" [allowClear]="true"
              [multiple]="false" [disabled]="assignedToDisabled" [selectedValue]="cleaningAlert.AssignedTo.UserId">
            </ngx-atlas-select>
          </div>
        </div>

        <!-- Assigned To -->
        <ng-container *ngIf="!('CA_ASSIGNMENT_REASSIGN_MANUAL_FROM_WEB' | checkVenueConfig)">
          <ng-container
            *ngTemplateOutlet="field;context: { id: 'assignedto', name: 'Assigned To', value: (cleaningAlert.AssignedTo.FirstName && cleaningAlert.AssignedTo.LastName ? cleaningAlert.AssignedTo.FirstName + ' ' + cleaningAlert.AssignedTo.LastName : ''), className: 'col-md-6 col-xs-12 form-group'}">
          </ng-container>
        </ng-container>

        <!-- Scheduled Start Date -->
        <ng-container
          *ngTemplateOutlet="field;context: { id: 'startdate', name: 'Scheduled Start Date', value: cleaningAlert.ScheduleStartDate | date: 'MM/dd/yyyy, hh:mm:ss a', className: 'col-md-6 col-xs-12 form-group' }">
        </ng-container>

        <div class="col-md-6 col-xs-12 form-group" *ngIf="cleaningAlert.FullfilledByWorkorderId">
          <label for="fulfilled_by">FulFilled By</label>
          <div class="clipping-wrapper">
            <p class="readOnly clipping"><a class="wo-id" (click)="navigateToAnAlertDetails(cleaningAlert.FullfilledByWorkorderId)">{{cleaningAlert.FullfilledByWorkorderId}}</a></p>
          </div>
        </div>

        <!-- venue -->
        <ng-container
          *ngTemplateOutlet="field;context: { id: 'venue', name: 'Venue', value: cleaningAlert.Building.Venue.VenueName, className: 'col-md-6 col-xs-12 form-group' }">
        </ng-container>

        <!-- building -->
        <ng-container
          *ngTemplateOutlet="field;context: { id: 'building', name: 'Building', value: cleaningAlert.Building.BuildingName, className: 'col-md-6 col-xs-12 form-group' }">
        </ng-container>

        <!-- location -->
        <ng-container
          *ngTemplateOutlet="field;context: { id: 'location', name: 'Location', value: cleaningAlert.LocationName, className: 'col-md-6 col-xs-12 form-group' }">
        </ng-container>

        <div class="col-md-6 col-xs-12 form-group"
          *ngIf="!isBadgeEnable && cleaningAlert.WorkorderStatus.Status === 'Complete'">
          <label for="completionDate"><span id="red-circle">&#x25CF;</span> Completion Date</label>
          <input class="form-control unstyled" name="ModifiedOn" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"
            placeholder="Completion Date" [(ngModel)]="cleaningAlert.ModifiedOn" #ScheduleStartDate="ngModel"
            required />
          <owl-date-time #dt1 [hour12Timer]="true" [pickerMode]="'dialog'"></owl-date-time>
        </div>

        <ng-container *ngIf="isBadgeEnable && cleaningAlert.WorkorderStatus.Status === 'Complete'">
          <ng-container
            *ngTemplateOutlet="field;context: { id: 'completionDate', name: 'Completion Date', value: cleaningAlert.ModifiedOn | date: 'MM/dd/yyyy, hh:mm:ss a', className: 'col-md-6 col-xs-12 form-group' }">
          </ng-container>
        </ng-container>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <input type="button" value="View Log" *ngIf="cleaningAlert.WorkorderId" class="btn btn-type btn-save"
            (click)="getWorkorderDetails(cleaningAlert.WorkorderId)" />
          <input type="button" value="Inspection History"
            *ngIf="cleaningAlert.InspectionId && ('INS_COMPLETION_CRITERIA_SHOW_HISTORY_ON_CA' | checkVenueConfig)"
            class="btn btn-type btn-save" (click)="getInspectionDetails()" />
          <input type="button" value="History" *ngIf="
              cleaningAlert.WorkorderId &&
              this.cleaningAlert.ParentWorkorderId &&
              ('CA_COMPLETION_CRITERIA_SHOW_HISTORY_ON_CA' | checkVenueConfig)
            " class="btn btn-type btn-save" (click)="getWorkorderHistory(cleaningAlert.WorkorderId)"
            data-toggle="modal" data-target="#cleaningAlertModal" />
          <input type="button" value="Cancel" class="btn btn-type pull-right btn-cancel"
            (click)="goBack()" />
          <input type="button" value="Save" *ngIf="cleaningAlert.WorkorderId" class="btn btn-type btn-save" [disabled]="
              !(TraxPermission.CLEANING_ALERT_MODIFY | canAccess) ||
              !workorderForm.form.valid ||
              !cleaningAlert.WorkorderStatus.StatusId ||
              isStatusComplete ||
              ((!'CA_COMPLETION_CRITERIA_MANUAL_FROM_WEB' | checkVenueConfig) &&
                cleaningAlert.WorkorderStatus.StatusId === 21) ||
              ((cleaningAlert.WorkorderStatus.StatusId === 19 ||
                cleaningAlert.WorkorderStatus.StatusId === 21 ||
                cleaningAlert.WorkorderStatus.StatusId === 8 ||
                cleaningAlert.WorkorderStatus.StatusId === 22 ||
                cleaningAlert.WorkorderStatus.StatusId === 10 ||
                cleaningAlert.WorkorderStatus.StatusId === 5 ||
                cleaningAlert.WorkorderStatus.StatusId === 6 ||
                cleaningAlert.WorkorderStatus.StatusId === 4) &&
                !cleaningAlert.AssignedTo.UserId) ||
              (!('QC_COMPLETION_CRITERIA_MANUAL_FROM_WEB' | checkVenueConfig) &&
                cleaningAlert.WorkorderStatus.StatusId === 6) ||
              cleaningAlert.WorkorderStatus.StatusId === 3 ||
              cleaningAlert.WorkorderStatus.StatusId === 5 ||
              cleaningAlert.WorkorderStatus.StatusId === 6 ||
              cleaningAlert.WorkorderStatus.StatusId === 8 ||
              cleaningAlert.WorkorderStatus.StatusId === 10 ||
              cleaningAlert.WorkorderStatus.StatusId === 22 ||
              cleaningAlert.WorkorderStatus.StatusId === 24
            " (click)="checkIsQCReject(workorderForm, 'no')" />
        </div>

        <div *ngIf="inspectionHistory && cleaningAlert.Inspection != null">
          <div class="col-md-12">
            <hr />
            <h3>Inspection History</h3>
            <div class="col-md-12 col-sm-12 col-lg-12">
              <div class="box-body table-responsive">
                <table class="table table-hover table-responsive">
                  <thead>
                    <tr>
                      <th>Inspect #</th>
                      <th>Venue Name</th>
                      <th>Location Name</th>
                      <th>Status</th>
                      <th *ngIf="cleaningAlert.Inspection.InspectionStatus.InsStatus != 'Open'">Inspector</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ cleaningAlert.Inspection.InspectionId }}</td>
                      <td>{{ cleaningAlert.Inspection.Venue.VenueName }}</td>
                      <td>{{ cleaningAlert.Inspection.Location.LocationName }}</td>
                      <td>{{ cleaningAlert.Inspection.InspectionStatus.InsStatus }}</td>
                      <td *ngIf="cleaningAlert.Inspection.InspectionStatus.InsStatus != 'Open'">
                        {{ cleaningAlert.Inspection.User.FirstName }} {{ cleaningAlert.Inspection.User.LastName }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr class="hr-margin" />
              <div class="row">
                <h3>Overall Comment</h3>
                <div *ngIf="
                    cleaningAlert.Inspection != null &&
                    (cleaningAlert.Inspection.OverallComment == null || cleaningAlert.Inspection.OverallComment == '')
                  " class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No overall comment available.</h4>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="
                    cleaningAlert.Inspection != null &&
                    (cleaningAlert.Inspection.OverallComment != null || cleaningAlert.Inspection.OverallComment != '')
                  ">
                  <h4>
                    <span style="word-break: break-word">{{ cleaningAlert.Inspection.OverallComment }}</span>
                  </h4>
                </div>
              </div>

              <hr class="hr-margin" />
              <div class="row">
                <h3>Beacon Activity</h3>
                <div class="col-md-12 col-sm-12 col-lg-12" *ngIf="!inspectionDetailsBeacons?.length">
                  <h4 class="listerror">No beacon data.</h4>
                  <hr class="hr-margin" />
                </div>

                <div class="col-md-12 col-sm-12 col-lg-12" id="workorderBeacons" *ngIf="inspectionDetailsBeacons?.length">
                  <div class="box-body table-responsive" style="height: auto; overflow-y: auto; max-height: 310px">
                    <table class="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>First Time In Range</th>
                          <th>Last Time In Range</th>
                          <th>Battery Level</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let beacon of inspectionDetailsBeacons">
                          <tr *ngIf="beacon.timeElapsed == null">
                            <td style="cursor: auto">{{ beacon.Beacon.BeaconName }}</td>
                            <td style="cursor: auto" *ngIf="beacon.OptIn != null">
                              {{ beacon.OptIn | date: 'MM/dd/yyyy, hh:mm:ss a' }}
                            </td>
                            <td style="cursor: auto" *ngIf="beacon.OptIn == null"></td>
                            <td style="cursor: auto" *ngIf="beacon.OptOut != null">
                              {{ beacon.OptOut | date: 'MM/dd/yyyy, hh:mm:ss a' }}
                            </td>
                            <td style="cursor: auto" *ngIf="beacon.OptOut == null"></td>
                            <td style="cursor: auto" *ngIf="beacon.BatteryLevel != null">{{ beacon.BatteryLevel }}%</td>
                            <td style="cursor: auto" *ngIf="beacon.BatteryLevel == null">-</td>
                          </tr>
                          <tr *ngIf="beacon.timeElapsed != null">
                            <td class="text-right" style="cursor: auto" colspan="3">Time Elapsed</td>
                            <td style="cursor: auto">{{ beacon.timeElapsed }}</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <hr class="hr-margin" *ngIf="inspectionDetailsBeacons?.length" />

              <div class="row"
                *ngIf="cleaningAlert.Inspection != null && cleaningAlert.Inspection.InspectionElements != null">
                <h3>Elements & Attachments</h3>
                <div class="col-sm-12 col-md-12 col-lg-12 box-body table-responsive"
                  style="padding: 0 18px 0 18px; height: auto; overflow-y: auto; max-height: 310px">
                  <table class="table table-hover table-responsive"
                    style="border-collapse: inherit !important; background: white; margin-bottom: 6px">
                    <div class="table-inner">
                      <div class="col-lg-2 col-md-2 col-sm-2 font">Element #</div>
                      <div class="col-lg-4 col-md-4 col-sm-4 font">Element Name</div>
                      <div class="col-lg-3 col-md-3 col-sm-3 font">Score Factor</div>
                      <div class="col-lg-1 col-md-1 col-sm-1 font">Rating</div>
                      <div class="col-lg-2 col-md-2 col-sm-2 font">Attachment</div>
                    </div>
                    <div *ngFor="let element of cleaningAlert.Inspection.InspectionElements; let index = index">
                      <div class="col-lg-12 col-md-12 first table-innerbody">
                        <div class="col-lg-2 col-md-2 col-sm-2">{{ element.Element.ElementId }}</div>
                        <div class="col-lg-4 col-md-4 col-sm-4" style="word-break: break-all">
                          {{ element.Element.ElementName }}
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3" style="word-break: break-all">
                          {{
                          element.InspectionScoreFactor &&
                          (element.InspectionScoreFactor.ScoreFactorName != null ||
                          element.InspectionScoreFactor.ScoreFactorName != '')
                          ? element.InspectionScoreFactor.ScoreFactorName
                          : ''
                          }}
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1">{{ element.Element.Rating }}</div>
                        <div class="col-lg-2 col-md-2 col-sm-2">
                          <a *ngIf="element.InspectElementAttachments.length > 0" (click)="showSubItem(index)"><i
                              class="fa fa-paperclip" aria-hidden="true"
                              style="font-size: 20px; cursor: pointer"></i></a>
                        </div>
                      </div>

                      <div class="col-lg-12 col-md-12" *ngIf="
                          visibleIndex === index &&
                          element.InspectElementAttachments != null &&
                          element.InspectElementAttachments.length > 0
                        " style="
                          height: auto;
                          border: 1px solid #a3b5b5 !important;
                          overflow-y: auto;
                          max-height: 200px;
                          width: 98%;
                          margin-left: 10px;
                          margin-top: 10px;
                        ">
                        <div class="col-lg-12 col-md-12" *ngFor="let file of element.InspectElementAttachments">
                          <div class="col-lg-2 col-md-2 col-sm-2" id="download">
                            <img *ngIf="
                                file.AttachmentType == 'PNG' ||
                                file.AttachmentType == 'png' ||
                                file.AttachmentType == 'jpg' ||
                                file.AttachmentType == 'JPG' ||
                                file.AttachmentType == 'jpeg' ||
                                file.AttachmentType == 'JPEG' ||
                                file.AttachmentType == 'gif' ||
                                file.AttachmentType == 'GIF' ||
                                file.AttachmentType == 'doc' ||
                                file.AttachmentType == 'DOC'
                              " class="img-responsive cursor-pointer" src="{{ file.AttachmentURL }}"
                              alt="{{ file.AttachmentName }}" style="
                                height: 110px;
                                width: 110px !important;
                                border: 1px solid black;
                                margin: 5px 0px 5px 0;
                              " data-toggle="modal" data-target="#InspectionimageModal"
                              (click)="openInspectionLogImage(file.AttachmentURL)" />
                            <a data-toggle="tooltip" data-html="true" title="View" *ngIf="
                                file.AttachmentType == 'MP4' ||
                                file.AttachmentType == 'mp4' ||
                                file.AttachmentType == 'MOV' ||
                                file.AttachmentType == 'mov' ||
                                file.AttachmentType == 'm4v' ||
                                file.AttachmentType == 'M4V'
                              " (click)="downloadVideo(file.AttachmentURL)" class="fa fa-download" style="
                                color: black;
                                cursor: pointer;
                                height: 110px;
                                width: 110px;
                                border: 1px solid black;
                                margin: 5px 0px 5px 0;
                              "></a>
                          </div>
                          <div class="col-lg-10 col-md-10 col-sm-10">
                            <textarea *ngIf="file.Comment != null" type="text" class="form-control outer-border"
                              rows="4" name="Comments" style="margin-top: 7px; margin-bottom: 10px"
                              readonly>{{ file.Comment }}</textarea>
                            <textarea *ngIf="file.Comment == null" type="text" class="form-control outer-border"
                              rows="4" name="Comments" style="margin-top: 7px; margin-bottom: 10px"
                              readonly>No comment available.</textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="workorderDetails" *ngIf="isWorkorderDetails">
          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
            <hr />
            <h3>Cleaning Alert Log Details</h3>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <h4>Status Activity</h4>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="box-body table-responsive">
                  <table class="table table-hover table-responsive">
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Time</th>
                        <th>User</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let status of workorderDetailsStatus">
                        <td style="cursor: auto">{{ status.Status }}</td>
                        <td style="cursor: auto">{{ status.Time | date: 'MM/dd/yyyy, hh:mm:ss a' }}</td>
                        <td style="cursor: auto">{{ status.UserName }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr class="hr-margin" />
              </div>

              <div *ngIf="additionalCleaningAlerts?.length">
                <h4>Additional Cleaning Alerts Closed</h4>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="box-body table-responsive">
                    <table class="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Cleaning Alert#</th>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Mode</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let alert of additionalCleaningAlerts">
                          <td style="cursor: auto"><a class="wo-id" (click)="navigateToAnAlertDetails(alert.WorkorderId)">#{{ alert.WorkorderId }}</a></td>
                          <td style="cursor: auto">{{ alert.Title }}</td>
                          <td style="cursor: auto">{{ alert.Status }}</td>
                          <td style="cursor: auto">{{ alert.IsTaskBased ? 'Task' : 'Full' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr class="hr-margin" />
                </div>
              </div>

              <div *ngIf="'ALLOW_ATTACHMENTS_FROM_WEB' | checkVenueConfig">
                <h4>Creator's Comment</h4>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror"
                    *ngIf="workorderDetails.CreatorComments == null || workorderDetails.CreatorComments == ''">
                    No comment available.
                  </h4>
                  <h4 *ngIf="workorderDetails.CreatorComments != null || workorderDetails.CreatorComments != ''">
                    <span style="word-break: break-word">{{ workorderDetails.CreatorComments }}</span>
                  </h4>
                  <hr class="hr-margin" />
                </div>
              </div>

              <div *ngIf="'ALLOW_ATTACHMENTS_FROM_WEB' | checkVenueConfig">
                <h4>Creator's Pictures and Videos</h4>
                <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="!workorderDetails.WorkorderCreatorAttachments?.length">
                  <h4 class="listerror">No attachment available.</h4>
                  <hr class="hr-margin" />
                </div>

                <div *ngIf="workorderDetails.WorkorderCreatorAttachments?.length">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="caGallery" *ngFor="let attachments of workorderDetails.WorkorderCreatorAttachments">
                      <img *ngIf="
                          (attachments.AttachmentType != null && attachments.AttachmentType == 'PNG') ||
                          attachments.AttachmentType === 'png' ||
                          attachments.AttachmentType === 'jpg' ||
                          attachments.AttachmentType === 'JPG' ||
                          attachments.AttachmentType === 'jpeg' ||
                          attachments.AttachmentType === 'JPEG' ||
                          attachments.AttachmentType === 'gif' ||
                          attachments.AttachmentType === 'GIF' ||
                          attachments.AttachmentType === 'doc' ||
                          attachments.AttachmentType === 'DOC'
                        " class="img-responsive cursor-pointer" src="{{ attachments.AttachmentURL }}"
                        alt="{{ attachments.AttachmentName }}" width="600" height="400"
                        (click)="openCreatorsPhoto(attachments.AttachmentURL)" data-toggle="modal"
                        data-target="#CreatorsAttachmentImg" />
                      <a data-toggle="tooltip" data-html="true" title="View" *ngIf="
                          (attachments.AttachmentType !== null && attachments.AttachmentType == 'MP4') ||
                          attachments.AttachmentType === 'mp4' ||
                          attachments.AttachmentType === 'MOV' ||
                          attachments.AttachmentType === 'mov' ||
                          attachments.AttachmentType === 'm4v' ||
                          attachments.AttachmentType === 'M4V'
                        " (click)="downloadVideo(attachments.AttachmentURL)" class="fa fa-download"></a>
                    </div>
                  </div>
                  <hr class="hr-margin" />
                </div>
              </div>

              <div *ngIf="'VENUE_PRE_CLEAN_FEEDBACK' | checkVenueConfig">
                <h4>Pre-Cleaning Feedback</h4>
                <div *ngIf="!workorderDetails.InitialSurvey" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No feedback available.</h4>
                  <hr class="hr-margin" />
                </div>

                <div *ngIf="workorderDetails.InitialSurvey">
                  <div class="box-body table-responsive">
                    <table class="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Feedback</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="cursor: auto">{{ workorderDetails.InitialSurvey }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr class="hr-margin" />
                  </div>
                </div>
              </div>

              <div *ngIf="workorderDetails.IsBadgeEnable == 0 || ('VENUE_TAG_BEACON' | checkVenueConfig)">
                <h4>Beacon Activity</h4>
                <div *ngIf="!workorderDetailsBeacons?.length" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No beacon data.</h4>
                  <hr class="hr-margin" />
                </div>

                <div id="workorderBeacons" *ngIf="workorderDetailsBeacons?.length">
                  <div class="box-body table-responsive">
                    <table class="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>First Time In Range</th>
                          <th>Last Time In Range</th>
                          <th>Battery Level</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let beacon of workorderDetailsBeacons">
                          <tr *ngIf="beacon.TimeElapsed == null">
                            <td style="cursor: auto">{{ beacon.Name }}</td>
                            <td style="cursor: auto" *ngIf="beacon.Optin != null">
                              {{ beacon.Optin | date: 'MM/dd/yyyy, hh:mm:ss a' }}
                            </td>
                            <td style="cursor: auto" *ngIf="beacon.Optin == null"></td>
                            <td style="cursor: auto" *ngIf="beacon.Optout != null">
                              {{ beacon.Optout | date: 'MM/dd/yyyy, hh:mm:ss a' }}
                            </td>
                            <td style="cursor: auto" *ngIf="beacon.Optout == null"></td>
                            <td style="cursor: auto" *ngIf="beacon.BatteryLevel != null">{{ beacon.BatteryLevel }}%</td>
                            <td style="cursor: auto" *ngIf="beacon.BatteryLevel == null">-</td>
                          </tr>
                          <tr *ngIf="beacon.TimeElapsed != null">
                            <td class="text-right" style="cursor: auto" colspan="3">Time Elapsed</td>
                            <td style="cursor: auto">{{ beacon.TimeElapsed }}</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                    <hr class="hr-margin" />
                  </div>
                </div>
              </div>

              <div *ngIf="workorderDetails.IsBadgeEnable == 1">
                <h4>Badge Activity</h4>
                <div *ngIf="!workorderDetailsBadges.length" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No badge data.</h4>
                  <hr class="hr-margin" />
                </div>

                <div id="workorderBeacons" *ngIf="workorderDetailsBadges.length">
                  <div class="box-body table-responsive">
                    <table class="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Badge Id</th>
                          <th>In Range</th>
                          <th>Out Range</th>
                          <th>Time Elapsed (Seconds)</th>
                        </tr>
                      </thead>

                      <tbody *ngFor="let badge of workorderDetailsBadges">
                        <tr>
                          <td>{{ badge.Name }}</td>
                          <td *ngIf="badge.Optin != null">{{ badge.Optin | date: 'MM/dd/yyyy, hh:mm:ss a' }}</td>
                          <td *ngIf="badge.Optin == null"></td>
                          <td *ngIf="badge.Optout != null">{{ badge.Optout | date: 'MM/dd/yyyy, hh:mm:ss a' }}</td>
                          <td *ngIf="badge.Optout == null"></td>
                          <td *ngIf="badge.BatteryLevel != null">{{ badge.BatteryLevel }}</td>
                          <td *ngIf="badge.BatteryLevel == null">-</td>
                        </tr>
                      </tbody>

                      <tbody>
                        <tr>
                          <td colspan="3" style="text-align: right">Clean Time (HH:MM:SS)</td>
                          <td>{{ totalCleanTime }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr class="hr-margin" />
                  </div>
                </div>
              </div>

              <div *ngIf="'VENUE_TAG_TASK' | checkVenueConfig">
                <h4>Tasks</h4>
                <div *ngIf="!workorderDetailsTasks?.TaskDetails?.length" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No task data.</h4>
                  <hr class="hr-margin" />
                </div>

                <div id="workorderTasks" *ngIf="workorderDetailsTasks?.TaskDetails?.length">
                  <div class="box-body table-responsive">
                    <table class="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Task</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let task of workorderDetailsTasks.TaskDetails">
                          <tr>
                            <td style="cursor: auto">{{ task.ItemName }}</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                    <hr class="hr-margin" />
                  </div>
                </div>
              </div>

              <div id="workorderTaskComment">
                <h4>Comment</h4>
                <div *ngIf="!isTaskComment" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No comment data.</h4>
                  <hr class="hr-margin" />
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isTaskComment">
                  <h4>
                    <span style="word-break: break-word">{{ workorderDetailsTasks.Comment }}</span>
                  </h4>
                  <hr class="hr-margin" />
                </div>
              </div>

              <div *ngIf="'VENUE_TAG_INVENTORIES' | checkVenueConfig">
                <h4>Inventory</h4>
                <div *ngIf="!workorderDetailsInventories?.length" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No inventory data.</h4>
                  <hr class="hr-margin" />
                </div>

                <div id="workorderInventory" *ngIf="workorderDetailsInventories?.length">
                  <div class="box-body table-responsive">
                    <table class="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let inventory of workorderDetailsInventories">
                          <td style="cursor: auto">{{ inventory.ItemName }}</td>
                          <td style="cursor: auto">{{ inventory.Quantity }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr class="hr-margin" />
                  </div>
                </div>
              </div>

              <div *ngIf="!isQCLogShow" style="margin-top: -20px">
                <h3>Quality Check Details</h3>

                <div *ngIf="isQCTaskComment">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <h4 *ngIf="workorderDetailsTasks != null">
                      Quality Check Comment:&nbsp;<span style="word-break: break-word">{{
                        qcDetails.QCTaskComment.QcComment
                        }}</span>
                    </h4>
                    <hr class="hr-margin" />
                  </div>
                </div>

                <div *ngIf="!isQCTaskComment" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No Comment available.</h4>
                  <hr class="hr-margin" />
                </div>

                <div *ngIf="isQCFeedback">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <h4 *ngIf="workorderDetailsTasks != null">
                      Quality Check Feedback:&nbsp;<span>{{ qcDetails.PreQCFeedback }}</span>
                    </h4>
                    <hr class="hr-margin" />
                  </div>
                </div>

                <div *ngIf="!isQCFeedback" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No Feedback available.</h4>
                  <hr class="hr-margin" />
                </div>

                <div>
                  <h4>Beacon Activity</h4>
                  <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="!qcDetailsBeacons?.length">
                    <h4 class="listerror">No beacon data.</h4>
                    <hr class="hr-margin" />
                  </div>

                  <div id="workorderBeacons" *ngIf="qcDetailsBeacons?.length">
                    <div class="box-body table-responsive">
                      <table class="table table-hover table-responsive">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>First Time In Range</th>
                            <th>Last Time In Range</th>
                            <th>Battery Level</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let beacon of qcDetailsBeacons">
                            <tr *ngIf="beacon.timeElapsed == null">
                              <td style="cursor: auto">{{ beacon.Beacon.BeaconName }}</td>
                              <td style="cursor: auto" *ngIf="beacon.OptIn != null">
                                {{ beacon.OptIn | date: 'MM/dd/yyyy, hh:mm:ss a' }}
                              </td>
                              <td style="cursor: auto" *ngIf="beacon.OptIn == null"></td>
                              <td style="cursor: auto" *ngIf="beacon.OptOut != null">
                                {{ beacon.OptOut | date: 'MM/dd/yyyy, hh:mm:ss a' }}
                              </td>
                              <td style="cursor: auto" *ngIf="beacon.OptOut == null"></td>
                              <td style="cursor: auto" *ngIf="beacon.BatteryLevel != null">
                                {{ beacon.BatteryLevel }}%
                              </td>
                              <td style="cursor: auto" *ngIf="beacon.BatteryLevel == null">-</td>
                            </tr>
                            <tr *ngIf="beacon.timeElapsed != null">
                              <td class="text-right" style="cursor: auto" colspan="3">Time Elapsed</td>
                              <td style="cursor: auto">{{ beacon.timeElapsed }}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                      <hr class="hr-margin" />
                    </div>
                  </div>
                </div>

                <div *ngIf="('VENUE_TAG_TASK' | checkVenueConfig) && !qcDetails?.QCTaskFeedbacks"
                  class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No Task data.</h4>
                  <hr class="hr-margin" />
                </div>

                <div *ngIf="('VENUE_TAG_TASK' | checkVenueConfig) && qcDetails?.QCTaskFeedbacks">
                  <h4>Tasks</h4>
                  <div id="workorderTasks">
                    <div class="box-body table-responsive">
                      <table class="table table-hover table-responsive">
                        <thead>
                          <tr>
                            <th>Task</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let feedback of qcDetails.QCTaskFeedbacks">
                            <td style="cursor: auto">{{ feedback.TaskName }}</td>
                            <td style="cursor: auto">
                              <span *ngIf="feedback.QcInput === 'S'"><i class="fa fa-check-circle"></i></span>
                              <span *ngIf="feedback.QcInput === 'U'"><i class="fa fa-times-circle"></i></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <hr class="hr-margin" />
                    </div>
                  </div>
                </div>

                <div *ngIf="('VENUE_TAG_INVENTORIES' | checkVenueConfig) && !qcDetails?.QCInventoryRefills?.length"
                  class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No Inventory data.</h4>
                  <hr class="hr-margin" />
                </div>

                <div *ngIf="('VENUE_TAG_INVENTORIES' | checkVenueConfig) && qcDetails?.QCInventoryRefills?.length">
                  <h4>Inventory</h4>
                  <div id="workorderTasks">
                    <div class="box-body table-responsive">
                      <table class="table table-hover table-responsive">
                        <thead>
                          <tr>
                            <th>Inventory</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let refill of qcDetails.QCInventoryRefills">
                            <td style="cursor: auto">{{ refill.InventoryName }}</td>
                            <td style="cursor: auto">{{ refill.Quantity }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <hr class="hr-margin" />
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="workorderQCImages != null && workorderQCImages?.length > 0">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="caGallery" *ngFor="let attachments of workorderQCImages">
                      <img *ngIf="
                          (attachments.ImageType != null && attachments.ImageType === 'PNG') ||
                          attachments.ImageType === 'png' ||
                          attachments.ImageType === 'jpg' ||
                          attachments.ImageType === 'JPG' ||
                          attachments.ImageType === 'jpeg' ||
                          attachments.ImageType === 'JPEG' ||
                          attachments.ImageType === 'gif' ||
                          attachments.ImageType === 'GIF' ||
                          attachments.ImageType === 'doc' ||
                          attachments.ImageType === 'DOC'
                        " class="img-responsive cursor-pointer" src="{{ attachments.AttachmentURL }}"
                        alt="{{ attachments.ImageName }}" width="600" height="400"
                        (click)="openQCAttachment(attachments.AttachmentURL)" data-toggle="modal"
                        data-target="#imageModal" />
                      <a data-toggle="tooltip" data-html="true" title="View" *ngIf="
                          (attachments.ImageType != null && attachments.ImageType === 'MP4') ||
                          attachments.ImageType === 'mp4' ||
                          attachments.ImageType === 'MOV' ||
                          attachments.ImageType === 'mov' ||
                          attachments.ImageType === 'm4v' ||
                          attachments.ImageType === 'M4V'
                        " (click)="downloadVideo(attachments.AttachmentURL)" class="fa fa-download"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal" id="cleaningAlertRejectQCModal"
          [ngStyle]="cleaningAlertRejectQCModal ? { display: 'block' } : { display: 'none' }">
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" (click)="closecleaningAlertRejectQCModal()">&times;</button>
                <h3 class="modal-title">Quality Control Check</h3>
              </div>
              <div class="modal-body">Are you sure you want to Accept/reject this cleaning alert Quality Check?</div>
              <div class="modal-footer">
                <input type="button" value="With Cleaning Alert" class="btn btn-type btn-save" (click)="updateWorkorder(workorderForm, 'yes')" />
                <input type="button" value="Without Cleaning Alert" class="btn btn-type btn-cancel" (click)="updateWorkorder(workorderForm, 'no')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal fade" id="cleaningAlertModal" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Cleaning Alert History</h3>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <div class="modal-body" style="text-align: left !important">
          <div class="row">
            <div
              class="col-lg-12 col-md-12 form-group"
              *ngFor="let workorderHistory of workorderHistoryDetails; let i = index"
            >
              <div class="panel-group" id="accordion">
                <div class="panel panel-default">
                  <div
                    class="panel-heading"
                    id="caHistoryheading{{ i }}"
                    style="height: auto; display: inline-flex; width: 100%"
                  >
                    <h4 class="panel-title">
                      <a
                        id="woHistory{{ workorderHistory.CleaningAlertDetails.Title }}"
                        class="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        data-target="#CleaningAlertDetails{{ i }}"
                        href="#CleaningAlertDetails{{ i }}"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <span style="font-weight: bold; float: left; word-break: break-word"
                          >Cleaning Alert # {{ workorderHistory.CleaningAlertDetails.WorkorderId }} -
                        </span>
                        <span style="float: left; padding-left: 5px; word-break: break-word">{{
                          workorderHistory.CleaningAlertDetails.Title
                        }}</span>
                        <span style="float: right; padding: 0 8px 0 8px; padding-left: 5px; word-break: break-word"
                          >Completion Date:
                          {{
                            workorderHistory.CleaningAlertDetails.WorkorderCompletionDate
                              | date: 'MM/dd/yyyy, hh:mm:ss a'
                          }}</span
                        >
                      </a>
                    </h4>
                  </div>

                  <div id="CleaningAlertDetails{{ i }}" class="panel-collapse collapse">
                    <div class="panel-body">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="col-md-6 history-box">
                            <span class="history-header">Cleaning Alert #: </span>
                            <span class="history-text">{{ workorderHistory.CleaningAlertDetails.WorkorderId }}</span>
                          </div>

                          <div class="col-md-6 history-box">
                            <span class="history-header">Cleaning Alert Name: </span>
                            <span class="history-text">{{ workorderHistory.CleaningAlertDetails.Title }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <div class="col-md-12 history-box">
                            <span class="history-header">CA Performed By: </span>
                            <span class="history-text"
                              >{{ workorderHistory.CleaningAlertDetails.AssignedTo.FirstName }}
                              {{ workorderHistory.CleaningAlertDetails.AssignedTo.LastName }}</span
                            >
                          </div>
                        </div>
                      </div>

                      <hr style="margin: 5px !important; border-top: 1px solid #3d5c70" />

                      <div
                        *ngIf="
                          !workorderHistory.CleaningAlertDetails?.WorkorderDetail?.WorkorderTaskComment?.Comment
                        "
                        class="col-lg-12 col-md-12 col-sm-12"
                      >
                        <h4 class="listerror">No Comment available.</h4>
                        <hr class="hr-margin" />
                      </div>

                      <div
                        *ngIf="
                          workorderHistory.CleaningAlertDetails?.WorkorderDetail?.WorkorderTaskComment?.Comment
                        "
                      >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <h4>
                            CA Comment:&nbsp;<span style="word-break: break-word">{{
                              workorderHistory.CleaningAlertDetails?.WorkorderDetail?.WorkorderTaskComment?.Comment
                            }}</span>
                          </h4>
                          <hr class="hr-margin" />
                        </div>
                      </div>

                      <div
                        *ngIf="
                          workorderHistory.CleaningAlertDetails != null &&
                          workorderHistory.CleaningAlertDetails.InitialSurvey == null
                        "
                        class="col-lg-12 col-md-12 col-sm-12"
                      >
                        <h4 class="listerror">No Feedback available.</h4>
                        <hr class="hr-margin" />
                      </div>

                      <div>
                        <div
                          class="col-lg-12 col-md-12 col-sm-12"
                          *ngIf="
                            workorderHistory.CleaningAlertDetails != null &&
                            workorderHistory.CleaningAlertDetails.InitialSurvey != null
                          "
                        >
                          <h4>
                            CA Feedback:&nbsp;<span>{{ workorderHistory.CleaningAlertDetails.InitialSurvey }}</span>
                          </h4>
                          <hr class="hr-margin" />
                        </div>
                      </div>

                      <div
                        *ngIf="
                          workorderHistory.CleaningAlertDetails != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment.TaskDetails ==
                            null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment.TaskDetails
                            .length <= 0
                        "
                        class="col-lg-12 col-md-12 col-sm-12"
                      >
                        <h4 class="listerror">No Task data.</h4>
                        <hr class="hr-margin" />
                      </div>

                      <div
                        *ngIf="
                          workorderHistory.CleaningAlertDetails != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment.TaskDetails !=
                            null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment.TaskDetails
                            .length > 0
                        "
                      >
                        <h4>Tasks</h4>
                        <div id="workorderTasks">
                          <div class="box-body table-responsive">
                            <table class="table table-hover table-responsive">
                              <thead>
                                <tr>
                                  <th style="text-align: unset">Task Name</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let task of workorderHistory.CleaningAlertDetails.WorkorderDetail
                                      .WorkorderTaskComment.TaskDetails
                                  "
                                >
                                  <td>{{ task.Task.TaskName }}</td>
                                </tr>
                              </tbody>
                            </table>
                            <hr class="hr-margin" style="margin-top: 10px !important" />
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="
                          (workorderHistory.CleaningAlertDetails != null &&
                            workorderHistory.CleaningAlertDetails.WorkorderDetail.Inventory == null) ||
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.Inventory.length <= 0
                        "
                        class="col-lg-12 col-md-12 col-sm-12"
                      >
                        <h4 class="listerror">No Inventory data.</h4>
                        <hr class="hr-margin" />
                      </div>

                      <div
                        *ngIf="
                          workorderHistory.CleaningAlertDetails != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.Inventory != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.Inventory.length > 0
                        "
                      >
                        <h4>Inventory</h4>
                        <div id="workorderTasks">
                          <div class="box-body table-responsive">
                            <table class="table table-hover">
                              <thead>
                                <tr>
                                  <th>Inventory Name</th>
                                  <th>Quantity</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let inventory of workorderHistory.CleaningAlertDetails.WorkorderDetail.Inventory
                                  "
                                >
                                  <td>{{ inventory.ItemName }}</td>
                                  <td>{{ inventory.Quantity }}</td>
                                </tr>
                              </tbody>
                            </table>
                            <hr class="hr-margin" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="panel-group" id="accordion" style="margin-top: 15px" *ngIf="workorderHistory.QCDetails">
                <div class="panel panel-default">
                  <div
                    class="panel-heading"
                    id="caHistoryheading{{ i }}"
                    style="height: auto; display: inline-flex; width: 100%"
                  >
                    <h4 class="panel-title">
                      <a
                        id="woHistory{{ workorderHistory.QCDetails.Title }}"
                        class="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        data-target="#QCDetails{{ i }}"
                        href="#QCDetails{{ i }}"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <span style="font-weight: bold; float: left; word-break: break-word"
                          >Quality Check # {{ workorderHistory.QCDetails.QcId }} -
                        </span>
                        <span style="float: left; word-break: break-word">{{ workorderHistory.QCDetails.Title }}</span>
                        <span style="float: right; padding: 0 8px 0 8px; word-break: break-word"
                          >Completion Date:
                          {{ workorderHistory.QCDetails.QCCompletionDate | date: 'MM/dd/yyyy, hh:mm:ss a' }}</span
                        >
                      </a>
                    </h4>
                  </div>
                  <div id="QCDetails{{ i }}" class="panel-collapse collapse">
                    <div class="panel-body">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="col-md-6 history-box">
                            <span class="history-header">Quality Check #: </span>
                            <span class="history-text">{{ workorderHistory.QCDetails.QcId }}</span>
                          </div>

                          <div class="col-md-6 history-box">
                            <span class="history-header">Quality Check Name: </span>
                            <span class="history-text">{{ workorderHistory.QCDetails.Title }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <div class="col-md-12 history-box">
                            <span class="history-header">Quality Check Performed By: </span>
                            <span class="history-text"
                              >{{ workorderHistory.QCDetails.QCAssignedTo.FirstName }}
                              {{ workorderHistory.QCDetails.QCAssignedTo.LastName }}</span
                            >
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="
                          (workorderHistory.QCDetails != null && workorderHistory.QCDetails.QCTaskComment == null) ||
                          workorderHistory.QCDetails.QCTaskComment.length <= 0
                        "
                        class="col-lg-12 col-md-12 col-sm-12"
                      >
                        <h4 class="listerror">No Comment available.</h4>
                        <hr class="hr-margin" />
                      </div>

                      <div
                        *ngIf="
                          workorderHistory.QCDetails != null &&
                          workorderHistory.QCDetails.QCTaskComment != null &&
                          workorderHistory.QCDetails.QCTaskComment.QcComment != null
                        "
                      >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <h4>
                            Quality Check Comment:&nbsp;<span style="word-break: break-word">{{
                              workorderHistory.QCDetails.QCTaskComment.QcComment
                            }}</span>
                          </h4>
                          <hr class="hr-margin" />
                        </div>
                      </div>

                      <div
                        *ngIf="
                          (workorderHistory.QCDetails != null && workorderHistory.QCDetails.PreQCFeedback == null) ||
                          workorderHistory.QCDetails.PreQCFeedback.length <= 0
                        "
                        class="col-lg-12 col-md-12 col-sm-12"
                      >
                        <h4 class="listerror">No Feedback available.</h4>
                        <hr class="hr-margin" />
                      </div>

                      <div
                        *ngIf="workorderHistory.QCDetails != null && workorderHistory.QCDetails.PreQCFeedback != null"
                      >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <h4>
                            Quality Check Feedback:&nbsp;<span>{{ workorderHistory.QCDetails.PreQCFeedback }}</span>
                          </h4>
                          <hr class="hr-margin" />
                        </div>
                      </div>

                      <div>
                        <div
                          *ngIf="
                            (workorderHistory.QCDetails != null &&
                              workorderHistory.QCDetails.QCTaskFeedbacks == null) ||
                            workorderHistory.QCDetails.QCTaskFeedbacks <= 0
                          "
                          class="col-lg-12 col-md-12 col-sm-12"
                        >
                          <h4 class="listerror">No Task data.</h4>
                          <hr class="hr-margin" />
                        </div>

                        <div
                          id="workorderTasks"
                          *ngIf="
                            workorderHistory.QCDetails != null &&
                            workorderHistory.QCDetails.QCTaskFeedbacks != null &&
                            workorderHistory.QCDetails.QCTaskFeedbacks.length > 0
                          "
                        >
                          <h4>Tasks</h4>
                          <div class="box-body table-responsive">
                            <table class="table table-hover table-responsive">
                              <thead>
                                <tr>
                                  <th>Task</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let feedback of workorderHistory.QCDetails.QCTaskFeedbacks">
                                  <td>{{ feedback.TaskName }}</td>
                                  <!--<td>{{feedback.QcInput}}</td>-->
                                  <td>
                                    <span *ngIf="feedback.QcInput == 'S'"><i class="fa fa-check-circle"></i></span>
                                    <span *ngIf="feedback.QcInput == 'U'"><i class="fa fa-times-circle"></i></span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <hr class="hr-margin" />
                          </div>
                        </div>
                      </div>

                      <div>
                        <div
                          *ngIf="
                            (workorderHistory.QCDetails != null &&
                              workorderHistory.QCDetails.QCInventoryRefills == null) ||
                            workorderHistory.QCDetails.QCInventoryRefills.length <= 0
                          "
                          class="col-lg-12 col-md-12 col-sm-12"
                        >
                          <h4 class="listerror">No Inventory data.</h4>
                          <hr class="hr-margin" />
                        </div>

                        <div
                          id="workorderTasks"
                          *ngIf="
                            workorderHistory.QCDetails != null &&
                            workorderHistory.QCDetails.QCInventoryRefills != null &&
                            workorderHistory.QCDetails.QCInventoryRefills.length > 0
                          "
                        >
                          <h4>Top-up Inventory</h4>
                          <div class="box-body table-responsive">
                            <table class="table table-hover table-responsive">
                              <thead>
                                <tr>
                                  <th>Inventory</th>
                                  <th>Quantity</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let refill of workorderHistory.QCDetails.QCInventoryRefills">
                                  <td>{{ refill.InventoryName }}</td>
                                  <td>{{ refill.Quantity }}</td>
                                </tr>
                              </tbody>
                            </table>
                            <hr class="hr-margin" />
                          </div>
                        </div>
                      </div>

                      <div
                        class="row"
                        *ngIf="
                          workorderHistory != null &&
                          workorderHistory.QCDetails != null &&
                          workorderHistory.QCDetails.QCImages != null &&
                          workorderHistory.QCDetails.QCImages.length > 0
                        "
                      >
                        <span class="history-header" style="margin-left: 30px">Quality Check Attachments:</span>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div
                            class="col-lg-3 col-md-3 col-sm-3"
                            style="margin-top: 10px"
                            *ngFor="let attachments of workorderHistory.QCDetails.QCImages"
                          >
                            <div
                              style="border: 1px solid #d2d6de; padding: 4px"
                              *ngIf="
                                (attachments.ImageType != null && attachments.ImageType == 'PNG') ||
                                attachments.ImageType == 'png' ||
                                attachments.ImageType == 'jpg' ||
                                attachments.ImageType == 'JPG' ||
                                attachments.ImageType == 'jpeg' ||
                                attachments.ImageType == 'JPEG' ||
                                attachments.ImageType == 'gif' ||
                                attachments.ImageType == 'GIF' ||
                                attachments.ImageType == 'doc' ||
                                attachments.ImageType == 'DOC'
                              "
                            >
                              <img
                                class="img-responsive cursor-pointer"
                                src="{{ attachments.AttachmentURL }}"
                                alt="{{ attachments.ImageName }}"
                                style="height: 125px; width: 100%"
                                (click)="openQCAttachment(attachments.AttachmentURL)"
                                data-toggle="modal"
                                data-target="#imageModal"
                              />
                            </div>

                            <div
                              style="border: 1px solid #d2d6de; text-align: center; height: 135px"
                              *ngIf="
                                (attachments.ImageType != null && attachments.ImageType == 'MP4') ||
                                attachments.ImageType == 'mp4' ||
                                attachments.ImageType == 'MOV' ||
                                attachments.ImageType == 'mov' ||
                                attachments.ImageType == 'm4v' ||
                                attachments.ImageType == 'M4V'
                              "
                            >
                              <a
                                class="fa fa-download"
                                style="color: black; cursor: pointer; position: relative; top: calc(50% - 10px)"
                                data-toggle="tooltip"
                                data-html="true"
                                title="View"
                                (click)="downloadVideo(attachments.AttachmentURL)"
                              ></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer"></div>
      </div>
    </div>
  </div>

  <div
    class="modal signup-content"
    id="reAssignModal"
    [ngClass]="{ show: reAssignModal }"
    [ngStyle]="reAssignModal ? { display: 'block' } : { display: 'none' }"
  >
    <form #workorderForm="ngForm">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">Reassign Cleaning Alert</h3>
            <button type="button" class="close" (click)="closeReassignModal()" style="margin-top: -26px">
              &times;
            </button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="box" style="box-shadow: none; border: none">
                  <div class="box-body">
                    <span>{{ reAssignErrorMsg }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
              <button
                class="btn btn-save btn-footersave"
                [disabled]="!workorderForm.form.valid"
                (click)="updateWorkorder(workorderForm, 'no')"
              >
                RE-ASSIGN
              </button>
              <button class="btn btn-type btn-cancel" (click)="closeReassignModal()">CANCEL</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <ng-container
    *ngTemplateOutlet="attachmentPopup;context: { id: 'InspectionimageModal', attachment: InspectionImg }">
  </ng-container>

  <ng-container
    *ngTemplateOutlet="attachmentPopup;context: { id: 'imageModal', attachment: qcAttachmentImg }">
  </ng-container>

  <ng-container
    *ngTemplateOutlet="attachmentPopup;context: { id: 'CreatorsAttachmentImg', attachment: CreatorsAttachmentImg }">
  </ng-container>

</section>

<ng-template #field let-id="id" let-name="name" let-value="value" let-className="className">
  <div [ngClass]="className">
    <label [for]="id"> {{name}}</label>
    <div class="clipping-wrapper">
      <p class="readOnly clipping">{{ value ? value : '' }}</p>
    </div>
  </div>
</ng-template>

<ng-template #attachmentPopup let-id="id" let-attachment="attachment">
  <div class="modal fade" [id]="id" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close close-button" data-dismiss="modal" style="color: black">&times;</button>
          <img class="img-responsive" src="{{ attachment }}" style="max-height: 424px; margin: auto" />
        </div>
      </div>
    </div>
  </div>
</ng-template>
