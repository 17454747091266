import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { TraxPermission } from 'src/app/core/models/trax-permission.model';
import { TaxTableColumnConfig } from './trax-table.model';

interface ColumnFilter {
  column: TaxTableColumnConfig;
  options: any[];
  filteredOptions: any[];
  control: string;
  searchControl: FormControl;
}

@Component({
  selector: 'trax-table',
  templateUrl: 'trax-table.component.html',
  styleUrls: ['trax-table.component.scss'],
})
export class TraxTableComponent implements OnInit {
  @ViewChild('exporter') exporter;

  TraxPermission = TraxPermission;

  @Input() fileName: string = 'data';
  @Input() sheetName: string = 'data';
  @Input() columnConfig: TaxTableColumnConfig[] = [];
  @Input() dataSource: Observable<any[]>;
  @Input() filterText: Observable<string>;
  @Input() tableDataStyle: string;
  @Input() tableHeaderStyle: string;

  @Output() dataLoaded = new EventEmitter<any>();
  @Output() dataLoading = new EventEmitter<any>();
  @Output() rowClick = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tableDataSource: MatTableDataSource<any>;
  dataSource$: Observable<MatTableDataSource<any>>;
  columnFilters: ColumnFilter[] = [];
  filterForm: FormGroup;
  originalData: any[] = [];
  isDataLoading: boolean = false;

  constructor(private fb: FormBuilder) {
    // Initialize empty form group
    this.filterForm = this.fb.group({});
  }

  ngOnInit() {
    // Initialize the form with empty controls for each column
    this.initializeEmptyForm();
    this.initializeDataSource();
  }

  get displayedColumns(): string[] {
    return this.columnConfig.map((c) => c.name);
  }

  get displayedLabels(): string[] {
    return this.columnConfig.map((c) => c.label);
  }

  get firstField(): string {
    return this.displayedColumns?.length ? this.displayedColumns[0] : '';
  }
  public refresh() {
    if (this.isDataLoading) return;
    this.initializeDataSource();
  }

  private initializeEmptyForm() {
    const filterControls = {};
    this.displayedColumns.forEach((column) => {
      const controlName = `filter_${column}`;
      filterControls[controlName] = [];
    });
    this.filterForm = this.fb.group(filterControls);
  }

  private initializeDataSource() {
    this.isDataLoading = true;
    this.dataLoading.emit();
    this.dataSource$ = this.dataSource.pipe(
      map((data) => {
        this.originalData = data;
        this.tableDataSource = new MatTableDataSource<any>();
        this.tableDataSource.data = data;
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        this.setupSortingAccessor();
        this.initializeColumnFilters(data);
        this.setupFilterPredicate();
        this.dataLoaded.emit(data);
        this.isDataLoading = false;
        return this.tableDataSource;
      }),
    );
  }

  public get showFilter(): boolean {
    return !!this.columnFilters?.length;
  }

  private initializeColumnFilters(data: any[]) {
    this.columnFilters = this.columnConfig
      .filter((c) => !!c.showFilter)
      .map((column: TaxTableColumnConfig) => {
        const controlName = `filter_${column.name}`;
        const options = this.getUniqueValues(data, column);
        const searchControl = new FormControl('');

        // Setup search filtering
        searchControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((searchText) => {
          this.filterOptions(column.name, searchText);
        });

        return {
          column,
          options,
          filteredOptions: options,
          control: controlName,
          searchControl,
        };
      });

    // Update existing form controls with new values
    const filterControls = {};
    this.columnFilters.forEach((filter) => {
      filterControls[filter.control] = [];
    });
    this.filterForm.patchValue(filterControls);

    this.filterForm.valueChanges.subscribe(() => {
      this.applyFilters();
    });
  }

  private filterOptions(column: string, searchText: string) {
    const filter = this.columnFilters.find((f) => f.column.name === column);
    if (filter) {
      filter.filteredOptions = filter.options.filter((option) =>
        String(option).toLowerCase().includes(searchText.toLowerCase()),
      );
    }
  }

  public onRowClick(row: any) {
    this.rowClick.emit(row);
  }

  private getUniqueValues(data: any[], column: TaxTableColumnConfig): any[] {
    const values = new Set(data.map((item) => this.formatValue(item[column.name], column)));
    return Array.from(values)
      .filter((value) => value !== undefined && value !== null)
      .sort((a, b) => {
        if (typeof a === 'string' && typeof b === 'string') {
          return a.localeCompare(b);
        }
        return String(a).localeCompare(String(b));
      });
  }
  private setupFilterPredicate() {
    this.tableDataSource.filterPredicate = (data: any, filter: string) => {
      // Text filter
      const searchStr = filter.toLowerCase().trim();
      const dataStr = Object.values(data).join('').toLowerCase();
      const textMatch = !searchStr || dataStr.indexOf(searchStr) !== -1;

      // Column filters
      const columnMatch = this.columnFilters.every((columnFilter) => {
        const selectedValues = this.filterForm.get(columnFilter.control).value;
        if (!selectedValues || selectedValues.length === 0) return true;
        const value = this.formatValue(data[columnFilter.column.name], columnFilter.column);
        return selectedValues.includes(value);
      });

      return textMatch && columnMatch;
    };
  }

  private applyFilters() {
    // Trigger filter
    this.tableDataSource.filter = this.tableDataSource.filter || ' ';
  }

  clearFilters() {
    this.dataLoading.emit();
    this.filterForm.reset();
    Object.keys(this.filterForm.controls).forEach((key) => {
      this.filterForm.get(key).setValue([]);
    });

    // Clear search controls and reset filtered options
    this.columnFilters.forEach((filter) => {
      filter.searchControl.setValue('');
      filter.filteredOptions = filter.options;
    });

    this.tableDataSource.filter = '';

    this.dataLoaded.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filterText'] && !!this.tableDataSource) {
      this.tableDataSource.filter = changes['filterText'].currentValue.trim().toLowerCase();
    }
  }

  private setupSortingAccessor() {
    this.tableDataSource.sortingDataAccessor = (item: any, property: string) => {
      const value = item[property];

      if (value == null) {
        return '';
      }

      switch (typeof value) {
        case 'string':
          return value.toLowerCase();
        case 'number':
          return value;
        case 'boolean':
          return value ? 1 : 0;
        case 'object':
          if (value instanceof Date) {
            return value.getTime();
          }
          return JSON.stringify(value).toLowerCase();
        default:
          return value;
      }
    };
  }

  getColumnHeader(columnHeader: TaxTableColumnConfig): string {
    const label = this.columnConfig.find((c) => c.name == columnHeader.name)?.label;
    return label || columnHeader.name.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
  }

  formatValue(value: any, config: TaxTableColumnConfig) {
    if (!!config.formatter) {
      return config.formatter(value);
    }

    switch (typeof value) {
      case 'boolean':
        return value ? 'Y' : 'N';
      default:
        return value;
    }
  }

  isExporting: boolean = false;
  exportType: string = '';

  onExportComplete(event: any) {
    console.log('Export completed:', event);
    this.isExporting = false;

    // Reset export type
    setTimeout(() => {
      this.exportType = '';
    }, 1000);
  }

  export(format: string) {
    this.exportType = format;

    console.log('Export started:', event);
    this.isExporting = true;

    let options: any = { fileName: 'TRAX Analytics - ' + this.fileName };

    switch (format) {
      case 'xlsx':
        options = {
          ...options,
          sheet: this.sheetName || this.fileName,
          Props: { Author: 'TRAX Analytics' },
        };
        break;
      case 'csv':
        options = {
          ...options,
          delimiter: ',',
          headers: true,
        };
        break;
      case 'txt':
        // Basic options for text export
        break;
    }

    // Reference the ViewChild for the exporter
    this.exporter.exportTable(format, options);
  }
}
