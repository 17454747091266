<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-4 col-md-6 col-sm-12 form-heading svgRestroom">
        <svg
          enable-background="new 0 0 79.707 79.707"
          version="1.1"
          viewBox="0 0 79.707 79.707"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m67.239 79.707-8.41-28.273c-0.207-7.525-4.795-13.963-11.309-16.849v-26.918c0-4.228-3.439-7.667-7.667-7.667-4.227 0-7.666 3.439-7.666 7.667v26.918c-6.513 2.886-11.099 9.323-11.306 16.844l-8.413 28.275h54.771v3e-3zm-12.81-6-3.431-12.806c-0.287-1.065-1.377-1.704-2.449-1.413-1.067 0.284-1.7 1.381-1.414 2.448l3.152 11.771h-8.436v-12.29c0-1.104-0.896-2-2-2s-2 0.896-2 2v12.287h-7.435l3.153-11.771c0.286-1.065-0.347-2.164-1.414-2.449-1.068-0.289-2.164 0.349-2.449 1.414l-3.431 12.806h-5.765l5.579-18.75h0.012 27.5 0.015l5.575 18.75h-4.763l1e-3 3e-3zm-16.242-66.04c0-0.919 0.748-1.667 1.666-1.667 0.919 0 1.667 0.748 1.667 1.667v25.373c-0.55-0.048-1.104-0.084-1.666-0.084s-1.117 0.036-1.667 0.084v-25.373zm1.667 31.289c6.135 0 11.275 4.276 12.637 10h-25.274c1.36-5.725 6.503-10 12.637-10z"
          />
        </svg>
        <span class="list-heading">CLEANING ALERT LOGS</span>
      </div>

      <div class="col-lg-8 col-md-12">
        <div id="page-size" class="col-lg-1 col-sm-2 col-md-2 col-xs-12 pull-right list-heading pageSize">
          <ngx-atlas-select
            (model)="onPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <app-log-filter
          [pageName]="pageName"
          [disable]="!!searchString"
          (filterChanged)="onFilterChanged($event)"
          [statuses]="alertStatuses"
        >
        </app-log-filter>

        <div class="col-lg-7 col-md-10 col-sm-10 col-xs-12 input-group pull-right p-search">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="onSearch()">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!cleaningAlertLogs.length" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>

        <div class="box-body table-responsive" *ngIf="cleaningAlertLogs.length">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <ng-container
                  *ngTemplateOutlet="columnHeading; context: { id: 'WorkorderId', name: 'Cleaning Alert #' }"
                >
                </ng-container>
                <ng-container *ngTemplateOutlet="columnHeading; context: { id: 'Title', name: 'Name' }"> </ng-container>
                <ng-container *ngTemplateOutlet="columnHeading; context: { id: 'Status', name: 'Status' }">
                </ng-container>
                <ng-container *ngTemplateOutlet="columnHeading; context: { id: 'IsTaskBased', name: 'Mode' }">
                </ng-container>
                <ng-container
                  *ngTemplateOutlet="columnHeading; context: { id: 'FullfilledByWorkorderId', name: 'Fulfilled By' }"
                >
                </ng-container>
                <ng-container
                  *ngTemplateOutlet="columnHeading; context: { id: 'ScheduleStartDate', name: 'Planned Start' }"
                >
                </ng-container>
                <ng-container *ngTemplateOutlet="columnHeading; context: { id: 'FirstName', name: 'Assignee' }">
                </ng-container>
              </tr>
            </thead>
            <tbody
              *ngFor="
                let wo of cleaningAlertLogs
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: totalCount }
              "
            >
              <tr (click)="navigateToDetails(wo.WorkorderId)">
                <td>{{ wo.WorkorderId }}</td>
                <td>{{ wo.Title }}</td>
                <td>{{ wo.WorkorderStatus.Status }}</td>

                <td>{{ wo.IsTaskBased ? 'Task' : 'Full' }}</td>
                <td>{{ wo.FullfilledByWorkorderId }}</td>
                <td>{{ wo.ScheduleStartDate | date: 'MM/dd/yyyy hh:mm a' }}</td>
                <td>{{ wo.AssignedTo.FirstName }} {{ wo.AssignedTo.LastName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="cleaningAlertLogs.length && totalCount > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>
</section>

<ng-template #columnHeading let-id="id" let-name="name">
  <th class="table-sort-header" (click)="sortColumn(id, columnSortOrder)">
    {{ name }}
    <span *ngIf="selectedColumnName === id && columnSortOrder === 'DESC'"><i class="fa fa-sort-up"></i></span
    ><span *ngIf="selectedColumnName === id && columnSortOrder === 'ASC'"><i class="fa fa-sort-down"></i></span>
  </th>
</ng-template>
