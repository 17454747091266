import { StateMaintain } from 'src/app/core/models/common.model';

export enum WorkOrderStatus {
  OPEN = 'Open',
  DECLINED = 'Declined',
  ASSIGNED = 'Assigned',
  COMPLETE = 'Complete',
  CLOSED_NOT_COMPLETE = 'Closed – Not Complete',
  QC_APPROVED = 'QC Approved',
  QC_ASSIGNED = 'QC Assigned',
  QC_IN_PROGRESS = 'QC In Progress',
  AWAITING_QC = 'Awaiting QC',
  QC_REJECTED = 'QC Rejected',
  CANCEL = 'Cancel',
  IN_PROGRESS = 'Inprogress',
}

export enum WoStatus {
  Open = 1,
  Close = 2,
  QC_Pending = 3,
  Inprogress = 4,
  QC_Rejected = 5,
  QC_Approved = 6,
  No_Work_Completed = 7,
  Awaiting_QC = 8,
  No_QC = 9,
  QC_In_Progress = 10,
  Delay = 11,
  No_Agent_Available = 12,
  Awaiting_Supervisor = 13,
  Notify_Supervisor = 14,
  Return_To_Agent = 15,
  Awaiting_Delay = 16,
  Awaiting_Assignee = 17,
  Awaiting_Staff = 18,
  Assigned = 19,
  Declined = 20,
  Complete = 21,
  QC_Assigned = 22,
  Cancel = 23,
  Closed_Not_Complete = 24,
}

export interface IAdditionalCleaningAlerts {
  WorkorderId: number;
  Title: string;
  Status: string;
  IsTaskBased: boolean;
}

export interface FilteredLogsListState extends StateMaintain {
  statusListId: number;
  historyDays: number;
}
