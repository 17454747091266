import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injectable,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatRadioButton, MatRadioChange } from '@angular/material/radio';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CalendarEvent, CalendarEventAction, CalendarEventTitleFormatter, CalendarView } from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';
import moment from 'moment';
import { Subject } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { AuthState } from 'src/app/core/services/auth.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { CorporationModule } from '../../corporationmanagement/corporation/corporation.module';
import { InspectorLocationAssignmentService } from '../../inspectionmanagement/inspectorlocationassignment/components/inspectorlocationassignment.http.service';
import { FilterInputs } from '../../inspectionmanagement/inspectorlocationassignment/inspectorlocationassignment.module';
import { LocationService } from '../../staffscheduling/location/components/location.http.service';
import { LocationModule } from '../../staffscheduling/location/location.module';
import { ZoneModule } from '../../staffscheduling/zone/zone.module';
import { User } from '../../usermanagement/user/user.model';
import { BuildingModule } from '../../venuemanagement/building/building.module';
import { VenueService } from '../../venuemanagement/venue/components/venue.http.service';
import { VenueModule } from '../../venuemanagement/venue/venue.module';
import {
  CalModule,
  DisplaySlot,
  RecurringPattern,
  ScheduleIntervalMinutes,
  ScheduleTypeModule,
  ScheduleUser,
} from '../calendar.module';
import { CalendarService } from './calendar.http.service';
@Injectable({
  providedIn: 'root',
})
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  dayTooltip(_event: any): string {
    // console.log(event);
    return (_event = '');
  }
  weekTooltip(_event: any): string {
    // console.log(event);
    return (_event = '');
  }
}

@Component({
  exportAs: 'calendar',
  selector: 'calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'calendar.component.html',
  styleUrls: ['./calendar.scss'],
  providers: [
    CalendarService,
    UtilitiesService,
    DatePipe,
    LocationService,
    VenueService,
    InspectorLocationAssignmentService,
    { provide: CalendarEventTitleFormatter, useClass: CustomEventTitleFormatter },
  ],
})
export class CalendarComponent implements OnInit, OnDestroy {
  fileNameDialogRef?: MatDialogRef<CalendarComponent>;
  @ViewChild('tabGroup') tabGroup: any;
  @ViewChild('openDeletDialogTemplate', { static: true }) openDeletDialog: any;
  @ViewChild('openConfirmDeletDialogTemplate', { static: true }) openConfirmDeletDialog: any;
  @ViewChild('openConfirmAddscheduleDialogTemplate', { static: true }) openConfirmAddscheduleDialog: any;
  @ViewChild('modalContent') modalContent?: TemplateRef<any>;
  @ViewChild('mwlCalendarToday', { static: true }) mwlCalendarToday?: ElementRef<any>;

  selectedTabIndex = 0;
  isNewState = true;
  isReqComplete = false;

  scheduleForm: FormGroup;
  currentUser: User;
  IsVisible = false;
  selectedDay: any;
  selectedStartTime: any;

  objTemplateRef: any = TemplateRef;
  TodayDate = new Date();
  year = this.TodayDate.getFullYear();
  month = this.TodayDate.getMonth();
  firstDay: any = new Date(this.year, this.month, 1);
  lastDay: any = new Date(this.year, this.month + 1, 0);
  _viewDate: any;

  rangeOfRecurrenceRadioControl: any;
  IsEntireSeriesModifiedControl: any;

  rbJustThisOne = 1;
  rbTheEntireSeries = 0;

  colors: any = {
    red: {
      primary: '#ad2121',
      secondary: '#FAE3E3',
    },
    blue: {
      primary: '#1e90ff',
      secondary: '#D1E8FF',
    },
    yellow: {
      primary: '#e3bc08',
      secondary: '#FDF1BA',
    },
    orange: {
      primary: '#FF5733',
      secondary: '#E16044',
    },
    violet: {
      primary: '#7E44E1',
      secondary: '#9E6BF5',
    },
  };

  tZs?: { id: string; name: string };
  public timezone: any[] = [];

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();

  modalData?: {
    action: string;
    event: CalendarEvent;
  };

  isDeleteOptionVisible = true;
  actions: CalendarEventAction[] = [
    {
      label:
        '<i class="material-icons delete-icon" data-toggle="tooltip" data-placement="top" title="Delete">delete_forever</i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        if (this.authState.canAccess('CALENDAR_SCHEDULE_DELETE')) {
          this.getVenueById('deleteSchedule', null, null, event, null);
        } else {
          this.utilitiesService.openDeleteModal('Calendar', 'You do not have permission to delete schedule.');
        }
      },
    },
    {
      label:
        '<i class="material-icons view-schedule" data-toggle="tooltip" data-placement="top" title="View">remove_red_eye</i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        if (this.authState.canAccess('CALENDAR_SCHEDULE_VIEW')) {
          this.handleEvent('Edited', event);
        } else {
          this.utilitiesService.openDeleteModal('Calendar', 'You do not have permission to view schedule.');
        }
      },
    },
  ];

  refresh: Subject<any> = new Subject();
  activeDayIsOpen = false;

  // Daily
  SeperationCountDaily = 1;
  isrbDailyDays = 0;

  // Weekly
  SeperationCountWeekly = 0;

  // Monthly
  SeperationCountMonthly1 = 1;
  isrbMonthlyDays1 = 0;
  DayOfMonthMonthly1 = 1;

  SeperationCountMonthly2 = 1;
  DayOfMonthMonthly2 = 0;
  isrbMonthlyDays2 = 0;
  WeekOfMonthMonthly = 0;

  // Yearly
  SeperationCountYearly = 1;
  isrbYearlyMonths = 0;
  DayOfMonthYearly = 1;
  isrbYearlyDayOfMonth = 0;
  DayOfMonthYearly2 = 0;
  MonthOfYearYearly = 0;

  YearlyWeekOfMonth = 0;
  YearlyDayOfMonth = 0;
  YearlyMonthOfYear = 0;

  IsrbRecurrenceNoEndDateCheked = true;
  IsrbRecurrenceEndAfterChecked = false;
  IsrbRecurrenceEndbyChecked = false;
  IsrbDailyDaysChecked = true;
  rbDailyEveryWeekdays = false;
  rbMonthlyDays = true;
  rbMonthlyMonths = false;
  rbYearlyMonths = true;
  rbYearlyMonthWeekDay = false;

  IsrbJustThisOneChecked = true;
  IsrbTheEntireSeriesChecked = false;
  FullDayStartDate: any;
  FullDayEndDate: any;
  FullDayStartTime: any;
  FullDayEndTime: any;
  IsRecurringVisible = true;
  IsFullDayVisible = true;
  IsFullDayDatePanalVisible = true;
  IsrbJustThisOneVisible = true;

  scheduleDialogTitle = 'New Schedule';
  VenueStartTime: '';
  VenueTimezoneCurrentDate: '';
  occurrenceDetails = false;
  activeNavigationUrls: any;
  activeNavigationName: string;

  public currentStartTime = '';
  public currentEndTime = '';

  public corporations: CorporationModule[] = [];
  public locations: LocationModule[] = [];

  public Venues: VenueModule[] = [];

  public supervisors: User[] = [];
  public workers: User[] = [];
  public inspectors: User[] = [];

  public ScheduleTypes: ScheduleTypeModule[] = [];
  public calenderEventData: CalendarEvent[] = [];

  public calendardata: CalModule[] = [];
  public calendar: CalModule = new CalModule();

  public events: CalendarEvent[] = this.calenderEventData;

  recurringTypes = [
    { id: '1', name: 'Daily' },
    { id: '2', name: 'Weekly' },
    { id: '3', name: 'Monthly' },
    { id: '4', name: 'Yearly' },
  ];

  public IsEntireSeries = 0;
  eventTypeDisabled = true;
  corporationDisabled = true;
  venueDisabled = true;
  locationDisabled = true;
  supervisorDisabled = true;
  workerDisabled = true;
  StartTimeDisabled = false;
  EndTimeDisabled = false;
  timeIntervalDisabled = true;

  public isCASchedulingShow = false;
  public isQCSchedulingShow = false;
  public isInspectSchedulingShow = false;

  public isCA_ASSIGNMENT = false;
  public isQC_ASSIGNMENT = false;
  public isINS_ASSIGNMENT = false;

  public venueServerTime: any;
  public venueTimeZoneText: any;
  public eventIsRecurring = false;
  public IsRecurringDisabled = false;
  public isScheduleUpdate = false;

  public buildings: BuildingModule[] = [];
  public zones: ZoneModule[] = [];
  public filterBuildingId: number;
  public filterZoneId: number;
  public buildingDisabled = true;
  public zoneDisabled = true;
  public selectedEventType = '';
  public intervalMinutes: ScheduleIntervalMinutes[] = [];
  public intervalTimeSlotes: DisplaySlot[] = [];

  public isTimeIntervalDisabled = false;
  public isFullDayEventDisabled = false;
  public isRecurringCheckboxDisabled = false;
  maxDate: Date;

  constructor(
    private datePipe: DatePipe,
    private calanderModal: MatDialog,
    private calendarservice: CalendarService,
    public configuration: Configuration,
    private scheduleDialog: MatDialog,
    private openDeletDialogTemplate: MatDialog,
    private openConfirmDeletDialogTemplate: MatDialog,
    private openConfirmAddscheduleDialogTemplate: MatDialog,
    private utilitiesService: UtilitiesService,
    private fb: FormBuilder,
    private locationService: LocationService,
    private venueService: VenueService,
    private loaderService: LoaderService,
    private inspectorLocationAssignmentService: InspectorLocationAssignmentService,
    public authState: AuthState,
  ) {
    this.currentUser = this.authState.getCurrentUser();

    this.scheduleForm = this.fb.group({
      VenueControl: ['', [Validators.required]],
      titleControl: ['', [Validators.required]],
      descriptionControl: ['', []],
      supervisorControl: ['', []],
      workerControl: ['', []],
      isRecurringControl: ['', []],
      isBillableWorkControlControl: ['', []],
      durationControl: ['', []],
      StartTime: ['', [Validators.required]],
      EndTime: ['', []],
      dailyRadioControl: ['', []],
      dayControl: ['', []],
      everyWeekRadioControl: ['', []],

      weekControl: ['', []],
      sundaycheckboxControl: ['', []],
      mondaycheckboxControl: ['', []],
      thuesdaycheckboxControl: ['', []],
      wednesdaycheckboxControl: ['', []],
      thursdaycheckboxControl: ['', []],
      fridaycheckboxControl: ['', []],
      saturdaycheckboxControl: ['', []],

      dayRadioControl: ['', []],
      everyDayControl: ['', []],
      everyMonthControl: ['', []],
      WeekDaysControl: ['', []],
      monthlyDaysControl: ['', []],
      everyMonthsControl: ['', []],

      yearsControl: ['', []],
      monthsSelectControl: ['', []],
      monthsDayControl: ['', []],
      yearlyWeekRadioControl: ['', []],
      weekofControl: ['', []],
      WeeksSelectControl: ['', []],
      weeksOfMonthsControl: ['', []],

      picker1: ['', []],
      picker2: ['', []],
      occurencesControl: ['', []],
      rangeOfRecurrenceRadioControl: ['', []],

      isFullDayEventControler: ['', []],
      IsEntireSeriesModifiedControl: ['', []],
      timeZoneControl: ['', []],
    });

    this.calendar = new CalModule();
    this.calendar.Corporation = new CorporationModule();
    this.calendar.Venue = new VenueModule();
    this.calendar.Location = new LocationModule();
    this.calendar.Location.Building = new BuildingModule();
    this.calendar.Location.Zone = new ZoneModule();
    this.calendar.ScheduleType = new ScheduleTypeModule();
    this.calendar.Supervisor = new ScheduleUser();
    this.calendar.AssigneTo = new ScheduleUser();
    this.calendar.Inspector = new ScheduleUser();
    this.calendar.RecurringPattern = new RecurringPattern();

    this.configuration.pageTitle = '';
    this.configuration.pageTitle = 'Calendar';
    this.rangeOfRecurrenceRadioControl = '1';
    this.IsEntireSeriesModifiedControl = 'rbJustThisOne';

    this.calendar.IsRecurring = 0;
    this.calendar.IsBillableWork = 0;
    this.StartTimeDisabled = false;
    this.EndTimeDisabled = false;
  }

  ngOnInit() {
    this.getVenueDetails();
    this.getModulesData();
    this.setDefaultValues();
    this.addTimeZones(this.configuration.currentTimezone);

    if (
      this.authState.canAccess('CALENDAR_VIEW_MY_RECORDS') ||
      this.authState.canAccess('CALENDAR_VIEW_ALL_RECORDS') ||
      this.authState.canAccess('CALENDAR_VIEW_MY_LOCATIONS')
    ) {
      this.GetScheduleList();
    }

    this.activeNavigationUrls = document.getElementsByClassName('treeview');
    for (let i = 0; i < this.activeNavigationUrls.length; i++) {
      this.activeNavigationName = this.utilitiesService.removeInnerSpaces(this.activeNavigationUrls[i].textContent);

      if (this.activeNavigationName.toLowerCase() != 'calendar') {
        this.activeNavigationUrls[i].classList.remove('menu-open');
        this.activeNavigationUrls[i].lastElementChild.style.display = 'none';
        this.activeNavigationUrls[i].classList.remove('active');
      }
      if (this.activeNavigationName.toLowerCase() == 'calendar') {
        this.activeNavigationUrls[i].classList.add('menu-open');
        this.activeNavigationUrls[i].lastElementChild.style.display = 'block';
        this.activeNavigationUrls[i].classList.add('active');
      }
    }
  }

  ngOnDestroy() {
    this.openConfirmAddscheduleDialogTemplate.closeAll();
  }

  public addTimeZones(tz: string) {
    this.tZs = { id: tz, name: tz };
    this.timezone.push(this.tZs);
  }

  public getModulesData() {
    this.isCASchedulingShow = this.utilitiesService.checkVenueFeatureConfiguration('CA_CALENDAR_SCHEDULING');
    this.isQCSchedulingShow = this.utilitiesService.checkVenueFeatureConfiguration('QC_CALENDAR_SCHEDULING');
    this.isInspectSchedulingShow = this.utilitiesService.checkVenueFeatureConfiguration('INS_CALENDAR_SCHEDULING');

    this.isCA_ASSIGNMENT = this.utilitiesService.checkVenueFeatureConfiguration('CA_ASSIGNMENT_CALENDAR_SCHEDULING')
      ? false
      : true;
    this.isQC_ASSIGNMENT = this.utilitiesService.checkVenueFeatureConfiguration('QC_ASSIGNMENT_CALENDAR_SCHEDULING')
      ? false
      : true;
    this.isINS_ASSIGNMENT = this.utilitiesService.checkVenueFeatureConfiguration('INS_ASSIGNMENT_CALENDAR_SCHEDULING')
      ? false
      : true;
  }

  public getVenueById(
    flag: string,
    scheduleForm: NgForm,
    openDialogTemplateRef: TemplateRef<any>,
    event: any,
    scheduleDialogRef: TemplateRef<any>,
  ) {
    this.loaderService.display(true);
    const CPvenue = new VenueModule();
    CPvenue.VenueCPId = this.authState.AESEncryptText(this.authState.getStoredContextVenueId());

    this.venueService.GetSingle(CPvenue).subscribe(
      (data) => {
        if (data != null) {
          this.venueServerTime = this.datePipe.transform(data.VenueCurrrentTime, 'HH:mm');
          this.venueTimeZoneText = data.Timezone;
        }
        this.loaderService.display(false);

        //// The reason behind calling add/update schedule from here is to get the latest "venue server time" for validation.
        if (flag == 'addSchedule') {
          this.addSchedule(scheduleForm);
        } else if (flag == 'updateSchedule') {
          this.updateSchedule(scheduleForm);
        } else if (flag == 'OpenUpdateScheduleModel') {
          this.OpenUpdateScheduleModel(openDialogTemplateRef, event, scheduleDialogRef);
        } else if (flag == 'deleteSchedule') {
          this.OpenDeleteScheduleModel(event.id, this.openDeletDialog, event);
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'venueService GetVenueById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getVenueDetails() {
    const CPvenue = new VenueModule();
    CPvenue.VenueCPId = this.authState.AESEncryptText(this.authState.getStoredContextVenueId());

    this.venueService.GetSingle(CPvenue).subscribe(
      (data) => {
        if (data != null && data.VenueCurrrentTime != null) {
          const dt = new Date(data.VenueCurrrentTime);

          this.calendar.StartDate = dt;
          this.calendar.EndDate = dt;
          this.TodayDate = dt;

          const startTime = this.utilitiesService.getFormattedTime(dt);
          this.calendar.StartTime = startTime;

          dt.setMinutes(dt.getMinutes() + 30);
          const endTime = this.utilitiesService.getFormattedTime(dt);
          this.calendar.EndTime = endTime;
        }
      },
      (error) => {
        console.log(
          'venueService GetVenueById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public GetScheduleList() {
    this.loaderService.display(true);

    const searchfilter = {
      StartDate: this.datePipe.transform(this.firstDay, 'yyyy-MM-dd'),
      EndDate: this.datePipe.transform(this.lastDay, 'yyyy-MM-dd'),
    };

    this.calendarservice.GetAllScheduleByDate(JSON.stringify(searchfilter)).subscribe(
      (data) => {
        this.calenderEventData = [];
        this.calendardata = data;

        if (this.calendardata != null && this.calendardata.length > 0) {
          this.calendardata.forEach((item) => {
            let eventColor: any;
            eventColor = '';
            let eventClass: any;
            eventClass = [];

            if (item.ScheduleType.ScheduleTypeCode == 'QC') {
              eventColor = this.colors.yellow;
              eventClass.push('qc-class');
            } else if (item.ScheduleType.ScheduleTypeCode == 'CLEANING_ALERT') {
              eventColor = this.colors.red;
              eventClass.push('ca-class');
            } else if (item.ScheduleType.ScheduleTypeCode == 'EQUIPMENT_PM') {
              eventColor = this.colors.orange;
              eventClass.push('eq-class');
            } else if (item.ScheduleType.ScheduleTypeCode == 'PROJECT_WORK') {
              eventColor = this.colors.violet;
              eventClass.push('pw-class');
            } else {
              eventColor = this.colors.blue;
              eventClass.push('inspect-class');
            }

            if (item.RecurringDates != null && item.RecurringDates.length > 0) {
              item.RecurringDates.forEach((recurringDate) => {
                eventClass.push('recurringclass');
                let eventRow = {
                  id: item.ScheduleId,
                  start: new Date(
                    recurringDate.StartDate +
                      ' ' +
                      (item.StartTime != null ? item.StartTime.split('.')[0] : item.StartTime),
                  ),
                  endDate: this.isStartTimeGreaterThanEndTime(item.VenueStartTime, item.VenueEndTime)
                    ? this.getRecurringOverNightEndDate(recurringDate.StartDate)
                    : recurringDate.StartDate,
                  end: new Date(
                    recurringDate.StartDate + ' ' + (item.EndTime != null ? item.EndTime.split('.')[0] : item.EndTime),
                  ),
                  title: item.ScheduleTitle,
                  ScheduleDescription: item.ScheduleDescription,
                  venueName: item.Venue.VenueName,
                  locationName: item.Location.LocationName,
                  buildingName: item.Location.Building != null ? item.Location.Building.BuildingName : '',
                  zoneName: item.Location.Zone != null ? item.Location.Zone.ZoneName : '',
                  CorporationName: item.CorporationName,
                  SupervisorName: item.Supervisor.FirstName + ' ' + item.Supervisor.LastName,
                  WorkerName: item.AssigneTo.FirstName + ' ' + item.AssigneTo.LastName,
                  InspectorName: item.Inspector.FirstName + ' ' + item.Inspector.LastName,
                  StartTime: this.utilitiesService.convert24To12HrWithMeridian(item.StartTime),
                  EndTime: this.utilitiesService.convert24To12HrWithMeridian(item.EndTime),
                  IsRecurring: item.IsRecurring,
                  IsPreviousDate: recurringDate.IsPreviousDate,
                  IsFullDayEvent: item.IsFullDayEvent,
                  isNoEndDateEvent: !item.EndDate,
                  IsFullDayEndDate: new Date(item.EndDate),
                  allDay: item.IsFullDayEvent == 1 ? true : false,
                  scheduleTypeCode: item.ScheduleType.ScheduleTypeCode,
                  scheduleTypeName: item.ScheduleType.ScheduleTypeName,
                  VenueStartTime: item.VenueStartTime,
                  color: eventColor,
                  actions: this.actions,
                  draggable: false,
                  cssClass: eventClass.join(' '),
                  meta: '',
                };
                this.calenderEventData.push(eventRow);
              });
            } else if (item.RecurringDates == null && item.IsRecurring == 0) {
              eventClass.push('nonrecurringclass');
              let eventRow = {
                id: item.ScheduleId,
                start: new Date(
                  this.datePipe.transform(item.StartDate, 'yyyy-MM-dd') +
                    ' ' +
                    (item.StartTime != null ? item.StartTime.split('.')[0] : item.StartTime),
                ),
                end: new Date(
                  this.datePipe.transform(item.StartDate, 'yyyy-MM-dd') +
                    ' ' +
                    (item.EndTime != null ? item.EndTime.split('.')[0] : item.EndTime),
                ),
                endDate: new Date(
                  this.datePipe.transform(item.EndDate, 'yyyy-MM-dd') +
                    ' ' +
                    (item.EndTime != null ? item.EndTime.split('.')[0] : item.EndTime),
                ),
                title: item.ScheduleTitle,
                ScheduleDescription: item.ScheduleDescription,
                venueName: item.Venue.VenueName,
                locationName: item.Location.LocationName,
                buildingName: item.Location.Building != null ? item.Location.Building.BuildingName : '',
                zoneName: item.Location.Zone != null ? item.Location.Zone.ZoneName : '',
                CorporationName: item.CorporationName,
                SupervisorName: item.Supervisor.FirstName + ' ' + item.Supervisor.LastName,
                WorkerName: item.AssigneTo.FirstName + ' ' + item.AssigneTo.LastName,
                StartTime: this.utilitiesService.convert24To12HrWithMeridian(item.StartTime),
                InspectorName: item.Inspector.FirstName + ' ' + item.Inspector.LastName,
                EndTime: this.utilitiesService.convert24To12HrWithMeridian(item.EndTime),
                IsRecurring: item.IsRecurring,
                IsPreviousDate: item.IsSchedulePreviousDate,
                IsFullDayEvent: item.IsFullDayEvent,
                IsFullDayEndDate: new Date(item.EndDate),
                allDay: item.IsFullDayEvent == 1 ? true : false,
                scheduleTypeCode: item.ScheduleType.ScheduleTypeCode,
                scheduleTypeName: item.ScheduleType.ScheduleTypeName,
                VenueStartTime: item.VenueStartTime,
                color: eventColor,
                actions: this.actions,
                draggable: false,
                cssClass: eventClass.join(' '),
                meta: '',
              };
              this.calenderEventData.push(eventRow);
            }
          });
        }
        this.events = this.calenderEventData;
        this.loaderService.display(false);
        this.refresh.next();
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'calendarservice GetAllScheduleByDate Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.loaderService.display(false);

    if (isSameMonth(date, this.viewDate)) {
      this.loaderService.display(false);
      this.viewDate = date;
      this.selectedDay = this.datePipe.transform(this.viewDate, 'yyyy-MM-dd');
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.loaderService.display(false);
        this.activeDayIsOpen = true;
      }
    }
    this.IsrbJustThisOneChecked = true;
    this.IsrbTheEntireSeriesChecked = false;
  }

  public handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.occurrenceDetails = true;
  }

  public setView(view: CalendarView) {
    this.view = view;
  }

  public closeOpenMonthViewDay() {
    this._viewDate = this.datePipe.transform(this.viewDate, 'yyyy-MM-dd');
    const selectedDate = new Date(this._viewDate);

    const selectedDateMonth = selectedDate.getMonth();
    const selectedDateYear = selectedDate.getFullYear();

    const FirstDay1 = new Date(selectedDateYear, selectedDateMonth, 1);
    const LastDay2 = new Date(selectedDateYear, selectedDateMonth + 1, 0);
    this.firstDay = FirstDay1;
    this.lastDay = LastDay2;
    this.GetScheduleList();

    this.activeDayIsOpen = false;
  }

  public openCalander(templateRef: TemplateRef<any>) {
    this.calanderModal.open(templateRef, { width: 'auto' });
    this.GetScheduleList();
  }

  //// Open Schedule Popup ------------------------------------------------------
  public scheduleDialogOpen(templateRef: TemplateRef<any>) {
    this.getModulesData();
    this.eventIsRecurring = false;
    this.calendar = new CalModule();
    this.calendar.Venue = new VenueModule();
    this.calendar.Location = new LocationModule();
    this.calendar.Location.Building = new BuildingModule();
    this.calendar.Location.Zone = new ZoneModule();
    this.calendar.ScheduleType = new ScheduleTypeModule();
    this.calendar.Supervisor = new ScheduleUser();
    this.calendar.AssigneTo = new ScheduleUser();
    this.calendar.Inspector = new ScheduleUser();
    this.calendar.RecurringPattern = new RecurringPattern();
    this.scheduleDialogTitle = 'New Schedule';
    this.isScheduleUpdate = false;
    this.refresh.next();
    this.scheduleDialog.open(templateRef, { width: '800px', disableClose: true });
    this.getAllScheduleTypes();
    this.setDefaultValues();
    this.BindAllSupervisors();
  }

  //// Close Schedule Popup ------------------------------------------------------
  public scheduleDialogClose(popupReset: NgForm) {
    this.calendar = new CalModule();
    this.calendar.Venue = new VenueModule();
    this.calendar.Location = new LocationModule();
    this.calendar.Location.Building = new BuildingModule();
    this.calendar.Location.Zone = new ZoneModule();
    this.calendar.AssigneTo = new ScheduleUser();
    this.calendar.Supervisor = new ScheduleUser();
    this.calendar.Inspector = new ScheduleUser();
    this.calendar.ScheduleType = new ScheduleTypeModule();
    this.calendar.RecurringPattern = new RecurringPattern();

    this.buildingDisabled = true;
    this.zoneDisabled = true;
    this.locationDisabled = true;

    if (popupReset) {
      popupReset.reset();
    }
    this.setDefaultValues();

    this.scheduleForm.reset();
    this.GetScheduleList();

    this.scheduleDialog.closeAll();
    this.openConfirmDeletDialogTemplate.closeAll();
    this.openDeletDialogTemplate.closeAll();
    this.openConfirmAddscheduleDialogTemplate.closeAll();
  }

  public setDefaultValues() {
    this.calendar.ScheduleTitle = '';
    this.calendar.ScheduleDescription = '';
    this.calendar.CorporationId = 0;
    this.calendar.Venue.VenueId = 0;

    this.calendar.IsRecurring = 0;
    this.selectedTabIndex = 0;
    this.SeperationCountMonthly2 = 1;
    this.SeperationCountDaily = 1;
    this.DayOfMonthMonthly1 = 1;
    this.SeperationCountMonthly1 = 1;
    this.SeperationCountYearly = 1;
    this.calendar.RecurringPattern.SeperationCount = 1;
    this.DayOfMonthYearly = 1;
    this.calendar.RecurringPattern.Occurences = 10;

    this.isrbDailyDays = 1;
    this.isrbMonthlyDays1 = 1;
    this.isrbYearlyMonths = 1;
    this.IsVisible = false;
    this.isNewState = true;
    this.IsrbRecurrenceNoEndDateCheked = true;
    this.IsrbRecurrenceEndAfterChecked = false;
    this.IsrbRecurrenceEndbyChecked = false;
    this.IsrbDailyDaysChecked = true;
    this.rbDailyEveryWeekdays = false;
    this.rbMonthlyDays = true;
    this.rbMonthlyMonths = false;
    this.rbYearlyMonths = true;
    this.rbYearlyMonthWeekDay = false;

    this.IsFullDayVisible = true;
    this.IsRecurringVisible = true;
    this.IsrbJustThisOneChecked = true;
    this.IsrbTheEntireSeriesChecked = false;
    this.IsEntireSeriesModifiedControl = 'rbJustThisOne';
    this.activeDayIsOpen = false;
    this.IsFullDayDatePanalVisible = true;
    this.rangeOfRecurrenceRadioControl = '1';
    this.calendar.StartDate = this.TodayDate;
    this.setNonRecurringMaxDate();
    this.calendar.EndDate = this.TodayDate;

    this.StartTimeDisabled = false;
    this.EndTimeDisabled = false;

    this.calendar.RecurringPattern.Sunday = 0;
    this.calendar.RecurringPattern.Monday = 0;
    this.calendar.RecurringPattern.Tuesday = 0;
    this.calendar.RecurringPattern.Wednesday = 0;
    this.calendar.RecurringPattern.Thusday = 0;
    this.calendar.RecurringPattern.Friday = 0;
    this.calendar.RecurringPattern.Saturday = 0;

    this.calendar.RecurringPattern.DayOfMonth = 0;
    this.calendar.RecurringPattern.WeekOfMonth = 0;
    this.calendar.RecurringPattern.MonthOfYear = 0;

    this.isReqComplete = false;

    this.WeekOfMonthMonthly = 0;
    this.DayOfMonthMonthly2 = 0;
    this.MonthOfYearYearly = 0;
    this.YearlyWeekOfMonth = 0;
    this.YearlyDayOfMonth = 0;
    this.YearlyMonthOfYear = 0;
    this.getVenueDetails();
    this.IsRecurringDisabled = false;
    this.isScheduleUpdate = false;

    this.calendar.CorporationId = this.authState.getStoredContextCorporationId();
    this.calendar.CorporationName = this.authState.getStoredCorporationName();

    this.calendar.Venue.VenueId = this.authState.getStoredContextVenueId();
    this.calendar.Venue.VenueName = this.authState.getStoredVenueName();
    this.selectedEventType = '';

    this.calendar.IsBetweenSelectedTime = 0;
    this.intervalTimeSlotes = [];
    this.timeIntervalDisabled = true;
    this.isTimeIntervalDisabled = false;
    this.isFullDayEventDisabled = false;
    this.isRecurringCheckboxDisabled = false;
    this.buildings = [];
    this.filterBuildingId = null;
    this.zones = [];
    this.filterZoneId = null;
    this.locations = [];
  }

  //// Get Schedule Types ------------------------------------------------------
  public getAllScheduleTypes() {
    this.loaderService.display(true);
    const payload = {};

    this.calendarservice.GetAllScheduleTypes(JSON.stringify(payload)).subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          this.ScheduleTypes = data;
          if (!this.isCASchedulingShow) {
            let CAindex = this.ScheduleTypes.findIndex((e) => e.ScheduleTypeCode == 'CLEANING_ALERT');
            if (CAindex > -1) {
              this.ScheduleTypes.splice(CAindex, 1);
            }
          }

          if (!this.isQCSchedulingShow) {
            let QCindex = this.ScheduleTypes.findIndex((e) => e.ScheduleTypeCode == 'QC');
            if (QCindex > -1) {
              this.ScheduleTypes.splice(QCindex, 1);
            }
          }

          if (!this.isInspectSchedulingShow) {
            let inspectIndex = this.ScheduleTypes.findIndex((e) => e.ScheduleTypeCode == 'INSPECT');
            if (inspectIndex > -1) {
              this.ScheduleTypes.splice(inspectIndex, 1);
            }
          }
          this.eventTypeDisabled = false;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'calendarservice GetAllScheduleTypes Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public changeScheduleType($event: number) {
    this.calendar.ScheduleType.ScheduleTypeId = $event;
    this.calendar.ScheduleType.ScheduleTypeCode = '';
    this.calendar.IsBillableWork = 0;
    if (this.calendar.ScheduleType.ScheduleTypeId) {
      this.locations = [];
      this.calendar.Location.LocationId = null;
      this.locationDisabled = true;

      this.buildings = [];
      this.filterBuildingId = null;
      this.buildingDisabled = true;

      this.zones = [];
      this.filterZoneId = null;
      this.zoneDisabled = true;

      this.supervisors = [];
      this.inspectors = [];
      this.workers = [];

      this.selectedEventType = '';
      let obj = this.ScheduleTypes.filter((st) => st.ScheduleTypeId == this.calendar.ScheduleType.ScheduleTypeId);
      if (obj && obj[0]) {
        this.calendar.ScheduleType.ScheduleTypeCode = obj[0].ScheduleTypeCode;

        if (obj[0].ScheduleTypeCode == 'CLEANING_ALERT' || obj[0].ScheduleTypeCode == 'QC') {
          this.selectedEventType = 'CLEAN_QC';
          this.getAllBuildings(
            this.authState.getStoredContextCorporationId(),
            this.authState.getStoredContextVenueId(),
            this.selectedEventType,
          );
        } else if (obj[0].ScheduleTypeCode == 'INSPECT') {
          this.selectedEventType = obj[0].ScheduleTypeCode;
          this.getAllBuildings(
            this.authState.getStoredContextCorporationId(),
            this.authState.getStoredContextVenueId(),
            this.selectedEventType,
          );
        } else if (obj[0].ScheduleTypeCode == 'EQUIPMENT_PM') {
          this.selectedEventType = obj[0].ScheduleTypeCode;
          this.getAllBuildings(
            this.authState.getStoredContextCorporationId(),
            this.authState.getStoredContextVenueId(),
            this.selectedEventType,
          );
        } else if (obj[0].ScheduleTypeCode == 'PROJECT_WORK') {
          this.selectedEventType = obj[0].ScheduleTypeCode;
          this.getAllBuildings(
            this.authState.getStoredContextCorporationId(),
            this.authState.getStoredContextVenueId(),
            this.selectedEventType,
          );
        }

        this.getAllLocations(
          this.calendar.CorporationId,
          this.calendar.Venue.VenueId,
          this.filterBuildingId,
          this.filterZoneId,
          this.calendar.ScheduleType.ScheduleTypeId,
        );

        this.getAllSupervisorsWorkersInspectors(
          this.calendar.ScheduleType.ScheduleTypeCode,
          this.calendar.CorporationId,
          this.calendar.Venue.VenueId,
          this.calendar.Location.LocationId,
        );
      }
    } else {
      this.calendar.ScheduleType.ScheduleTypeId = null;
      this.calendar.ScheduleType.ScheduleTypeCode = '';

      this.locations = [];
      this.calendar.Location.LocationId = null;
      this.locationDisabled = true;

      this.supervisors = [];
      this.inspectors = [];
      this.workers = [];

      this.buildings = [];
      this.filterBuildingId = null;
      this.buildingDisabled = true;

      this.zones = [];
      this.filterZoneId = null;
      this.zoneDisabled = true;
    }
  }

  //// Get Buildings ------------------------------------------------------
  public getAllBuildings(corporationId: number, venueId: number, eventType: string) {
    this.loaderService.display(true);

    const payload = new FilterInputs();
    payload.FilterIdentifier = eventType;
    payload.ScreenCode = 'SCHEDULE_SCREEN';

    payload.Corporations = [];
    let corpObj: CorporationModule = new CorporationModule();
    corpObj.CorporationId = corporationId;
    payload.Corporations.push(corpObj);

    payload.Venues = [];
    let veuneObj: VenueModule = new VenueModule();
    veuneObj.VenueId = venueId;
    payload.Venues.push(veuneObj);

    this.inspectorLocationAssignmentService.GetBuildingList(payload).subscribe(
      (data) => {
        this.loaderService.display(false);
        if (data != null && data.length > 0) {
          this.buildings = data;
          this.buildingDisabled = false;

          let scheduleBldgArray = [];
          for (let i = 0; i < this.buildings.length; i++) {
            let bldgObj: BuildingModule = new BuildingModule();
            bldgObj.BuildingId = this.buildings[i].BuildingId;
            bldgObj.BuildingName = this.buildings[i].BuildingName;
            scheduleBldgArray.push(bldgObj);
          }
          this.getAllZones(
            this.calendar.CorporationId,
            this.calendar.Venue.VenueId,
            scheduleBldgArray,
            this.selectedEventType,
          );
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'inspectorLocationAssignmentService GetBuildingList call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  public changeBuilding($event: number) {
    this.filterBuildingId = $event;
    if (this.filterBuildingId) {
      this.locations = [];
      this.calendar.Location.LocationId = null;
      this.locationDisabled = true;

      this.zones = [];
      this.zoneDisabled = true;
      this.filterZoneId = null;

      this.getAllZones(
        this.calendar.CorporationId,
        this.calendar.Venue.VenueId,
        this.filterBuildingId,
        this.selectedEventType,
      );
      this.getAllLocations(
        this.calendar.CorporationId,
        this.calendar.Venue.VenueId,
        this.filterBuildingId,
        this.filterZoneId,
        this.calendar.ScheduleType.ScheduleTypeId,
      );
    } else {
      this.locations = [];
      this.calendar.Location.LocationId = null;
      this.locationDisabled = true;

      this.zones = [];
      this.zoneDisabled = true;
      this.filterZoneId = null;
      this.filterBuildingId = null;

      if (this.buildings != null && this.buildings.length > 0) {
        let bldgArray = [];
        for (let i = 0; i < this.buildings.length; i++) {
          let bldgObj: BuildingModule = new BuildingModule();
          bldgObj.BuildingId = this.buildings[i].BuildingId;
          bldgObj.BuildingName = this.buildings[i].BuildingName;
          bldgArray.push(bldgObj);
        }
        this.getAllZones(this.calendar.CorporationId, this.calendar.Venue.VenueId, bldgArray, this.selectedEventType);
      }
      this.getAllLocations(
        this.calendar.CorporationId,
        this.calendar.Venue.VenueId,
        this.filterBuildingId,
        this.filterZoneId,
        this.calendar.ScheduleType.ScheduleTypeId,
      );
    }
  }

  //// Get Zones ------------------------------------------------------
  public getAllZones(corporationId: number, venueId: number, buildingId: any, eventType: string) {
    this.loaderService.display(true);

    const payload = new FilterInputs();
    payload.FilterIdentifier = eventType;
    payload.ScreenCode = 'SCHEDULE_SCREEN';

    payload.Corporations = [];
    let corpObj: CorporationModule = new CorporationModule();
    corpObj.CorporationId = corporationId;
    payload.Corporations.push(corpObj);

    payload.Venues = [];
    let veuneObj: VenueModule = new VenueModule();
    veuneObj.VenueId = venueId;
    payload.Venues.push(veuneObj);

    if (buildingId) {
      if (buildingId.length > 0) {
        payload.Buildings = [];
        payload.Buildings = buildingId;
      } else {
        payload.Buildings = [];
        let buildingObj: BuildingModule = new BuildingModule();
        buildingObj.BuildingId = buildingId;
        payload.Buildings.push(buildingObj);
      }
    } else {
      payload.Buildings = [];
    }

    this.inspectorLocationAssignmentService.GetZoneList(payload).subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          this.zones = data;
          this.zoneDisabled = false;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'inspectorLocationAssignmentService GetZoneList call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  public changeZone($event: number) {
    this.filterZoneId = $event;
    if (this.filterZoneId) {
      this.locations = [];
      this.calendar.Location.LocationId = null;
      this.locationDisabled = true;

      this.getAllLocations(
        this.calendar.CorporationId,
        this.calendar.Venue.VenueId,
        this.filterBuildingId,
        this.filterZoneId,
        this.calendar.ScheduleType.ScheduleTypeId,
      );
    } else {
      this.filterZoneId = null;

      this.locations = [];
      this.calendar.Location.LocationId = null;
      this.locationDisabled = true;

      this.getAllLocations(
        this.calendar.CorporationId,
        this.calendar.Venue.VenueId,
        this.filterBuildingId,
        this.filterZoneId,
        this.calendar.ScheduleType.ScheduleTypeId,
      );
    }
  }

  //// Get Locations ------------------------------------------------------
  public getAllLocations(
    corporationId: number,
    venueId: number,
    buildingId: number,
    zoneId: number,
    eventType: number,
  ) {
    this.loaderService.display(true);

    const payload = new FilterInputs();
    payload.FilterIdentifier = this.selectedEventType;
    payload.ScreenCode = 'SCHEDULE_SCREEN';
    payload.ScheduleTypeId = eventType;
    payload.CorporationId = corporationId;
    payload.VenueId = venueId;

    if (buildingId) {
      payload.Buildings = [];
      let buildingObj: BuildingModule = new BuildingModule();
      buildingObj.BuildingId = buildingId;
      payload.Buildings.push(buildingObj);
    } else {
      payload.Buildings = [];
    }

    if (zoneId) {
      payload.Zones = [];
      let zoneObj: ZoneModule = new ZoneModule();
      zoneObj.ZoneId = zoneId;
      payload.Zones.push(zoneObj);
    } else {
      payload.Zones = [];
    }

    this.locationService.GetAllLocationsByVenue(payload).subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          this.locations = data;
          this.locationDisabled = false;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationService GetAllLocationsByVenue Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public changeLocation($event: number) {
    this.calendar.Location.LocationId = $event;
    if (this.calendar.Location.LocationId) {
      this.supervisors = [];
      this.inspectors = [];

      if (
        this.calendar.ScheduleType.ScheduleTypeCode == 'CLEANING_ALERT' ||
        this.calendar.ScheduleType.ScheduleTypeCode == 'QC' ||
        this.calendar.ScheduleType.ScheduleTypeCode == 'INSPECT'
      ) {
        this.workers = [];

        this.getAllSupervisorsWorkersInspectors(
          this.calendar.ScheduleType.ScheduleTypeCode,
          this.calendar.CorporationId,
          this.calendar.Venue.VenueId,
          this.calendar.Location.LocationId,
        );
      }
    } else {
      if (
        this.calendar.ScheduleType.ScheduleTypeCode != 'EQUIPMENT_PM' &&
        this.calendar.ScheduleType.ScheduleTypeCode != 'PROJECT_WORK'
      ) {
        this.supervisors = [];
        this.inspectors = [];
        this.workers = [];
      }
    }
  }

  //// Get Users ------------------------------------------------------
  public getAllSupervisorsWorkersInspectors(
    eventType: string,
    corporationId: number,
    venueId: number,
    locationId: number,
  ) {
    let payload = { EventTypeCode: eventType, CorporationId: corporationId, VenueId: venueId, LocationId: locationId };

    // Get Inspector
    if (eventType == 'INSPECT' && corporationId && venueId && locationId) {
      this.loaderService.display(true);
      this.calendarservice.GetAllSupervisorsWorkersInspectors(JSON.stringify(payload)).subscribe(
        (data) => {
          if (data != null && data.length > 0) {
            data.forEach(function (value) {
              value.FirstName = value.FirstName + ' ' + value.LastName;
            });
            this.inspectors = data;
          }
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'calendarservice GetAllSupervisorsWorkersInspectors Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
          this.loaderService.display(false);
        },
      );
    }

    // Get Supervisor
    if (eventType == 'QC' && corporationId != null && venueId != null && locationId != null) {
      this.loaderService.display(true);
      this.calendarservice.GetAllSupervisorsWorkersInspectors(JSON.stringify(payload)).subscribe(
        (data) => {
          if (data != null && data.length > 0) {
            data.forEach(function (value) {
              value.FirstName = value.FirstName + ' ' + value.LastName;
            });
            this.supervisors = data;
          }
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'calendarservice GetAllSupervisorsWorkersInspectors Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
          this.loaderService.display(false);
        },
      );
    }

    // Get Worker
    if (eventType == 'CLEANING_ALERT' && corporationId != null && venueId != null && locationId != null) {
      this.loaderService.display(true);
      this.calendarservice.GetAllSupervisorsWorkersInspectors(JSON.stringify(payload)).subscribe(
        (data) => {
          if (data != null && data.length > 0) {
            data.forEach(function (value) {
              value.FirstName = value.FirstName + ' ' + value.LastName;
            });
            this.workers = data;
          }
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'calendarservice GetAllSupervisorsWorkersInspectors Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
          this.loaderService.display(false);
        },
      );
    }

    if ((eventType == 'EQUIPMENT_PM' || eventType == 'PROJECT_WORK') && corporationId != null && venueId != null) {
      this.loaderService.display(true);
      this.calendarservice.GetAllSupervisorsWorkersInspectors(JSON.stringify(payload)).subscribe(
        (data) => {
          if (data != null && data.length > 0) {
            data.forEach(function (value) {
              value.FirstName = value.FirstName + ' ' + value.LastName;
            });
            this.workers = data;
          }
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'calendarservice GetAllSupervisorsWorkersInspectors Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
          this.loaderService.display(false);
        },
      );
    }
  }

  public IsTimeIntervalEvent($event: any) {
    if ($event.checked) {
      this.calendar.IsFullDayEvent = 0;
      if (!this.isNewState && this.FullDayEndDate === undefined && this.FullDayEndDate === undefined) {
        this.StartTimeDisabled = false;
        this.EndTimeDisabled = false;
      } else {
        this.IsRecurringVisible = true;
        this.StartTimeDisabled = false;
        this.EndTimeDisabled = false;
      }

      if (this.calendar.ScheduleId) {
        this.isFullDayEventDisabled = true;
        this.calendar.IsFullDayEvent = 0;
        this.isRecurringCheckboxDisabled = true;
      } else {
        this.isFullDayEventDisabled = true;
        this.calendar.IsFullDayEvent = 0;
      }
      this.getAllIntervalMinutes();
    } else {
      this.calendar.IsBetweenSelectedTime = null;
      this.intervalMinutes = [];
      this.calendar.IntervalMinutes = null;
      this.timeIntervalDisabled = true;
      this.intervalTimeSlotes = [];

      if (this.calendar.ScheduleId) {
        this.isFullDayEventDisabled = true;
        this.isRecurringCheckboxDisabled = true;
        this.calendar.IsFullDayEvent = 0;
      } else {
        this.isFullDayEventDisabled = false;
        this.isRecurringCheckboxDisabled = false;
        this.calendar.IsFullDayEvent = 0;
      }
    }
  }

  public getAllIntervalMinutes() {
    this.loaderService.display(true);

    this.calendarservice.GetTimeIntervalList().subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          this.intervalMinutes = data;
          this.timeIntervalDisabled = false;

          if (this.calendar.ScheduleId) {
            let obj = this.intervalMinutes.filter((m) => m.ScheduleIntervalValue == this.calendar.IntervalMinutes);
            if (obj && obj.length > 0) {
              this.calendar.IntervalMinutes = obj[0].ScheduleIntervalValue;
            }
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'calendarservice GetTimeIntervalList Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public onChangerangeOfRecurrenceRadioControl(mrChange: MatRadioChange) {
    this.rangeOfRecurrenceRadioControl = mrChange.value;
    this.BindAllSupervisors();
  }

  public onTabChanged(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
    this.calendar.RecurringPattern.RecurringTypeId = this.selectedTabIndex + 1;
  }

  public BindAllSupervisors() {
    if (
      this.calendar.StartDate !== null &&
      this.calendar.StartDate !== '' &&
      this.calendar.StartTime !== null &&
      this.calendar.StartTime !== '' &&
      this.calendar.EndTime !== null &&
      this.calendar.EndTime !== ''
    ) {
    } else {
      this.workers = [];
    }

    if (this.calendar.IsRecurring && this.IsrbRecurrenceNoEndDateCheked) {
      this.calendar.EndDate = null;
    }
  }

  public getValueFromSelectedTab(selectedTabIndex: number) {
    this.calendar.RecurringPattern.MonthOfYear = 0;
    this.calendar.RecurringPattern.WeekOfMonth = 0;
    this.calendar.RecurringPattern.DayOfMonth = 0;

    switch (selectedTabIndex) {
      case 0: {
        this.calendar.RecurringPattern.RecurringTypeName = 'Daily';
        this.calendar.RecurringPattern.RecurringTypeId = this.selectedTabIndex + 1;
        if (this.isrbDailyDays === 1) {
          this.calendar.RecurringPattern.SeperationCount = this.SeperationCountDaily;
          this.calendar.RecurringPattern.Sunday = 0;
          this.calendar.RecurringPattern.Monday = 0;
          this.calendar.RecurringPattern.Tuesday = 0;
          this.calendar.RecurringPattern.Wednesday = 0;
          this.calendar.RecurringPattern.Thusday = 0;
          this.calendar.RecurringPattern.Friday = 0;
          this.calendar.RecurringPattern.Saturday = 0;
        } else {
          this.calendar.RecurringPattern.SeperationCount = 0;
          this.calendar.RecurringPattern.Sunday = 0;
          this.calendar.RecurringPattern.Monday = 1;
          this.calendar.RecurringPattern.Tuesday = 1;
          this.calendar.RecurringPattern.Wednesday = 1;
          this.calendar.RecurringPattern.Thusday = 1;
          this.calendar.RecurringPattern.Friday = 1;
          this.calendar.RecurringPattern.Saturday = 0;
        }
        break;
      }
      case 1: {
        this.calendar.RecurringPattern.RecurringTypeName = 'Weekly';
        this.calendar.RecurringPattern.RecurringTypeId = this.selectedTabIndex + 1;
        this.calendar.RecurringPattern.Sunday = this.calendar.RecurringPattern.Sunday ? 1 : 0;
        this.calendar.RecurringPattern.Monday = this.calendar.RecurringPattern.Monday ? 1 : 0;
        this.calendar.RecurringPattern.Tuesday = this.calendar.RecurringPattern.Tuesday ? 1 : 0;
        this.calendar.RecurringPattern.Wednesday = this.calendar.RecurringPattern.Wednesday ? 1 : 0;
        this.calendar.RecurringPattern.Thusday = this.calendar.RecurringPattern.Thusday ? 1 : 0;
        this.calendar.RecurringPattern.Friday = this.calendar.RecurringPattern.Friday ? 1 : 0;
        this.calendar.RecurringPattern.Saturday = this.calendar.RecurringPattern.Saturday ? 1 : 0;
        break;
      }
      case 2: {
        this.calendar.RecurringPattern.RecurringTypeName = 'Monthly';
        this.calendar.RecurringPattern.RecurringTypeId = this.selectedTabIndex + 1;
        this.calendar.RecurringPattern.Sunday = 0;
        this.calendar.RecurringPattern.Monday = 0;
        this.calendar.RecurringPattern.Tuesday = 0;
        this.calendar.RecurringPattern.Wednesday = 0;
        this.calendar.RecurringPattern.Thusday = 0;
        this.calendar.RecurringPattern.Friday = 0;
        this.calendar.RecurringPattern.Saturday = 0;

        if (this.isrbMonthlyDays1 === 1) {
          this.calendar.RecurringPattern.SeperationCount = this.SeperationCountMonthly1;
          this.calendar.RecurringPattern.DayOfMonth = this.DayOfMonthMonthly1;
        } else if (this.isrbMonthlyDays2 === 1) {
          this.calendar.RecurringPattern.SeperationCount = this.SeperationCountMonthly2;
          this.calendar.RecurringPattern.WeekOfMonth = this.WeekOfMonthMonthly;
          this.calendar.RecurringPattern.Sunday = 0;
          this.calendar.RecurringPattern.Monday = 0;
          this.calendar.RecurringPattern.Tuesday = 0;
          this.calendar.RecurringPattern.Wednesday = 0;
          this.calendar.RecurringPattern.Thusday = 0;
          this.calendar.RecurringPattern.Friday = 0;
          this.calendar.RecurringPattern.Saturday = 0;
          switch (this.DayOfMonthMonthly2) {
            case 1: {
              this.calendar.RecurringPattern.Sunday = 1;
              break;
            }
            case 2: {
              this.calendar.RecurringPattern.Monday = 1;
              break;
            }
            case 3: {
              this.calendar.RecurringPattern.Tuesday = 1;
              break;
            }
            case 4: {
              this.calendar.RecurringPattern.Wednesday = 1;
              break;
            }
            case 5: {
              this.calendar.RecurringPattern.Thusday = 1;
              break;
            }
            case 6: {
              this.calendar.RecurringPattern.Friday = 1;
              break;
            }
            case 7: {
              this.calendar.RecurringPattern.Saturday = 1;
              break;
            }
          }
        }
        break;
      }
      case 3: {
        this.calendar.RecurringPattern.RecurringTypeName = 'Yearly';
        this.calendar.RecurringPattern.RecurringTypeId = this.selectedTabIndex + 1;
        this.calendar.RecurringPattern.SeperationCount = this.SeperationCountYearly;
        this.calendar.RecurringPattern.Sunday = 0;
        this.calendar.RecurringPattern.Monday = 0;
        this.calendar.RecurringPattern.Tuesday = 0;
        this.calendar.RecurringPattern.Wednesday = 0;
        this.calendar.RecurringPattern.Thusday = 0;
        this.calendar.RecurringPattern.Friday = 0;
        this.calendar.RecurringPattern.Saturday = 0;

        if (this.isrbYearlyMonths === 1) {
          this.calendar.RecurringPattern.MonthOfYear = this.MonthOfYearYearly;
          this.calendar.RecurringPattern.DayOfMonth = this.DayOfMonthYearly;
        } else {
          this.calendar.RecurringPattern.Sunday = 0;
          this.calendar.RecurringPattern.Monday = 0;
          this.calendar.RecurringPattern.Tuesday = 0;
          this.calendar.RecurringPattern.Wednesday = 0;
          this.calendar.RecurringPattern.Thusday = 0;
          this.calendar.RecurringPattern.Friday = 0;
          this.calendar.RecurringPattern.Saturday = 0;

          this.calendar.RecurringPattern.WeekOfMonth = this.YearlyWeekOfMonth;
          this.calendar.RecurringPattern.MonthOfYear = this.YearlyMonthOfYear;
          switch (this.YearlyDayOfMonth) {
            case 1: {
              this.calendar.RecurringPattern.Sunday = 1;
              break;
            }
            case 2: {
              this.calendar.RecurringPattern.Monday = 1;
              break;
            }
            case 3: {
              this.calendar.RecurringPattern.Tuesday = 1;
              break;
            }
            case 4: {
              this.calendar.RecurringPattern.Wednesday = 1;
              break;
            }
            case 5: {
              this.calendar.RecurringPattern.Thusday = 1;
              break;
            }
            case 6: {
              this.calendar.RecurringPattern.Friday = 1;
              break;
            }
            case 7: {
              this.calendar.RecurringPattern.Saturday = 1;
              break;
            }
          }
        }
        break;
      }
    }
  }

  public CheckValidation(noValidation: any) {
    if (this.calendar.CorporationId === 0 || this.calendar.CorporationId === null) {
      this.utilitiesService.openWarningModal('Calendar', 'Corporation is required.');
      this.loaderService.display(false);
      return false;
    }

    if (this.calendar.Venue.VenueId === 0 || this.calendar.Venue.VenueId === null) {
      this.utilitiesService.openWarningModal('Calendar', 'Venue is required.');
      this.loaderService.display(false);
      return false;
    }

    if (this.calendar.ScheduleTitle === null || this.calendar.ScheduleTitle === '') {
      this.utilitiesService.openWarningModal('Calendar', 'Title is required.');
      this.loaderService.display(false);
      return false;
    }

    if (this.calendar.RecurringPattern.Occurences < 0) {
      this.utilitiesService.openWarningModal('Calendar', 'Recurrence type is not valid.');
      this.loaderService.display(false);
      return false;
    }

    if (this.calendar.StartTime === null || this.calendar.StartTime === '') {
      this.utilitiesService.openWarningModal('Calendar', 'Start Time is required.');
      this.loaderService.display(false);
      return false;
    }

    if (this.calendar.EndTime === null || this.calendar.EndTime === '') {
      this.utilitiesService.openWarningModal('Calendar', 'End Time is required.');
      this.loaderService.display(false);
      return false;
    }

    if (this.calendar.StartDate === null || this.calendar.StartDate === '') {
      this.utilitiesService.openWarningModal('Calendar', 'Start date is required.');
      this.loaderService.display(false);
      return false;
    }

    if (this.rangeOfRecurrenceRadioControl === '3' || this.rangeOfRecurrenceRadioControl === 3) {
      if (this.calendar.EndDate === null || this.calendar.EndDate === '') {
        this.utilitiesService.openWarningModal('Calendar', 'End date is required.');
        this.loaderService.display(false);
        return false;
      }
    } else if (
      this.calendar.IsRecurring === 1 &&
      this.isNewState === true &&
      (this.rangeOfRecurrenceRadioControl === '1' || this.rangeOfRecurrenceRadioControl === '2')
    ) {
      this.calendar.EndDate = '';
    }

    if (this.calendar.IsFullDayEvent !== 1 && this.calendar.StartTime === this.calendar.EndTime) {
      this.utilitiesService.openWarningModal('Calendar', 'Start Time and End Time should not be equal.');
      this.loaderService.display(false);
      return false;
    } else if (
      this.calendar.IsFullDayEvent === 1 &&
      this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd') ===
        this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd')
    ) {
      this.utilitiesService.openWarningModal(
        'Calendar',
        'Sorry, you cannot create a full day event schedule for current day.',
      );
      this.loaderService.display(false);
      return false;
    } else if (
      this.calendar.EndDate === this.calendar.StartDate &&
      !noValidation &&
      this.calendar.StartTime > this.calendar.EndTime &&
      this.calendar.IsRecurring === 0
    ) {
      this.utilitiesService.openWarningModal('Calendar', 'Schedule start time should not be greater than end time.');
      this.loaderService.display(false);
      return false;
    } else if (
      !noValidation &&
      this.calendar.EndDate === this.calendar.StartDate &&
      this.calendar.StartTime > this.calendar.EndTime &&
      this.calendar.IsRecurring === 1 &&
      (this.calendar.EndDate !== null || this.calendar.EndDate !== '') &&
      this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd') ===
        this.datePipe.transform(this.calendar.EndDate, 'yyyy-MM-dd')
    ) {
      this.utilitiesService.openWarningModal('Calendar', 'Schedule start time should not be greater than end time.');
      this.loaderService.display(false);
      return false;
    }

    if (
      this.calendar.StartDate !== null ||
      (this.calendar.StartDate !== '' && this.calendar.EndDate !== null) ||
      this.calendar.EndDate !== ''
    ) {
      const sDate = Date.parse(this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd'));
      const eDate = Date.parse(this.datePipe.transform(this.calendar.EndDate, 'yyyy-MM-dd'));
      if (sDate > eDate) {
        this.utilitiesService.openWarningModal('Calendar', 'Schedule end date should not be smaller than start date.');
        this.loaderService.display(false);
        return false;
      }
    }

    if (this.calendar.IsRecurring === 1 && (this.IsEntireSeries !== 0 || this.isNewState === true)) {
      switch (this.selectedTabIndex) {
        case 0:
          if (this.isrbDailyDays === 1) {
            if (this.SeperationCountDaily === 0 || this.SeperationCountDaily === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a day.');
              this.loaderService.display(false);
              return false;
            }
          }
          break;
        case 1:
          if (
            this.calendar.RecurringPattern.SeperationCount === 0 ||
            this.calendar.RecurringPattern.SeperationCount === null
          ) {
            this.utilitiesService.openWarningModal('Calendar', 'Please select week.');
            this.loaderService.display(false);
            return false;
          } else {
            this.calendar.RecurringPattern.Sunday = this.calendar.RecurringPattern.Sunday ? 1 : 0;
            this.calendar.RecurringPattern.Monday = this.calendar.RecurringPattern.Monday ? 1 : 0;
            this.calendar.RecurringPattern.Tuesday = this.calendar.RecurringPattern.Tuesday ? 1 : 0;
            this.calendar.RecurringPattern.Wednesday = this.calendar.RecurringPattern.Wednesday ? 1 : 0;
            this.calendar.RecurringPattern.Thusday = this.calendar.RecurringPattern.Thusday ? 1 : 0;
            this.calendar.RecurringPattern.Friday = this.calendar.RecurringPattern.Friday ? 1 : 0;
            this.calendar.RecurringPattern.Saturday = this.calendar.RecurringPattern.Saturday ? 1 : 0;

            if (
              this.calendar.RecurringPattern.Sunday !== 1 &&
              this.calendar.RecurringPattern.Monday !== 1 &&
              this.calendar.RecurringPattern.Tuesday !== 1 &&
              this.calendar.RecurringPattern.Wednesday !== 1 &&
              this.calendar.RecurringPattern.Thusday !== 1 &&
              this.calendar.RecurringPattern.Friday !== 1 &&
              this.calendar.RecurringPattern.Saturday !== 1
            ) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a week day.');
              this.loaderService.display(false);
              return false;
            }
          }
          break;

        case 2: {
          if (this.isrbMonthlyDays1 === 1) {
            if (this.DayOfMonthMonthly1 === 0 || this.DayOfMonthMonthly1 === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a day.');
              this.loaderService.display(false);
              return false;
            } else if (this.SeperationCountMonthly1 === 0 || this.SeperationCountMonthly1 === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a month.');
              this.loaderService.display(false);
              return false;
            } else if (this.DayOfMonthMonthly1 > 31) {
              this.utilitiesService.openWarningModal('Calendar', 'Recurrence type is not valid.');
              this.loaderService.display(false);
              return false;
            }
          }

          if (this.isrbMonthlyDays2 === 1) {
            if (this.WeekOfMonthMonthly === 0 || this.WeekOfMonthMonthly === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a week.');
              this.loaderService.display(false);
              return false;
            } else if (this.DayOfMonthMonthly2 === 0 || this.DayOfMonthMonthly2 === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a week day.');
              this.loaderService.display(false);
              return false;
            } else if (this.SeperationCountMonthly2 === 0 || this.SeperationCountMonthly2 === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a month.');
              this.loaderService.display(false);
              return false;
            }
          }
          break;
        }

        case 3: {
          if (this.SeperationCountYearly === 0 || this.SeperationCountYearly === null) {
            this.utilitiesService.openWarningModal('Calendar', 'Please select recur year(s).');
            this.loaderService.display(false);
            return false;
          }

          if (this.isrbYearlyMonths === 1) {
            if (this.MonthOfYearYearly === 0 || this.MonthOfYearYearly === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a month.');
              this.loaderService.display(false);
              return false;
            } else if (this.DayOfMonthYearly === 0 || this.DayOfMonthYearly === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a day.');
              this.loaderService.display(false);
              return false;
            } else if (this.DayOfMonthYearly !== 0 || this.DayOfMonthYearly !== null) {
              let maxdays = 0;
              switch (this.MonthOfYearYearly) {
                case 1:
                case 3:
                case 5:
                case 7:
                case 8:
                case 10:
                case 12:
                  maxdays = 31;
                  break;

                case 2:
                  maxdays = this.utilitiesService.isLeapYear(new Date(this.calendar.StartDate).getFullYear()) ? 29 : 28;
                  break;

                default:
                  maxdays = 30;
              }

              if (this.DayOfMonthYearly > maxdays) {
                this.utilitiesService.openWarningModal('Calendar', 'Recurrence type is not valid.');
                this.loaderService.display(false);
                return false;
              }
            }
          }

          if (this.isrbYearlyDayOfMonth === 1) {
            if (this.isrbYearlyDayOfMonth === 1 && (this.YearlyWeekOfMonth === 0 || this.YearlyWeekOfMonth === null)) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a week.');
              this.loaderService.display(false);
              return false;
            } else if (
              this.isrbYearlyDayOfMonth === 1 &&
              (this.YearlyDayOfMonth === 0 || this.YearlyDayOfMonth === null)
            ) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a week day.');
              this.loaderService.display(false);
              return false;
            } else if (
              this.isrbYearlyDayOfMonth === 1 &&
              (this.YearlyMonthOfYear === 0 || this.YearlyMonthOfYear === null)
            ) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a month.');
              this.loaderService.display(false);
              return false;
            }
          }
          break;
        }
      }

      if (this.rangeOfRecurrenceRadioControl === '2') {
        if (this.calendar.RecurringPattern.Occurences === null || this.calendar.RecurringPattern.Occurences === 0) {
          this.utilitiesService.openWarningModal('Calendar', 'Occurrences is required.');
          this.loaderService.display(false);
          return false;
        }
      }
    }

    if (
      this.calendar.IsRecurring === 1 &&
      this.isNewState === true &&
      this.calendar.StartDate !== null &&
      this.calendar.StartDate !== '' &&
      this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd') ===
        this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd') &&
      this.utilitiesService.dateParseFromTime(this.calendar.StartTime) <=
        this.utilitiesService.dateParseFromTime(this.venueServerTime)
    ) {
      if (this.rangeOfRecurrenceRadioControl === '1' || this.rangeOfRecurrenceRadioControl === '2') {
        this.calendar.EndDate = '';
      }

      this.utilitiesService.openWarningModal(
        'Calendar',
        'Sorry, you cannot create schedule for current day as start time has already passed.',
      );
      this.loaderService.display(false);
      return false;
    }
  }

  public dayMonthYearValidation(value: any, type: string) {
    if (value > 365 && type === 'day' && this.SeperationCountDaily) {
      this.SeperationCountDaily = null;
    } else if (value > 52 && type === 'week' && this.calendar.RecurringPattern.SeperationCount) {
      this.calendar.RecurringPattern.SeperationCount = null;
    } else if (value > 12 && type === 'month' && this.SeperationCountMonthly2) {
      this.SeperationCountMonthly2 = null;
    } else if (value > 12 && type === 'month' && this.SeperationCountMonthly1) {
      this.SeperationCountMonthly1 = null;
    } else if (value > 31 && type === 'dayCount' && this.DayOfMonthMonthly1) {
      this.DayOfMonthMonthly1 = null;
    } else if (value > 31 && type === 'dayMonth' && this.DayOfMonthYearly) {
      this.DayOfMonthYearly = null;
    }
  }

  public addScheduleFromNextDay() {
    const newStartDate = new Date(this.calendar.StartDate);
    this.calendar.StartDate = this.datePipe.transform(newStartDate.setDate(newStartDate.getDate() + 1), 'yyyy-MM-dd');
  }

  //// Create Schedule ------------------------------------------------------
  public addSchedule(scheduleForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;
    let noValidation = false;

    if (scheduleForm.status == 'INVALID' && scheduleForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Calendar', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    let sTime = '';
    let eTime = '';
    if (this.calendar.StartTime) {
      sTime = this.calendar.StartTime.split(' ')[1];
    }
    if (this.calendar.EndTime) {
      eTime = this.calendar.EndTime.split(' ')[1];
    }

    if (sTime == undefined) {
      sTime = this.utilitiesService.convert24To12HrWithMeridian(this.calendar.StartTime).split(' ')[1];
    }
    if (eTime == undefined) {
      eTime = this.utilitiesService.convert24To12HrWithMeridian(this.calendar.EndTime).split(' ')[1];
    }

    if (
      this.calendar.IsRecurring &&
      this.calendar.StartTime != null &&
      this.calendar.EndTime != null &&
      ((sTime.toLowerCase() == 'pm' &&
        eTime.toLowerCase() == 'am' &&
        (this.calendar.StartDate != '' || this.calendar.StartDate != null) &&
        (this.calendar.EndDate == '' || this.calendar.EndDate == null)) ||
        (sTime.toLowerCase() == 'pm' &&
          eTime.toLowerCase() == 'am' &&
          (this.calendar.StartDate != '' || this.calendar.StartDate != null) &&
          (this.calendar.EndDate != '' || this.calendar.EndDate != null) &&
          this.calendar.EndDate > this.calendar.StartDate) ||
        (sTime.toLowerCase() == 'pm' &&
          eTime.toLowerCase() == 'am' &&
          (this.calendar.StartDate != '' || this.calendar.StartDate != null) &&
          this.calendar.EndDate == null &&
          this.IsrbRecurrenceEndAfterChecked))
    ) {
      noValidation = true;
    }

    this.calendar.IsFullDayEvent = this.calendar.IsFullDayEvent ? 1 : 0;
    this.calendar.IsRecurring = this.calendar.IsRecurring ? 1 : 0;
    this.calendar.IsBillableWork = this.calendar.IsBillableWork ? 1 : 0;

    const startTime = this.calendar.StartTime;
    const endTime = this.calendar.EndTime;

    if (
      this.calendar.IsFullDayEvent !== 1 &&
      this.utilitiesService.convert12To24Hr(startTime) <= this.venueServerTime &&
      this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd') ==
        this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    ) {
      this.loaderService.display(false);
      this.utilitiesService.smmodal(
        'Calendar',
        'Schedule start time should not be less than current time (Timezone: ' + this.venueTimeZoneText + ').',
      );
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    if (
      !noValidation &&
      this.calendar.StartDate === this.calendar.EndDate &&
      this.utilitiesService.convert12To24Hr(startTime) > this.utilitiesService.convert12To24Hr(endTime)
    ) {
      this.utilitiesService.openWarningModal('Calendar', 'Schedule start time should not be greater than end time.');
      this.loaderService.display(false);
      this.isReqComplete = false;
      return false;
    }

    this.calendar.ScheduleTitle = this.calendar.ScheduleTitle;
    this.calendar.ScheduleDescription = this.calendar.ScheduleDescription;

    const storeStartTime = this.calendar.StartTime;
    const storeEndTime = this.calendar.EndTime;

    if (this.calendar.StartTime !== null && this.calendar.StartTime !== '') {
      this.calendar.StartTime = this.utilitiesService.convert12To24Hr(this.calendar.StartTime);
    }

    if (this.calendar.EndTime !== null && this.calendar.EndTime !== '') {
      this.calendar.EndTime = this.utilitiesService.convert12To24Hr(this.calendar.EndTime);
    }

    this.calendar.AddedBy = this.currentUser.UserId;

    if (this.calendar.ScheduleType != null) {
      this.calendar.ScheduleType.ScheduleTypeId = this.calendar.ScheduleType.ScheduleTypeId;
    }

    if (
      storeStartTime == '12:00 am' ||
      storeStartTime == '12:00 AM' ||
      storeStartTime == '00:00 am' ||
      storeStartTime == '00:00 AM'
    ) {
      this.calendar.StartTime = '00:00';
    }

    if (
      storeEndTime == '12:00 am' ||
      storeEndTime == '12:00 AM' ||
      storeEndTime == '00:00 am' ||
      storeEndTime == '00:00 AM'
    ) {
      this.calendar.EndTime = '00:00';
    }
    if (this.CheckValidation(noValidation) === false) {
      this.isReqComplete = false;
      return;
    }

    if (this.calendar.IsRecurring === 1) {
      if (this.rangeOfRecurrenceRadioControl === '1' || this.rangeOfRecurrenceRadioControl === '3') {
        this.calendar.RecurringPattern.Occurences = 0;
      }
      if (this.rangeOfRecurrenceRadioControl === '1' || this.rangeOfRecurrenceRadioControl === '2') {
        this.calendar.EndDate = '';
      }
      this.calendar.RecurringPattern.RecurringTypeId = this.selectedTabIndex + 1;
      this.getValueFromSelectedTab(this.selectedTabIndex);
    }

    this.calendar.Venue.Timezone = this.calendar.TimeZone;
    this.calendar.ScheduleId = 0;

    this.calendar.CorporationId = this.calendar.CorporationId;
    this.calendar.Venue.VenueId = this.calendar.Venue.VenueId;
    this.calendar.Location.LocationId = this.calendar.Location.LocationId;

    if (this.calendar.ScheduleType.ScheduleTypeCode == 'QC') {
      this.calendar.AssigneTo = null;
      this.calendar.Inspector = null;
      this.calendar.Supervisor.UserId = this.calendar.Supervisor.UserId;
    } else if (this.calendar.ScheduleType.ScheduleTypeCode == 'CLEANING_ALERT') {
      this.calendar.Supervisor = null;
      this.calendar.Inspector = null;
      this.calendar.AssigneTo.UserId = this.calendar.AssigneTo.UserId;
    } else if (this.calendar.ScheduleType.ScheduleTypeCode == 'EQUIPMENT_PM') {
      this.calendar.Supervisor = null;
      this.calendar.Inspector = null;
      this.calendar.AssigneTo.UserId = this.calendar.AssigneTo.UserId;
    } else if (this.calendar.ScheduleType.ScheduleTypeCode == 'PROJECT_WORK') {
      this.calendar.Supervisor = null;
      this.calendar.Inspector = null;
      this.calendar.AssigneTo.UserId = this.calendar.AssigneTo.UserId;
    } else if (this.calendar.ScheduleType.ScheduleTypeCode == 'INSPECT') {
      this.calendar.Supervisor = null;
      this.calendar.AssigneTo = null;
      this.calendar.Inspector.UserId = this.calendar.Inspector.UserId;
    }

    if (this.calendar.IsRecurring == 0) {
      this.calendar.RecurringPattern.Sunday = 0;
      this.calendar.RecurringPattern.Monday = 0;
      this.calendar.RecurringPattern.Tuesday = 0;
      this.calendar.RecurringPattern.Wednesday = 0;
      this.calendar.RecurringPattern.Thusday = 0;
      this.calendar.RecurringPattern.Friday = 0;
      this.calendar.RecurringPattern.Saturday = 0;
      this.calendar.RecurringPattern.RecurringTypeId = 0;
    }

    if (this.calendar.StartDate !== null && this.calendar.StartDate !== '') {
      this.calendar.StartDate = this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd');
    }

    if (this.calendar.EndDate !== null && this.calendar.EndDate !== '') {
      this.calendar.EndDate = this.datePipe.transform(this.calendar.EndDate, 'yyyy-MM-dd');
    }
    this.calendar.ServiceProviderId = this.authState.getStoredContextServiceProviderId();

    this.calendar.IsBetweenSelectedTime = this.calendar.IsBetweenSelectedTime ? 1 : 0;
    if (this.calendar.IsBetweenSelectedTime && this.calendar.IntervalMinutes) {
      this.calendar.IntervalMinutes = Number(this.calendar.IntervalMinutes);
    }

    if (this.calendar.IsFullDayEvent) {
      this.calendar.StartTime = '00:00';
      this.calendar.EndTime = '00:00';
    }

    this.calendarservice.Add(this.utilitiesService.stripScript(this.calendar)).subscribe(
      () => {
        this.scheduleDialogClose(scheduleForm);
        this.utilitiesService.snackBarWithAutoDismiss('Calendar', 'Schedule added successfully!');
        this.loaderService.display(false);
        this.isReqComplete = false;
      },
      (error) => {
        this.isReqComplete = true;
        this.loaderService.display(false);
        console.log('CalendarService Add Call Failed. Status:' + error.status + 'Status Text' + error.statusText);
      },
    );
  }

  //// get Schedule ------------------------------------------------------
  public getScheduleDetails(templateRef: TemplateRef<any>, ScheduleId: any, IsEntireSeries: number) {
    this.loaderService.display(true);

    const CPSchedule = new CalModule();
    CPSchedule.ScheduleCPId = this.authState.AESEncryptText(ScheduleId);
    this.calendarservice.GetScheduleDetails(CPSchedule).subscribe(
      (data) => {
        this.calendar = data;
        this.loaderService.display(false);
        if (this.calendar != null) {
          if (
            this.calendar.IsBetweenSelectedTime &&
            this.calendar.IntervalMinutes != null &&
            this.calendar.IntervalMinutes > 0
          ) {
            this.isRecurringCheckboxDisabled = true;
            this.isFullDayEventDisabled = true;
            this.getAllIntervalMinutes();
            this.changeTimeInterval(this.calendar.IntervalMinutes);
          } else if (this.calendar.IsFullDayEvent) {
            this.isTimeIntervalDisabled = true;
            this.isRecurringCheckboxDisabled = true;
          } else if (this.IsrbJustThisOneChecked && this.calendar.IsRecurring) {
            this.isFullDayEventDisabled = true;
            this.isRecurringCheckboxDisabled = true;
            this.isTimeIntervalDisabled = true;
          } else if (
            this.IsrbTheEntireSeriesChecked &&
            this.calendar.IsRecurring &&
            this.calendar.IntervalMinutes === null
          ) {
            this.isFullDayEventDisabled = true;
            this.isTimeIntervalDisabled = true;
            this.isRecurringCheckboxDisabled = true;
          } else if (this.calendar.IsRecurring) {
            this.isFullDayEventDisabled = true;
            this.isRecurringCheckboxDisabled = true;
          } else {
            this.isTimeIntervalDisabled = true;
            this.isFullDayEventDisabled = true;
            this.isRecurringCheckboxDisabled = true;
          }

          this.calendar.CorporationId = this.calendar.CorporationId;
          this.calendar.CorporationName = this.calendar.CorporationName;

          this.calendar.Venue.VenueId = this.calendar.Venue.VenueId;
          this.calendar.Venue.VenueName = this.calendar.Venue.VenueName;

          if (this.calendar.Location != null) {
            this.calendar.Location.LocationId = this.calendar.Location.LocationId;
            if (this.calendar.Location.Building != null) {
              this.filterBuildingId = this.calendar.Location.Building.BuildingId;
            }

            if (this.calendar.Location.Zone != null) {
              this.filterZoneId = this.calendar.Location.Zone.ZoneId;
            }
          }

          this.selectedEventType = '';
          if (
            this.calendar.ScheduleType.ScheduleTypeCode == 'CLEANING_ALERT' ||
            this.calendar.ScheduleType.ScheduleTypeCode == 'ScheduleTypeCode'
          ) {
            this.selectedEventType = 'CLEAN_QC';
          } else if (this.calendar.ScheduleType.ScheduleTypeCode == 'INSPECT') {
            this.selectedEventType = this.calendar.ScheduleType.ScheduleTypeCode;
          } else if (this.calendar.ScheduleType.ScheduleTypeCode == 'EQUIPMENT_PM') {
            this.selectedEventType = this.calendar.ScheduleType.ScheduleTypeCode;
          } else if (this.calendar.ScheduleType.ScheduleTypeCode == 'PROJECT_WORK') {
            this.selectedEventType = this.calendar.ScheduleType.ScheduleTypeCode;
          }

          this.getAllScheduleTypes();

          if (this.calendar.IsFullDayEvent === 0) {
            if (this.calendar.StartTime !== null || this.calendar.StartTime !== '') {
              this.calendar.StartTime = this.utilitiesService.convert24To12HrWithMeridian(this.calendar.StartTime);
            }

            if (this.calendar.EndTime !== null || this.calendar.EndTime !== '') {
              this.calendar.EndTime = this.utilitiesService.convert24To12HrWithMeridian(this.calendar.EndTime);
            }
          }

          if (this.calendar.IsFullDayEvent === 1) {
            this.calendar.StartTime = '12:00 AM';
            this.calendar.EndTime = '12:00 AM';
            this.StartTimeDisabled = true;
            this.EndTimeDisabled = true;
          }

          if (
            this.calendar.ScheduleType != null &&
            this.calendar.ScheduleType.ScheduleTypeCode != null &&
            this.calendar.CorporationId != null &&
            this.calendar.Venue != null &&
            this.calendar.Venue.VenueId != null
          ) {
            this.getAllSupervisorsWorkersInspectors(
              this.calendar.ScheduleType.ScheduleTypeCode,
              this.calendar.CorporationId,
              this.calendar.Venue.VenueId,
              this.calendar.Location.LocationId,
            );
          }

          if (
            this.calendar.CorporationId != null &&
            this.calendar.Venue != null &&
            this.calendar.Venue.VenueId != null &&
            this.calendar.ScheduleType != null &&
            this.calendar.ScheduleType.ScheduleTypeId != null
          ) {
            this.getAllBuildings(this.calendar.CorporationId, this.calendar.Venue.VenueId, this.selectedEventType);

            this.getAllZones(
              this.calendar.CorporationId,
              this.calendar.Venue.VenueId,
              this.filterBuildingId,
              this.selectedEventType,
            );

            this.getAllLocations(
              this.calendar.CorporationId,
              this.calendar.Venue.VenueId,
              this.filterBuildingId,
              this.filterZoneId,
              this.calendar.ScheduleType.ScheduleTypeId,
            );
          }

          this.SetControlValueForUpdate(this.calendar, IsEntireSeries);

          this.fileNameDialogRef = this.scheduleDialog.open(templateRef, { width: '800px', disableClose: true });
          if (this.calendar.Venue != null && this.calendar.Venue.Timezone != null) {
            this.timezone = [];
            this.addTimeZones(this.configuration.currentTimezone);
            this.tZs = { id: this.calendar.Venue.Timezone, name: this.calendar.Venue.Timezone };
            this.timezone.push(this.tZs);

            if (this.timezone && this.timezone.length > 0) {
              const time = this.timezone.filter((tz) => tz.id === this.calendar.Venue.Timezone);
              if (time && time[0]) {
                this.calendar.TimeZone = time[0].id;
              }
            }
          }
        }

        this.eventIsRecurring = false;
        if (this.calendar.IsRecurring == 1 && IsEntireSeries == 0) {
          this.eventIsRecurring = true;
        }

        // if (this.calendar.IntervalMinutes != null && this.calendar.IntervalMinutes > 0) {
        //     this.changeTimeInterval(this.calendar.IntervalMinutes);
        // }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'calendarservice GetScheduleDetails for Dropdown Service Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  //// Update Schedule ------------------------------------------------------
  public updateSchedule(scheduleForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;
    let noValidation = false;

    if (scheduleForm.status == 'INVALID' && scheduleForm.disabled == false) {
      this.utilitiesService.smmodal('Calendar', 'You cannot proceed ahead until all the fields are filled properly.');
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    let sTime = '';
    let eTime = '';
    if (this.calendar.StartTime) {
      sTime = this.calendar.StartTime.split(' ')[1];
    }
    if (this.calendar.EndTime) {
      eTime = this.calendar.EndTime.split(' ')[1];
    }

    if (sTime == undefined) {
      sTime = this.utilitiesService.convert24To12HrWithMeridian(this.calendar.StartTime).split(' ')[1];
    }
    if (eTime == undefined) {
      eTime = this.utilitiesService.convert24To12HrWithMeridian(this.calendar.EndTime).split(' ')[1];
    }

    if (
      this.calendar.IsRecurring &&
      this.calendar.StartTime != null &&
      this.calendar.EndTime != null &&
      ((sTime.toLowerCase() == 'pm' &&
        eTime.toLowerCase() == 'am' &&
        (this.calendar.StartDate != '' || this.calendar.StartDate != null) &&
        (this.calendar.EndDate == '' || this.calendar.EndDate == null)) ||
        (sTime.toLowerCase() == 'pm' &&
          eTime.toLowerCase() == 'am' &&
          (this.calendar.StartDate != '' || this.calendar.StartDate != null) &&
          (this.calendar.EndDate != '' || this.calendar.EndDate != null) &&
          this.calendar.EndDate > this.calendar.StartDate) ||
        (sTime.toLowerCase() == 'pm' &&
          eTime.toLowerCase() == 'am' &&
          (this.calendar.StartDate != '' || this.calendar.StartDate != null) &&
          this.calendar.EndDate == null &&
          this.IsrbRecurrenceEndAfterChecked))
    ) {
      noValidation = true;
    }

    if (
      this.datePipe.transform(this.selectedDay, 'yyyy-MM-dd') < this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd')
    ) {
      this.utilitiesService.openWarningModal(
        'Calendar',
        'This schedule is either started or completed. It cannot be updated.',
      );
      this.loaderService.display(false);
      this.isReqComplete = false;
      return false;
    } else if (
      this.datePipe.transform(this.selectedDay, 'yyyy-MM-dd') ===
        this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd') &&
      this.calendar.IsFullDayEvent
    ) {
      this.utilitiesService.openWarningModal(
        'Update This Schedule',
        'This schedule is either started or completed. It cannot be updated.',
      );
      this.loaderService.display(false);
      this.isReqComplete = false;
      return false;
    } else if (
      this.datePipe.transform(this.selectedDay, 'yyyy-MM-dd') ===
        this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd') &&
      this.selectedStartTime <= this.utilitiesService.convert12To24Hr(this.venueServerTime)
    ) {
      this.utilitiesService.openWarningModal(
        'Update This Schedule',
        'This schedule is either started or completed. It cannot be updated.',
      );
      this.loaderService.display(false);
      this.isReqComplete = false;
      return false;
    } else if (
      this.datePipe.transform(this.selectedDay, 'yyyy-MM-dd') ===
        this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd') &&
      this.selectedStartTime <= this.utilitiesService.convert12To24Hr(this.venueServerTime) &&
      this.calendar.IsRecurring &&
      (this.IsrbJustThisOneChecked || this.IsrbTheEntireSeriesChecked)
    ) {
      this.utilitiesService.openWarningModal(
        'Update This Schedule',
        'This schedule is either started or completed. It cannot be updated.',
      );
      this.loaderService.display(false);
      this.isReqComplete = false;
      return false;
    }

    this.calendar.IsFullDayEvent = this.calendar.IsFullDayEvent ? 1 : 0;
    this.calendar.IsRecurring = this.calendar.IsRecurring ? 1 : 0;
    this.calendar.IsBillableWork = this.calendar.IsBillableWork ? 1 : 0;

    const startTime = this.calendar.StartTime;
    const endTime = this.calendar.EndTime;

    if (
      this.calendar.IsFullDayEvent !== 1 &&
      this.utilitiesService.convert12To24Hr(startTime) <= this.venueServerTime &&
      this.IsrbJustThisOneChecked &&
      this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd') ==
        this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    ) {
      this.loaderService.display(false);
      this.utilitiesService.smmodal(
        'Calendar',
        'Schedule start time should not be less than current time (Timezone: ' + this.venueTimeZoneText + ').',
      );
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    if (
      !noValidation &&
      this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd') ==
        this.datePipe.transform(this.calendar.EndDate, 'yyyy-MM-dd') &&
      this.utilitiesService.convert12To24Hr(startTime) > this.utilitiesService.convert12To24Hr(endTime)
    ) {
      this.utilitiesService.openWarningModal('Calendar', 'Schedule start time should not be greater than end time.');
      this.loaderService.display(false);
      this.isReqComplete = false;
      return false;
    }

    this.calendar.ModifiedBy = this.currentUser.UserId;
    this.calendar.AddedBy = this.currentUser.UserId;

    this.calendar.ScheduleTitle = this.calendar.ScheduleTitle;
    this.calendar.ScheduleDescription = this.calendar.ScheduleDescription;

    const storeStartTime = this.calendar.StartTime;
    const storeEndTime = this.calendar.EndTime;

    if (this.calendar.StartTime !== null && this.calendar.StartTime !== '') {
      this.calendar.StartTime = this.utilitiesService.convert12To24Hr(this.calendar.StartTime);
    }

    if (this.calendar.EndTime !== null && this.calendar.EndTime !== '') {
      this.calendar.EndTime = this.utilitiesService.convert12To24Hr(this.calendar.EndTime);
    }

    if (this.calendar.ScheduleType != null) {
      this.calendar.ScheduleType.ScheduleTypeId = this.calendar.ScheduleType.ScheduleTypeId;
    }

    if (
      storeStartTime == '12:00 am' ||
      storeStartTime == '12:00 AM' ||
      storeStartTime == '00:00 am' ||
      storeStartTime == '00:00 AM'
    ) {
      this.calendar.StartTime = '00:00';
    }
    if (
      storeEndTime == '12:00 am' ||
      storeEndTime == '12:00 AM' ||
      storeEndTime == '00:00 am' ||
      storeEndTime == '00:00 AM'
    ) {
      this.calendar.EndTime = '00:00';
    }

    if (this.CheckValidation(noValidation) === false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    if (
      this.rangeOfRecurrenceRadioControl === '1' ||
      this.rangeOfRecurrenceRadioControl === '3' ||
      this.IsEntireSeriesModifiedControl === 'rbJustThisOne'
    ) {
      this.calendar.RecurringPattern.Occurences = 0;
    }

    if (this.calendar.IsRecurring === 1 && this.IsEntireSeriesModifiedControl !== 'rbJustThisOne') {
      this.calendar.RecurringPattern.RecurringTypeId = this.selectedTabIndex + 1;
      this.getValueFromSelectedTab(this.selectedTabIndex);
    }

    this.calendar.IsActive = 1;
    this.calendar.Venue.Timezone = this.calendar.TimeZone;

    this.calendar.CorporationId = this.calendar.CorporationId;
    this.calendar.Venue.VenueId = this.calendar.Venue.VenueId;
    this.calendar.Location.LocationId = this.calendar.Location.LocationId;

    if (this.calendar.ScheduleType.ScheduleTypeCode == 'QC') {
      this.calendar.AssigneTo = null;
      this.calendar.Inspector = null;
      this.calendar.Supervisor.UserId = this.calendar.Supervisor.UserId;
    } else if (this.calendar.ScheduleType.ScheduleTypeCode == 'CLEANING_ALERT') {
      this.calendar.Supervisor = null;
      this.calendar.Inspector = null;
      this.calendar.AssigneTo.UserId = this.calendar.AssigneTo.UserId;
    } else if (this.calendar.ScheduleType.ScheduleTypeCode == 'EQUIPMENT_PM') {
      this.calendar.Supervisor = null;
      this.calendar.Inspector = null;
      this.calendar.AssigneTo.UserId = this.calendar.AssigneTo.UserId;
    } else if (this.calendar.ScheduleType.ScheduleTypeCode == 'PROJECT_WORK') {
      this.calendar.Supervisor = null;
      this.calendar.Inspector = null;
      this.calendar.AssigneTo.UserId = this.calendar.AssigneTo.UserId;
    } else if (this.calendar.ScheduleType.ScheduleTypeCode == 'INSPECT') {
      this.calendar.Supervisor = null;
      this.calendar.AssigneTo = null;
      this.calendar.Inspector.UserId = this.calendar.Inspector.UserId;
    }

    if (this.calendar.IsRecurring == 0) {
      this.calendar.RecurringPattern.Sunday = 0;
      this.calendar.RecurringPattern.Monday = 0;
      this.calendar.RecurringPattern.Tuesday = 0;
      this.calendar.RecurringPattern.Wednesday = 0;
      this.calendar.RecurringPattern.Thusday = 0;
      this.calendar.RecurringPattern.Friday = 0;
      this.calendar.RecurringPattern.Saturday = 0;
      this.calendar.RecurringPattern.RecurringTypeId = 0;
    }

    if (this.calendar.StartDate !== null && this.calendar.StartDate !== '') {
      this.calendar.StartDate = this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd');
    }

    if (this.calendar.EndDate !== null && this.calendar.EndDate !== '') {
      this.calendar.EndDate = this.datePipe.transform(this.calendar.EndDate, 'yyyy-MM-dd');
    }
    this.calendar.ServiceProviderId = this.authState.getStoredContextServiceProviderId();

    this.calendar.IsBetweenSelectedTime = this.calendar.IsBetweenSelectedTime ? 1 : 0;
    if (this.calendar.IsBetweenSelectedTime && this.calendar.IntervalMinutes) {
      this.calendar.IntervalMinutes = Number(this.calendar.IntervalMinutes);
    }

    this.calendarservice.UpdateSchedule(this.calendar).subscribe(
      () => {
        this.scheduleDialogClose(scheduleForm);
        this.utilitiesService.snackBarWithAutoDismiss('Calendar', 'Schedule updated successfully!');
        this.loaderService.display(false);
        this.isReqComplete = false;
      },
      (error) => {
        this.isReqComplete = true;
        this.loaderService.display(false);
        console.log('calendarService  UpdateSchedule Failed. ' + error.status + ' Status Text:' + error.statusText);
      },
    );
    this.refresh.next();
  }

  public getScheduleDetailsForDelete(ScheduleId: any, IsEntireSeries: any) {
    this.loaderService.display(true);
    const CPSchedule = new CalModule();
    CPSchedule.ScheduleCPId = this.authState.AESEncryptText(ScheduleId);

    this.calendarservice.GetScheduleDetails(CPSchedule).subscribe(
      (data) => {
        this.loaderService.display(false);
        if (data != null) {
          this.calendar = data;
          this.SetControlValueForUpdate(this.calendar, IsEntireSeries);
        } else if (data == null) {
          this.openConfirmDeletDialogTemplate.closeAll();
          this.utilitiesService.smmodal('Calendar', 'Schedule is already deleted.');
          this.GetScheduleList();
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'calendarservice GetScheduleDetails for Dropdown Service Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public rangeOfRecurrenceRadioclick(event: MatRadioChange) {
    const rangeOfRecurrence: MatRadioButton = event.source;

    if (rangeOfRecurrence.id === 'rbRecurrenceNoEndDate' && rangeOfRecurrence.checked) {
      this.calendar.RecurringPattern.Occurences = 0;
      this.calendar.EndDate = '';
      this.IsrbRecurrenceNoEndDateCheked = true;
      this.IsrbRecurrenceEndAfterChecked = false;
      this.IsrbRecurrenceEndbyChecked = false;
    }

    if (rangeOfRecurrence.id === 'rbRecurrenceEndAfter' && rangeOfRecurrence.checked) {
      this.calendar.RecurringPattern.Occurences = 10;
      this.calendar.EndDate = '';
      this.IsrbRecurrenceNoEndDateCheked = false;
      this.IsrbRecurrenceEndAfterChecked = true;
      this.IsrbRecurrenceEndbyChecked = false;
    }

    if (rangeOfRecurrence.id === 'rbRecurrenceEndby' && rangeOfRecurrence.checked) {
      this.calendar.RecurringPattern.Occurences = 0;

      if (this.calendar.EndDate !== null && this.calendar.EndDate !== '') {
        this.calendar.EndDate = this.datePipe.transform(this.calendar.EndDate, 'yyyy-MM-dd');
      }
      this.IsrbRecurrenceNoEndDateCheked = false;
      this.IsrbRecurrenceEndAfterChecked = false;
      this.IsrbRecurrenceEndbyChecked = true;
    }
  }

  public rbSelectionChange(event: MatRadioChange) {
    const Selection: MatRadioButton = event.source;
    if (Selection.id === 'rbJustThisOne' && Selection.checked) {
      this.IsrbJustThisOneChecked = true;
      this.IsrbTheEntireSeriesChecked = false;
      this.IsEntireSeriesModifiedControl = Selection.value;
    }

    if (Selection.id === 'rbTheEntireSeries' && Selection.checked) {
      this.IsrbJustThisOneChecked = false;
      this.IsrbTheEntireSeriesChecked = true;
      this.IsEntireSeriesModifiedControl = Selection.value;
    }
  }

  public rbDailyChange(event: MatRadioChange) {
    const Daily: MatRadioButton = event.source;
    if (Daily.id === 'rbDailyDays' && Daily.checked) {
      this.isrbDailyDays = 1;
      this.calendar.RecurringPattern.SeperationCount = 1;
      this.IsrbDailyDaysChecked = true;
      this.rbDailyEveryWeekdays = false;
    }

    if (Daily.id === 'rbDailyEveryWeekdays' && Daily.checked) {
      this.isrbDailyDays = 0;
      this.calendar.RecurringPattern.SeperationCount = 0;
      this.IsrbDailyDaysChecked = false;
      this.rbDailyEveryWeekdays = true;
    }
  }

  public rbMonthlyChange(event: MatRadioChange) {
    const Monthly: MatRadioButton = event.source;
    if (Monthly.id === 'rbMonthlyDays' && Monthly.checked) {
      this.isrbMonthlyDays1 = 1;
      this.isrbMonthlyDays2 = 0;
      this.rbMonthlyDays = true;
      this.rbMonthlyMonths = false;
    }

    if (Monthly.id === 'rbMonthlyMonths' && Monthly.checked) {
      this.isrbMonthlyDays1 = 0;
      this.isrbMonthlyDays2 = 1;
      this.rbMonthlyDays = false;
      this.rbMonthlyMonths = true;
    }
  }

  public rbYearlyChange(event: MatRadioChange) {
    const Yearly: MatRadioButton = event.source;
    if (Yearly.id === 'rbYearlyMonths' && Yearly.checked) {
      this.isrbYearlyMonths = 1;
      this.isrbYearlyDayOfMonth = 0;
      this.rbYearlyMonths = true;
      this.rbYearlyMonthWeekDay = false;
    }

    if (Yearly.id === 'rbYearlyMonthWeekDay' && Yearly.checked) {
      this.isrbYearlyMonths = 0;
      this.isrbYearlyDayOfMonth = 1;
      this.rbYearlyMonthWeekDay = true;
      this.rbYearlyMonths = false;
    }
  }

  public IsRecurring(event: any) {
    if (event.checked === true) {
      //// Daily
      this.selectedTabIndex = 0;
      this.IsrbDailyDaysChecked = true;
      this.SeperationCountDaily = 1;
      this.rbDailyEveryWeekdays = false;

      //// Weekly
      this.calendar.RecurringPattern.SeperationCount = 1;
      this.calendar.RecurringPattern.Sunday = 0;
      this.calendar.RecurringPattern.Monday = 0;
      this.calendar.RecurringPattern.Tuesday = 0;
      this.calendar.RecurringPattern.Wednesday = 0;
      this.calendar.RecurringPattern.Thusday = 0;
      this.calendar.RecurringPattern.Friday = 0;
      this.calendar.RecurringPattern.Saturday = 0;

      //// Monthly
      this.rbMonthlyDays = true;
      this.DayOfMonthMonthly1 = 1;
      this.SeperationCountMonthly1 = 1;
      this.rbMonthlyMonths = false;
      this.WeekOfMonthMonthly = 0;
      this.DayOfMonthMonthly2 = 0;
      this.SeperationCountMonthly2 = 1;

      //// Yearly
      this.SeperationCountYearly = 1;
      this.rbYearlyMonths = true;
      this.MonthOfYearYearly = 0;
      this.DayOfMonthYearly = 1;
      this.rbYearlyMonthWeekDay = false;
      this.YearlyWeekOfMonth = 0;
      this.YearlyDayOfMonth = 0;
      this.YearlyMonthOfYear = 0;

      //// Range of Recurrence
      // this.calendar.StartDate = this.TodayDate;
      this.IsrbRecurrenceNoEndDateCheked = true;
      this.IsrbRecurrenceEndAfterChecked = false;
      this.calendar.RecurringPattern.Occurences = 10;
      this.IsrbRecurrenceEndbyChecked = false;
      this.calendar.EndDate = null;

      this.IsVisible = true;
      this.calendar.IsRecurring = 1;
      this.IsFullDayDatePanalVisible = false;

      this.calendar.IsFullDayEvent = 0;

      if (this.calendar.ScheduleId) {
        this.isFullDayEventDisabled = true;
      } else {
        this.isFullDayEventDisabled = true;
      }
    } else {
      this.calendar.IsRecurring = 0;
      this.IsVisible = false;
      this.IsFullDayDatePanalVisible = true;
      this.calendar.StartDate = this.TodayDate;
      this.calendar.EndDate = this.calendar.StartDate;
      this.isFullDayEventDisabled = false;

      if (this.calendar.ScheduleId) {
        this.isFullDayEventDisabled = true;
      } else {
        this.isFullDayEventDisabled = false;
      }

      if (this.calendar.IsBetweenSelectedTime && this.calendar.IsRecurring == 0) {
        this.calendar.IsFullDayEvent = 0;
        this.isFullDayEventDisabled = true;
      }
    }

    if (this.calendar.IsRecurring && this.IsrbRecurrenceNoEndDateCheked) {
      this.calendar.EndDate = null;
    }
  }

  //// Delete Schedule ------------------------------------------------------
  public OpenDeleteScheduleModel(ScheduleId2: any, templateRef: TemplateRef<any>, event: any) {
    this.isDeleteOptionVisible = event.IsRecurring === 1 ? true : false;
    this.calendar.ScheduleId = ScheduleId2;
    this.VenueStartTime = event.VenueStartTime;
    this.VenueTimezoneCurrentDate = event.VenueTimezoneCurrentDate;

    this.selectedDay = this.datePipe.transform(event.start, 'yyyy-MM-dd');

    let selectedDayTime = this.datePipe.transform(event.start, 'yyyy-MM-dd HH:mm');
    let todayDatTime = this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd ' + this.venueServerTime);

    if (selectedDayTime < todayDatTime) {
      this.utilitiesService.openWarningModal(
        'Delete This Schedule',
        'This schedule is either started or completed. It cannot be deleted.',
      );
      this.loaderService.display(false);
      return false;
    } else {
      this.IsEntireSeriesModifiedControl = 'rbJustThisOne';

      if (!this.isDeleteOptionVisible) {
        if (event.IsRecurring === 0) {
          this.deleteScheduleDetails(this.calendar.ScheduleId, this.openConfirmDeletDialog);
        } else {
          this.fileNameDialogRef = this.openDeletDialogTemplate.open(templateRef);
        }
      } else {
        this.fileNameDialogRef = this.openDeletDialogTemplate.open(templateRef);
      }
    }
  }

  public deleteScheduleDetails(ScheduleId: any, templateRef: TemplateRef<any>) {
    if (this.IsEntireSeriesModifiedControl === 'rbJustThisOne') {
      this.IsEntireSeries = 0;
      this.calendar.StartDate = this.selectedDay;
      this.calendar.EndDate = this.selectedDay;
    }
    if (this.IsEntireSeriesModifiedControl === 'rbTheEntireSeries') {
      this.IsEntireSeries = 1;
    }
    this.isNewState = false;
    this.openDeletDialogTemplate.closeAll();
    this.fileNameDialogRef = this.openConfirmDeletDialogTemplate.open(templateRef);
    this.getScheduleDetailsForDelete(ScheduleId, this.IsEntireSeries);
  }

  public DeleteSchedule(scheduleForm: NgForm) {
    this.loaderService.display(true);

    this.calendar.ModifiedBy = this.currentUser.UserId;
    this.calendar.AddedBy = this.currentUser.UserId;

    this.calendar.IsEntireSeriesModified = this.IsEntireSeries;
    this.calendar.IsFullDayEvent = this.calendar.IsFullDayEvent ? 1 : 0;
    this.calendar.IsRecurring = this.calendar.IsRecurring ? 1 : 0;
    this.calendar.IsBillableWork = this.calendar.IsBillableWork ? 1 : 0;
    this.calendar.CorporationId = this.calendar.CorporationId;
    this.calendar.Venue.VenueId = this.calendar.Venue.VenueId;

    if (this.calendar.StartDate !== null && this.calendar.StartDate !== '') {
      this.calendar.StartDate = this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd');
    }

    if (this.calendar.EndDate !== null && this.calendar.EndDate !== '') {
      this.calendar.EndDate = this.datePipe.transform(this.calendar.EndDate, 'yyyy-MM-dd');
    }

    this.calendar.IsActive = 1;

    this.calendarservice.DeleteSchedule(this.calendar).subscribe(
      () => {
        this.scheduleDialogClose(scheduleForm);
        this.utilitiesService.snackBarWithAutoDismiss('Calendar', 'Schedule deleted successfully!');
        this.loaderService.display(false);
        this.refresh.next();
      },
      (error) => {
        this.loaderService.display(false);
        this.openConfirmDeletDialogTemplate.closeAll();
        console.log('calendarService DeleteSchedule Failed. ' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  //// Update Schedule ------------------------------------------------------
  public OpenUpdateScheduleModel(
    openDialogTemplateRef: TemplateRef<any>,
    event: any,
    scheduleDialogRef: TemplateRef<any>,
  ) {
    if (this.authState.canAccess('CALENDAR_SCHEDULE_EDIT')) {
      this.calendar = new CalModule();
      this.calendar.RecurringPattern = new RecurringPattern();
      this.scheduleDialogTitle = 'Update Schedule';
      this.isScheduleUpdate = true;
      this.calendar.ScheduleId = event.id;
      this.IsrbJustThisOneVisible = event.IsPreviousDate;
      this.selectedDay = event.start;
      this.selectedStartTime = event.VenueStartTime;

      if (
        this.datePipe.transform(this.selectedDay, 'yyyy-MM-dd') < this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd')
      ) {
        this.utilitiesService.openWarningModal(
          'Calendar',
          'This schedule is either started or completed. It cannot be updated.',
        );
        this.loaderService.display(false);
        return false;
      } else if (
        this.datePipe.transform(this.selectedDay, 'yyyy-MM-dd') ===
          this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd') &&
        event.IsFullDayEvent
      ) {
        this.utilitiesService.openWarningModal(
          'Update This Schedule',
          'This schedule is either started or completed. It cannot be updated.',
        );
        this.loaderService.display(false);
        return false;
      } else if (
        this.datePipe.transform(this.selectedDay, 'yyyy-MM-dd') ===
          this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd') &&
        event.VenueStartTime <= this.utilitiesService.convert12To24Hr(this.venueServerTime)
      ) {
        this.utilitiesService.openWarningModal(
          'Update This Schedule',
          'This schedule is either started or completed. It cannot be updated.',
        );
        this.loaderService.display(false);
        return false;
      } else {
        this.isReqComplete = false;
        this.IsEntireSeriesModifiedControl = 'rbJustThisOne';
        if (this.IsrbJustThisOneVisible) {
          if (event.IsRecurring === 0) {
            this.UpdateScheduleDetails(scheduleDialogRef, this.calendar.ScheduleId);
          } else {
            this.fileNameDialogRef = this.scheduleDialog.open(openDialogTemplateRef);
          }
        } else {
          this.fileNameDialogRef = this.scheduleDialog.open(openDialogTemplateRef);
        }
      }
    } else {
      this.utilitiesService.openDeleteModal('Calendar', 'You do not have permission to edit schedule.');
    }
  }

  public UpdateScheduleDetails(templateRef: TemplateRef<any>, ScheduleId: any) {
    if (this.IsEntireSeriesModifiedControl === 'rbJustThisOne') {
      this.IsEntireSeries = 0;
      this.IsVisible = false;
      this.IsFullDayVisible = true;
      this.IsRecurringVisible = false;
      this.IsFullDayDatePanalVisible = true;
      this.calendar.IsEntireSeriesModified = 0;
      this.calendar.RecurringPattern.Occurences = 0;
    }

    if (this.IsEntireSeriesModifiedControl === 'rbTheEntireSeries') {
      this.IsEntireSeries = 1;
      this.IsVisible = true;
      this.calendar.IsFullDayEvent = 0;
      this.IsFullDayVisible = false;
      this.IsRecurringVisible = true;
      this.IsFullDayDatePanalVisible = false;
      this.calendar.IsEntireSeriesModified = 1;
      this.IsRecurringDisabled = true;
    }
    this.isNewState = false;
    this.getScheduleDetails(templateRef, ScheduleId, this.IsEntireSeries);
  }

  public SetControlValueForUpdate(Objcalendar: CalModule, IsEntireSeries: number) {
    if (Objcalendar.IsRecurring === 1 && this.IsEntireSeriesModifiedControl !== 'rbJustThisOne') {
      this.IsVisible = true;

      switch (Objcalendar.RecurringPattern.RecurringTypeId) {
        case 1: {
          this.selectedTabIndex = 0;

          if (Objcalendar.IsWeekdays === 0) {
            this.SeperationCountDaily = Objcalendar.RecurringPattern.SeperationCount;
            this.IsrbDailyDaysChecked = true;
            this.rbDailyEveryWeekdays = false;
            this.isrbDailyDays = 1;
            this.calendar.RecurringPattern.Sunday = 0;
            this.calendar.RecurringPattern.Monday = 0;
            this.calendar.RecurringPattern.Tuesday = 0;
            this.calendar.RecurringPattern.Wednesday = 0;
            this.calendar.RecurringPattern.Thusday = 0;
            this.calendar.RecurringPattern.Friday = 0;
            this.calendar.RecurringPattern.Saturday = 0;
          } else {
            this.SeperationCountDaily = 0;
            this.IsrbDailyDaysChecked = false;
            this.rbDailyEveryWeekdays = true;
            this.isrbDailyDays = 0;
          }
          break;
        }
        case 2: {
          this.selectedTabIndex = 1;
          this.calendar.RecurringPattern.SeperationCount = Objcalendar.RecurringPattern.SeperationCount;
          break;
        }
        case 3: {
          this.selectedTabIndex = 2;
          if (Objcalendar.RecurringPattern.WeekOfMonth === 0 || Objcalendar.RecurringPattern.WeekOfMonth === null) {
            this.DayOfMonthMonthly1 = Objcalendar.RecurringPattern.DayOfMonth;
            this.SeperationCountMonthly1 = Objcalendar.RecurringPattern.SeperationCount;
            this.rbMonthlyDays = true;
            this.rbMonthlyMonths = false;
            this.isrbMonthlyDays1 = 1;
            this.isrbMonthlyDays2 = 0;
            this.calendar.RecurringPattern.Sunday = 0;
            this.calendar.RecurringPattern.Monday = 0;
            this.calendar.RecurringPattern.Tuesday = 0;
            this.calendar.RecurringPattern.Wednesday = 0;
            this.calendar.RecurringPattern.Thusday = 0;
            this.calendar.RecurringPattern.Friday = 0;
            this.calendar.RecurringPattern.Saturday = 0;
          } else {
            this.WeekOfMonthMonthly = Objcalendar.RecurringPattern.WeekOfMonth;
            this.SeperationCountMonthly2 = Objcalendar.RecurringPattern.SeperationCount;
            this.rbMonthlyDays = false;
            this.rbMonthlyMonths = true;
            this.isrbMonthlyDays1 = 0;
            this.isrbMonthlyDays2 = 1;

            if (this.calendar.RecurringPattern.Sunday === 1) {
              this.DayOfMonthMonthly2 = 1;
            } else if (this.calendar.RecurringPattern.Monday === 1) {
              this.DayOfMonthMonthly2 = 2;
            } else if (this.calendar.RecurringPattern.Tuesday === 1) {
              this.DayOfMonthMonthly2 = 3;
            } else if (this.calendar.RecurringPattern.Wednesday === 1) {
              this.DayOfMonthMonthly2 = 4;
            } else if (this.calendar.RecurringPattern.Thusday === 1) {
              this.DayOfMonthMonthly2 = 5;
            } else if (this.calendar.RecurringPattern.Friday === 1) {
              this.DayOfMonthMonthly2 = 6;
            } else if (this.calendar.RecurringPattern.Saturday === 1) {
              this.DayOfMonthMonthly2 = 7;
            }
          }
          break;
        }
        case 4: {
          this.selectedTabIndex = 3;
          this.SeperationCountYearly = Objcalendar.RecurringPattern.SeperationCount;

          if (Objcalendar.RecurringPattern.WeekOfMonth === 0 || Objcalendar.RecurringPattern.WeekOfMonth === null) {
            this.MonthOfYearYearly = Objcalendar.RecurringPattern.MonthOfYear;
            this.DayOfMonthYearly = Objcalendar.RecurringPattern.DayOfMonth;
            this.rbYearlyMonths = true;
            this.rbYearlyMonthWeekDay = false;
            this.isrbYearlyMonths = 1;
            this.isrbYearlyDayOfMonth = 0;
            this.calendar.RecurringPattern.Sunday = 0;
            this.calendar.RecurringPattern.Monday = 0;
            this.calendar.RecurringPattern.Tuesday = 0;
            this.calendar.RecurringPattern.Wednesday = 0;
            this.calendar.RecurringPattern.Thusday = 0;
            this.calendar.RecurringPattern.Friday = 0;
            this.calendar.RecurringPattern.Saturday = 0;
          } else {
            this.YearlyWeekOfMonth = Objcalendar.RecurringPattern.WeekOfMonth;
            this.YearlyMonthOfYear = Objcalendar.RecurringPattern.MonthOfYear;
            this.rbYearlyMonths = false;
            this.rbYearlyMonthWeekDay = true;
            this.isrbYearlyMonths = 0;
            this.isrbYearlyDayOfMonth = 1;

            if (this.calendar.RecurringPattern.Sunday === 1) {
              this.YearlyDayOfMonth = 1;
            } else if (this.calendar.RecurringPattern.Monday === 1) {
              this.YearlyDayOfMonth = 2;
            } else if (this.calendar.RecurringPattern.Tuesday === 1) {
              this.YearlyDayOfMonth = 3;
            } else if (this.calendar.RecurringPattern.Wednesday === 1) {
              this.YearlyDayOfMonth = 4;
            } else if (this.calendar.RecurringPattern.Thusday === 1) {
              this.YearlyDayOfMonth = 5;
            } else if (this.calendar.RecurringPattern.Friday === 1) {
              this.YearlyDayOfMonth = 6;
            } else if (this.calendar.RecurringPattern.Saturday === 1) {
              this.YearlyDayOfMonth = 7;
            }
          }
          break;
        }
      }
    }

    if (Objcalendar.RecurringPattern.Occurences > 0) {
      this.IsrbRecurrenceNoEndDateCheked = false;
      this.IsrbRecurrenceEndAfterChecked = true;
      this.IsrbRecurrenceEndbyChecked = false;
      this.rangeOfRecurrenceRadioControl = '2';
      this.calendar.EndDate = '';
      this.calendar.RecurringPattern.Occurences = Objcalendar.RecurringPattern.Occurences;
    } else if (Objcalendar.EndDate === '' || Objcalendar.EndDate === null) {
      this.IsrbRecurrenceNoEndDateCheked = true;
      this.IsrbRecurrenceEndAfterChecked = false;
      this.IsrbRecurrenceEndbyChecked = false;
    } else if (Objcalendar.EndDate !== '' && Objcalendar.EndDate !== null) {
      this.IsrbRecurrenceNoEndDateCheked = false;
      this.IsrbRecurrenceEndAfterChecked = false;
      this.IsrbRecurrenceEndbyChecked = true;
    }

    if (IsEntireSeries === 0) {
      this.calendar.StartDate = this.selectedDay;
      const isGreaterStartTime = this.isStartTimeGreaterThanEndTime(
        Objcalendar.VenueStartTime,
        Objcalendar.VenueEndTime,
      );
      const nextDate = new Date(this.calendar.StartDate.getTime());
      nextDate.setDate(nextDate.getDate() + 1);
      if (Objcalendar.IsRecurring === 1) {
        this.calendar.EndDate = isGreaterStartTime ? nextDate : this.selectedDay;
      } else if (isGreaterStartTime) {
        this.calendar.EndDate = nextDate;
      }
      this.setNonRecurringMaxDate();
      this.calendar.IsEntireSeriesModified = 0;
    } else if (IsEntireSeries === 1) {
      // SET Start Date
      if (this.calendar.StartDate !== null && this.calendar.StartDate !== '') {
        if (this.calendar.StartDate < this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd')) {
          this.calendar.StartDate = this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd');
        }
      }
      // SET End Date
      this.calendar.EndDate = this.calendar.EndDate;
      this.calendar.IsEntireSeriesModified = 1;
    }
  }

  /**
   * Method to be called on start date/end date change of a non recurring schedule
   *
   * @param isStartDate boolean to indicate whether the changed date is start date
   */
  public onNonRecurringDateSelection(isStartDate = false) {
    if (isStartDate && this.calendar.StartDate) {
      this.calendar.EndDate = this.calendar.StartDate;
      this.setNonRecurringMaxDate();
    }
    this.BindAllSupervisors();
  }

  public IsFullDayEvent(event: any) {
    this.IsFullDayDatePanalVisible = true;
    this.calendar.IsRecurring = 0;
    if (event.checked) {
      this.FullDayStartTime = this.calendar.StartTime;
      this.FullDayEndTime = this.calendar.EndTime;
      this.calendar.StartTime = '12:00 am';
      this.calendar.EndTime = '12:00 am';
      this.FullDayStartDate = this.calendar.StartDate;
      this.FullDayEndDate = this.calendar.EndDate;

      this.IsRecurringVisible = false;
      this.IsVisible = false;
      this.StartTimeDisabled = true;
      this.EndTimeDisabled = true;

      this.calendar.IsBetweenSelectedTime = null;
      this.intervalMinutes = [];
      this.calendar.IntervalMinutes = null;
      this.intervalTimeSlotes = [];
      this.isTimeIntervalDisabled = true;
      this.IsRecurringDisabled = true;

      if (this.calendar.ScheduleId) {
        this.isRecurringCheckboxDisabled = true;
        this.isTimeIntervalDisabled = true;
      } else {
        this.isTimeIntervalDisabled = true;
        this.isRecurringCheckboxDisabled = true;
      }
    } else {
      if (!this.isNewState && this.FullDayEndDate === undefined && this.FullDayEndDate === undefined) {
        this.getVenueDetails();
        this.FullDayStartTime = this.calendar.StartTime;
        this.FullDayEndTime = this.calendar.EndTime;
        this.FullDayStartDate = this.calendar.StartDate;
        this.FullDayEndDate = this.calendar.EndDate;
        this.StartTimeDisabled = false;
        this.EndTimeDisabled = false;
      } else {
        this.FullDayStartTime = this.calendar.StartTime;
        this.FullDayEndTime = this.calendar.EndTime;
        this.FullDayStartDate = this.calendar.StartDate;
        this.FullDayEndDate = this.calendar.EndDate;

        this.IsRecurringVisible = true;
        this.StartTimeDisabled = false;
        this.EndTimeDisabled = false;
      }
      this.isTimeIntervalDisabled = false;
      this.IsRecurringDisabled = false;

      if (this.calendar.ScheduleId) {
        this.isRecurringCheckboxDisabled = true;
        this.isTimeIntervalDisabled = true;
      } else {
        this.isTimeIntervalDisabled = false;
        this.isRecurringCheckboxDisabled = false;
      }
    }

    if (!this.isNewState) {
      this.IsRecurringVisible = false;
    }
  }

  public DailyonFocus(ControlName: string) {
    if (ControlName === 'rbDailyDays') {
      this.isrbDailyDays = 1;
      this.calendar.RecurringPattern.SeperationCount = 1;
      this.IsrbDailyDaysChecked = true;
      this.rbDailyEveryWeekdays = false;
    } else {
      this.isrbDailyDays = 0;
      this.calendar.RecurringPattern.SeperationCount = 0;
      this.IsrbDailyDaysChecked = false;
      this.rbDailyEveryWeekdays = true;
    }
  }

  public MonthlyonFocus1() {
    this.isrbMonthlyDays1 = 1;
    this.isrbMonthlyDays2 = 0;
    this.rbMonthlyDays = true;
    this.rbMonthlyMonths = false;
  }

  public MonthlyonFocus2() {
    this.isrbMonthlyDays1 = 0;
    this.isrbMonthlyDays2 = 1;
    this.rbMonthlyDays = false;
    this.rbMonthlyMonths = true;
  }

  public YearlyonFocus1() {
    this.isrbYearlyMonths = 1;
    this.isrbYearlyDayOfMonth = 0;
    this.rbYearlyMonthWeekDay = false;
    this.rbYearlyMonths = true;
  }

  public YearlyonFocus2() {
    this.isrbYearlyMonths = 0;
    this.isrbYearlyDayOfMonth = 1;
    this.rbYearlyMonths = false;
    this.rbYearlyMonthWeekDay = true;
  }

  public MonthlyonFocusWeek($event) {
    this.isrbMonthlyDays1 = 0;
    this.isrbMonthlyDays2 = 1;
    this.rbMonthlyDays = false;
    this.rbMonthlyMonths = true;
    this.WeekOfMonthMonthly = $event;
  }

  public MonthlyonFocusDays($event) {
    this.isrbMonthlyDays1 = 0;
    this.isrbMonthlyDays2 = 1;
    this.rbMonthlyDays = false;
    this.rbMonthlyMonths = true;
    this.DayOfMonthMonthly2 = $event;
  }

  public YearlyonFocusOnMonth1($event) {
    this.isrbYearlyMonths = 1;
    this.isrbYearlyDayOfMonth = 0;
    this.rbYearlyMonthWeekDay = false;
    this.rbYearlyMonths = true;
    this.MonthOfYearYearly = $event;
  }

  public YearlyonFocusonWeek2($event) {
    this.isrbYearlyMonths = 0;
    this.isrbYearlyDayOfMonth = 1;
    this.rbYearlyMonths = false;
    this.rbYearlyMonthWeekDay = true;
    this.YearlyWeekOfMonth = $event;
  }

  public YearlyonFocusonDay2($event) {
    this.isrbYearlyMonths = 0;
    this.isrbYearlyDayOfMonth = 1;
    this.rbYearlyMonths = false;
    this.rbYearlyMonthWeekDay = true;
    this.YearlyDayOfMonth = $event;
  }

  public YearlyonFocusonMonth2($event) {
    this.isrbYearlyMonths = 0;
    this.isrbYearlyDayOfMonth = 1;
    this.rbYearlyMonths = false;
    this.rbYearlyMonthWeekDay = true;
    this.YearlyMonthOfYear = $event;
  }

  public rangeOfRecurrenceFocus(ControlName: string) {
    switch (ControlName) {
      case 'rbRecurrenceNoEndDate':
        this.rangeOfRecurrenceRadioControl = '1';
        this.calendar.RecurringPattern.Occurences = 0;
        this.calendar.EndDate = '';
        this.IsrbRecurrenceNoEndDateCheked = true;
        this.IsrbRecurrenceEndAfterChecked = false;
        this.IsrbRecurrenceEndbyChecked = false;
        break;
      case 'rbRecurrenceEndAfter':
        this.rangeOfRecurrenceRadioControl = '2';
        if (this.calendar.RecurringPattern.Occurences == 10) {
          this.calendar.RecurringPattern.Occurences = 10;
        }
        this.calendar.EndDate = '';
        this.IsrbRecurrenceNoEndDateCheked = false;
        this.IsrbRecurrenceEndAfterChecked = true;
        this.IsrbRecurrenceEndbyChecked = false;
        break;
      case 'rbRecurrenceEndby':
        this.rangeOfRecurrenceRadioControl = '3';
        this.calendar.RecurringPattern.Occurences = 0;
        if (this.calendar.EndDate !== null && this.calendar.EndDate !== '') {
          this.calendar.EndDate = this.datePipe.transform(this.calendar.EndDate, 'yyyy-MM-dd');
        }
        this.IsrbRecurrenceNoEndDateCheked = false;
        this.IsrbRecurrenceEndAfterChecked = false;
        this.IsrbRecurrenceEndbyChecked = true;
        break;
    }
  }

  public closeModalContent() {
    this.occurrenceDetails = false;
  }

  beforeViewRender($event: any) {
    if ($event.body != undefined && $event.body != null) {
      for (let i = 0; i < $event.body.length; i++) {
        if ($event.body[i].inMonth == true) {
          if (moment($event.body[i].date).format('YYYY-MM-DD') == moment(new Date()).format('YYYY-MM-DD')) {
            this.mwlCalendarToday.nativeElement.classList.add('active');
            return;
          } else {
            this.mwlCalendarToday.nativeElement.classList.remove('active');
          }
        }
      }
    } else {
      if ($event.header.length == 1) {
        if (moment($event.header[0].date).format('YYYY-MM-DD') == moment(new Date()).format('YYYY-MM-DD')) {
          this.mwlCalendarToday.nativeElement.classList.add('active');
          return;
        } else {
          this.mwlCalendarToday.nativeElement.classList.remove('active');
        }
      } else {
        for (let i = 0; i < $event.header.length; i++) {
          if (moment($event.header[i].date).format('YYYY-MM-DD') == moment(new Date()).format('YYYY-MM-DD')) {
            this.mwlCalendarToday.nativeElement.classList.add('active');
            return;
          } else {
            this.mwlCalendarToday.nativeElement.classList.remove('active');
          }
        }
      }
    }
  }

  changeTimeInterval($event) {
    this.intervalTimeSlotes = [];
    let startTime: any;
    let endTime: any;
    startTime = this.calendar.StartTime;
    endTime = this.calendar.EndTime;
    if (
      this.calendar.StartTime == '12:00 am' ||
      this.calendar.StartTime == '12:00 AM' ||
      this.calendar.StartTime == '00:00 am' ||
      this.calendar.StartTime == '00:00 AM' ||
      this.calendar.StartTime == '00:00'
    ) {
      startTime = '24:00';
    }

    if (
      this.calendar.EndTime == '12:00 am' ||
      this.calendar.EndTime == '12:00 AM' ||
      this.calendar.EndTime == '00:00 am' ||
      this.calendar.EndTime == '00:00 AM' ||
      this.calendar.EndTime == '00:00'
    ) {
      endTime = '24:00';
    }

    if ($event != null) {
      this.calendar.IntervalMinutes = $event;
      if (
        this.utilitiesService.convert12To24Hr(startTime) > this.utilitiesService.convert12To24Hr(endTime) &&
        this.calendar.IsRecurring === 0 &&
        this.calendar.EndDate === this.calendar.StartDate
      ) {
        this.utilitiesService.openWarningModal('Calendar', 'Schedule start time should not be greater than end time.');
        this.calendar.IntervalMinutes = null;
        return;
      } else if (
        this.utilitiesService.convert12To24Hr(startTime) > this.utilitiesService.convert12To24Hr(endTime) &&
        this.calendar.IsRecurring === 1 &&
        (this.calendar.EndDate !== null || this.calendar.EndDate !== '') &&
        this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd') ===
          this.datePipe.transform(this.calendar.EndDate, 'yyyy-MM-dd')
      ) {
        this.utilitiesService.openWarningModal('Calendar', 'Schedule start time should not be greater than end time.');
        this.calendar.IntervalMinutes = null;
        return;
      }

      this.calendar.IntervalMinutes = $event;
      const slots: any = this.utilitiesService.getTimeSlots(
        this.utilitiesService.convert12To24Hr(startTime),
        this.utilitiesService.convert12To24Hr(endTime),
        this.calendar.IntervalMinutes,
      );

      for (let i = 0; i < slots.length - 1; i++) {
        let slotRange: DisplaySlot = new DisplaySlot();
        let j = i + 1;
        slotRange.RowId = j;
        slotRange.Time =
          this.utilitiesService.convert24To12HrWithMeridian(slots[i]) +
          '  -  ' +
          this.utilitiesService.convert24To12HrWithMeridian(slots[j]);
        this.intervalTimeSlotes.push(slotRange);
      }
    }
  }

  public dateTimePickerClose(_event) {
    if (
      this.calendar.IsBetweenSelectedTime != null &&
      (this.calendar.IsBetweenSelectedTime || this.calendar.IsBetweenSelectedTime > 0) &&
      this.calendar.IntervalMinutes != null &&
      this.calendar.IntervalMinutes > 0
    ) {
      this.changeTimeInterval(this.calendar.IntervalMinutes);
    }
  }

  /**
   * Set Max date for non recurring end date
   */
  setNonRecurringMaxDate() {
    this.maxDate = new Date(this.calendar.StartDate);
    this.maxDate.setDate(this.maxDate.getDate() + 1);
  }

  /**
   * Method to check whether the start time is greater than the end time
   */
  isStartTimeGreaterThanEndTime(startTime: string, endTime: string) {
    // Split the time strings into hours and minutes
    const startTimeParts = startTime.split(':');
    const endTimeParts = endTime.split(':');

    // Convert hours and minutes to integers
    const startHour = parseInt(startTimeParts[0], 10);
    const startMinute = parseInt(startTimeParts[1], 10);
    const endHour = parseInt(endTimeParts[0], 10);
    const endMinute = parseInt(endTimeParts[1], 10);

    // Check if start time is on a different day (across midnight)
    return startHour > endHour || (startHour === endHour && startMinute > endMinute);
  }

  /**
   * Method to set the end date as the next date if its a overnight schedule in the recurring schedule view popup
   */
  getRecurringOverNightEndDate(startDate: string) {
    const nextDate = new Date(startDate);
    nextDate.setDate(nextDate.getDate() + 1);
    return this.datePipe.transform(nextDate, 'yyyy-MM-dd');
  }
}
