import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Configuration } from 'src/app/app.constants';
import { Constants } from 'src/app/configs/app.config';
import { ICommonResponse } from 'src/app/core/models/common.model';
import { TraxPermission } from 'src/app/core/models/trax-permission.model';
import { User } from 'src/app/core/models/user.model';
import { ICleaningAlertLogs } from 'src/app/core/models/work-order.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { FilteredLogsListState } from '../../models/cleaning-alert-log.model';
import { WorkorderService } from '../workorder.http.service';

// The cleaning alerts logs listing in displayed when user clicks on Cleaning Alerts Logs sub menu from Cleaning Alerts menu
// The list displays the list with columns
// 1. Cleaning Alert #
// 2. Name
// 3. Status
// 4. Planned Start Date
// 5. Assignee
// By default list is populated based on the latest added cleaning alert
// The user is able to search the cleaning alerts from the list based on the id, name and status
// The user is able to sort based on each of the available columns in ascending/descending order
// The user is able to change the list count based on page size selection.
// On clicking each record, if user has the view permissions, they are navigated to edit/view log screen.

@Component({
  selector: 'app-cleaning-alert-logs-list',
  templateUrl: './cleaning-alert-logs-list.component.html',
  styleUrls: ['./cleaning-alert-logs-list.component.scss'],
})
export class CleaningAlertLogsListComponent implements OnInit {
  searchString: string;
  public pageName = 'workorderForm';

  pageState: FilteredLogsListState = {
    pageName: this.pageName,
    page: undefined,
    size: undefined,
    statusListId: -1,
    historyDays: 1,
  };
  totalCount = 0;
  cleaningAlertLogs: ICleaningAlertLogs[] = [];
  columnName: string;
  sortOrder: string;
  currentUser: User;
  pageSizes: ICommonResponse[];
  selectedColumnName: string;
  columnSortOrder: string;
  alertStatuses = this.utilitiesService.alertStatuses;

  constructor(
    private readonly authState: AuthState,
    private readonly WOService: WorkorderService,
    private readonly utilitiesService: UtilitiesService,
    private readonly loaderService: LoaderService,
    private readonly configuration: Configuration,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly constants: Constants,
  ) {
    this.currentUser = this.authState.getCurrentUser();
  }

  ngOnInit(): void {
    this.pageSizes = this.constants.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
  }

  get page(): number {
    return this.pageState?.page ?? 1;
  }
  set page(val: number) {
    this.pageState.page = val;
  }

  get size(): number {
    return this.pageState?.size ?? 10;
  }
  set size(val: number) {
    this.pageState.size = val;
  }

  /**
   * Method to be called on search
   */
  onSearch() {
    this.authState.storePageState(this.pageState);
    this.getAllCleaningAlertLogs();
  }

  /**
   * Method to retrieve the stored page state;
   */
  getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page && this.pageState.size) {
      this.getAllCleaningAlertLogs();
    } else {
      this.getAllCleaningAlertLogs();
    }
  }

  /**
   * Method to get the cleaning alert logs list
   */
  getAllCleaningAlertLogs() {
    this.loaderService.display(true);
    const searchTerm = this.searchString;
    const selectedHistoryDays = this.pageState.historyDays;
    const selectedStatusId = this.pageState.statusListId;
    const pageSize = this.size;
    const pageIndex = this.page ?? 1;

    const selectedStatusIds = this.alertStatuses.find((a) => a.id == selectedStatusId).statusIds;
    const searchFilter = {
      SearchQuery: searchTerm ? this.utilitiesService.removeInnerSpaces(searchTerm) : '',
      GenericSearch: 0,
      SortColumn: this.columnName ?? null,
      SortOrder: this.sortOrder ?? null,
      selectedHistoryDays,
      selectedStatusIds,
    };

    this.WOService.GetAll(JSON.stringify(searchFilter), pageIndex, pageSize).subscribe(
      (data) => (this.cleaningAlertLogs = data),
      (error) =>
        console.log(
          'WOService GetAllCleaningAlertLogs Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        ),
      () => {
        this.totalCount = this.cleaningAlertLogs[0] ? this.cleaningAlertLogs[0].Count : 0;
        this.loaderService.display(false);
      },
    );
  }

  /**
   * Method to update the list on page size change
   */
  onPageSizeChange($event: number) {
    this.size = $event;
    this.page = 1;
    this.setPageState();
  }

  /**
   * Method to be called on page change
   */
  pageChanged(page: number) {
    this.page = page;
    this.setPageState();
  }

  /**
   * Method to update page state and fetch cleaning alert logs
   */
  setPageState() {
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.authState.storePageState(this.pageState);
    this.getAllCleaningAlertLogs();
  }

  /**
   * Method to be called on search clear
   */
  clearSearch() {
    this.searchString = '';
    this.getAllCleaningAlertLogs();
  }

  /**
   * Method to update the list based on column sort
   */
  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order === 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllCleaningAlertLogs();
  }

  /**
   * Method to navigate to the edit/view cleaning alert log screen
   */
  navigateToDetails(id: number) {
    if (
      this.authState.canAccess(TraxPermission.CLEANING_ALERT_VIEW_MY_RECORDS) ||
      this.authState.canAccess(TraxPermission.CLEANING_ALERT_VIEW_ALL_RECORDS) ||
      this.authState.canAccess(TraxPermission.CLEANING_ALERT_VIEW_MY_LOCATIONS)
    ) {
      this.router.navigate(['../', id], { relativeTo: this.activatedRoute });
    } else {
      this.utilitiesService.snackBarWithAutoDismiss(
        'Cleaning Alert',
        'You do not have permission to view cleaning alert details.',
      );
    }
  }

  onFilterChanged(filters: { statusListId: number; historyDays: number }) {
    this.pageState.statusListId = filters.statusListId;
    this.pageState.historyDays = filters.historyDays;
    this.page = 1;

    this.authState.storePageState(this.pageState);
    this.getAllCleaningAlertLogs();
  }
}
